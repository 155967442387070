import { divider, onProduction, translateX } from "../utils/helpers";
import dayjs from "dayjs";
import { message, Modal, notification } from "antd";
import breakpoint from "antd/lib/grid/hooks/useBreakpoint";
import utils from "../utils";
import { API_TOOLS_URL } from "../settings/constant";
import axiosService from "./axiosService";

const appService = {};

appService.console = (
  flag,
  message,
  details,
  emoji,
  dividerTopChar,
  dividerBottomChar,
) => {
  if (onProduction()) return;

  const icon = $icon();
  const method = $method();
  const color = $color();

  let text = icon ? `${icon}${message ? " " : ""}${message}` : message;

  if (dividerTopChar && typeof dividerBottomChar === "undefined") {
    dividerBottomChar = dividerTopChar;
  }

  // show log
  if (dividerTopChar) console[method](color, divider(dividerTopChar));
  if (message) {
    console[method](color, `${dayjs().format("HH:mm:ss.SSS")} ${text}`);
    // console[logLevel](color, text);
    // console[logLevel](color, dayjs().format("HH:mm:ss.SSS"), text );
    // console[logLevel](color, text);
  }
  if (details) {
    console[method](details);
    // console[method](color, details);
    // console[method](color, dayjs().format("HH:mm:ss.SSS"), details);
    // console[logLevel](color, "👉", dayjs().format("HH:mm:ss.SSS"), details);
    // console[logLevel](details);
  }
  // if (details) console[logLevel](details);
  if (dividerBottomChar) console[method](color, divider(dividerBottomChar));

  // color by level
  function $color() {
    const red = "\x1b[31m%s\x1b[0m"; // Vermelho
    const green = "\x1b[32m%s\x1b[0m"; // Verde
    const yellow = "\x1b[33m%s\x1b[0m"; // Amarelo
    const blue = "\x1b[34m%s\x1b[0m"; // Azul
    const cyan = "\x1b[36m%s\x1b[0m"; // Ciano
    // const magenta = '\x1b[35m%s\x1b[0m' // Magenta
    // const white = '\x1b[37m%s\x1b[0m' // Branco

    if (!flag || ["start", "i"].includes(flag)) return blue; // "info";
    if (["s", "ok"].includes(flag)) return green;
    if (["e", "no"].includes(flag)) return red;
    if (["w"].includes(flag)) return yellow;
    if (["t"].includes(flag)) return cyan;
  }

  // level detect internal function
  function $method() {
    if (!flag || ["start", "i"].includes(flag)) return "log"; // "info";
    if (["s", "ok"].includes(flag)) return "log";
    if (["e", "no"].includes(flag)) return "error";
    if (["w"].includes(flag)) return "warn";
    if (["t"].includes(flag)) return "trace";
  }

  // icon build internal function
  function $icon() {
    if (emoji) return emoji;

    switch (flag) {
      case "start":
        return "🚀";

      case "s":
        return "✅";

      case "e":
        return "❌";

      case "ok":
        return "👍";

      case "no":
        return "👎";

      case "w":
        return "⚠️";

      case "i":
        return "️🕑";

      case "debug":
      default:
        return "";
    }
  }
};

appService.isMobile = () => !utils.getBreakPoint(breakpoint()).includes("md");

appService.modal = (flag, title, options) => {
  const fnNotImplemented = () =>
    appService.console("e", "Function not implemented!");

  const {
    Content,
    okText = "ok",
    cancelText = "cancel",
    CustomButtons,
    onOk = fnNotImplemented,
    onCancel = fnNotImplemented,
  } = options;

  const icon = typeof flag !== "string" && flag !== null ? flag : null;

  const content =
    typeof flag !== "string" && flag !== null ? Content : translateX(Content);

  const method = icon ? "info" : $method();

  const opt = {
    title: translateX(title),
    content,
    okText: translateX(okText),
    cancelText: translateX(cancelText),
    onOk,
    onCancel,
    footer: (_, { OkBtn, CancelBtn }) => (
      <>
        {CustomButtons}
        <CancelBtn />
        <OkBtn />
      </>
    ),
  };

  if (icon) opt.icon = flag;

  Modal[method](opt);

  // level detect internal function
  function $method() {
    if (!flag) return "open";
    if (["start", "i"].includes(flag)) return "info";
    if (["s", "ok"].includes(flag)) return "success";
    if (["e", "no"].includes(flag)) return "error";
    if (["w"].includes(flag)) return "warning";
    if (["q", "c"].includes(flag)) return "confirm"; // q:question, c:confirm
  }
};

appService.notification = (flag, title, key, description, duration) => {
  const icon = typeof flag !== "string" && flag !== null ? flag : null;
  const method = icon ? "info" : $method();
  const options = {
    message: translateX(title),
    key,
    description: translateX(description),
    duration,
  };

  if (icon) options.icon = flag;

  notification[method](options);

  // level detect internal function
  function $method() {
    if (!flag) return "open";
    if (["start", "i"].includes(flag)) return "info"; // "info";
    if (["s", "ok"].includes(flag)) return "success";
    if (["e", "no"].includes(flag)) return "error";
    if (["w"].includes(flag)) return "warning";
  }
};

appService.notificationDestroy = (key) => notification.destroy(key);

appService.message = (flag, text, key, duration) => {
  if (!flag)
    return console.error("'flag' is missing in 'appService.message()'");

  // eslint-disable-next-line default-case
  switch (flag.toLowerCase()) {
    case "s":
      flag = "success";
      break;

    case "e":
    case "f":
      flag = "error";
      break;

    case "w":
      flag = "warning";
      break;

    case "i":
      flag = "info";
      break;

    case "l":
      flag = "loading";
      break;
  }

  if (!["success", "error", "warning", "info", "loading"].includes(flag)) {
    console.error(`unknowing type '${flag}' in msg() helper function!`);
    return;
  }

  message[flag]({
    duration: duration || 2,
    key: key,
    content: translateX(text),
    className: "message-info",
  }).then((r) => r);
};

appService.messageDestroy = (key) => message.destroy(key);

appService.getGpsByAddress = async (address) => {
  const url = `${API_TOOLS_URL}/get-gps-by-address/${encodeURIComponent(
    address,
  )}`;
  const [doc] = await axiosService.get({ url });
  return doc?.[0] && doc?.[1] ? doc : null; // null (coordinates not exist according address and number
};

appService.copyUrl = () => {
  const url = window.location.href;

  if (!navigator.clipboard) {
    appService.notification(
      "w",
      "incompatible_browser",
      "copyUrl",
      "url_copy_not_supported",
      10,
    );
    return;
  }

  navigator.clipboard
    .writeText(url)
    .then(() => {
      appService.message("s", "url_copied");
    })
    .catch(() => {
      appService.message("f", "url_not_copied");
    });
};

export default appService;
