import React from "react";
import PropTypes from "prop-types";
import FooterWrapper, {
  MenuWrapper,
  CopyrightArea,
  SecondaryFooter,
} from "./Footer.style";

const Footer = ({ logo, menu, bgSrc, copyright, className, path }) => {
  return (
    <>
      <FooterWrapper id="footer" className={className} bg-img={bgSrc}>
        {logo && logo}
        {menu && <MenuWrapper>{menu}</MenuWrapper>}
        {copyright && <CopyrightArea>{copyright}</CopyrightArea>}
        <span
          style={{
            marginTop: 16,
            fontSize: 12,
            marginBottom: -24,
            opacity: 0.8,
          }}
        >
          Site e desenvolvido e mantido por &nbsp;
          <strong>
            <a
              href="https://www.instagram.com/biruel"
              target="_blank"
              rel="noopener noreferrer"
            >
              @biruel
            </a>
          </strong>
        </span>
      </FooterWrapper>
      {!!path && <SecondaryFooter />}
    </>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.element,
  menu: PropTypes.element,
  bgSrc: PropTypes.string,
  copyright: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Footer;
