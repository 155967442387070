import { Col, Flex, Row } from "antd";
import { MdOutlineLocalPhone, MdOutlineWhatsapp } from "react-icons/md";
import { NavLink } from "react-router-dom";
import {
  INFO_BENEFITS_COVERAGE_ROUTE,
  INFO_COMPANY_ROUTE,
  INFO_CONTACT_ROUTE,
  INFO_EUROPE_TRAVEL_INSURANCE_ROUTE,
  INFO_EXCHANGE_TRAVEL_INSURANCE_ROUTE,
  INFO_FAQ_ROUTE,
  INFO_FOR_FOREIGNERS_EN_ROUTE,
  INFO_FOR_FOREIGNERS_ES_ROUTE,
  INFO_FOR_FOREIGNERS_PT_ROUTE,
  INFO_INTERNATIONAL_TRAVEL_INSURANCE_ROUTE,
  INFO_PRIVACY_POLICY_ROUTE, INFO_SCHENGEN_TREATY_ROUTE,
  INFO_TRAVEL_INSURANCE_ROUTE,
  INFO_WTS_TRAVEL_INSURANCE_ROUTE,
  ORDERS_ROUTE
} from "../../settings/constant";
import securityGoogle from "assets/images/security-google.png";
import securitySSL from "assets/images/security-ssl.png";
import cardsImages from "assets/images/cartoes-bandeiras.png";
import discountsBankSlipAndPix from "assets/images/descontos.png";

const FooterLinks = () => {
  const justifyFlex = "center";
  const classItem = "p-2 text-center";

  return (
    <Row justify="space-around">
      {/* COLUNA 1 */}
      <Col xs={24} md={6} style={{ padding: "36px 24px 0" }}>
        <p className={`font-weight-bold text-center`}>SUPORTE E VENDAS</p>
        <Flex justify={justifyFlex} align="center" className={classItem}>
          <MdOutlineLocalPhone style={{ marginRight: 8 }} />
          <a href="tel:01932010447">
            <span>(19) 3201 0447</span>
          </a>
        </Flex>
        <Flex justify={justifyFlex} align="center" className={classItem}>
          <MdOutlineWhatsapp style={{ marginRight: 8 }} />
          <a href="https://wa.me/551932010447">
            <span>(19) 3201 0447</span>
          </a>
        </Flex>

        <div className={classItem}>
          <img src={cardsImages} alt="Cartões de Créditos" width={180} />
        </div>

        <div className={classItem}>
          <img
            src={discountsBankSlipAndPix}
            alt="Desconto de 5% no Boleto e no Pix"
            width={180}
          />
        </div>
      </Col>
      {/* COLUNA 1 */}

      {/* COLUNA 2 */}
      <Col xs={24} md={6} style={{ padding: "36px 24px 0" }}>
        <p className={`font-weight-bold text-center`}>COMPRAS E INFORMAÇÕES</p>

        <div className={classItem}>
          <NavLink to={INFO_TRAVEL_INSURANCE_ROUTE}>
            <span>Seguro Viagem</span>
          </NavLink>
        </div>

        <div className={classItem}>
          <NavLink to={ORDERS_ROUTE}>
            <span>Acesse seu Voucher</span>
          </NavLink>
        </div>

        <div className={classItem}>
          <NavLink to={INFO_WTS_TRAVEL_INSURANCE_ROUTE}>
            <span>O que é seguro viagem?</span>
          </NavLink>
        </div>

        <div className={classItem}>
          <NavLink to={INFO_BENEFITS_COVERAGE_ROUTE}>
            <span>Benefícios e coberturas</span>
          </NavLink>
        </div>

        <div className={classItem}>
          <NavLink to={INFO_FAQ_ROUTE}>
            <span>FAQ - Perguntas frequentes</span>
          </NavLink>
        </div>

        <div className={classItem}>
          <NavLink to={INFO_SCHENGEN_TREATY_ROUTE}>
            <span>Tratado de Schengen</span>
          </NavLink>
        </div>

        <div className={classItem}>
          <NavLink to={INFO_EXCHANGE_TRAVEL_INSURANCE_ROUTE}>
            <span>Seguro Viagem Intercâmbio</span>
          </NavLink>
        </div>

        <div className={classItem}>
          <NavLink to={INFO_INTERNATIONAL_TRAVEL_INSURANCE_ROUTE}>
            <span>Seguro Viagem Internacional</span>
          </NavLink>
        </div>

        <div className={classItem}>
          <NavLink to={INFO_EUROPE_TRAVEL_INSURANCE_ROUTE}>
            <span>Seguro Viagem Europa</span>
          </NavLink>
        </div>

        <div className={classItem}>
          <NavLink to={INFO_FOR_FOREIGNERS_PT_ROUTE}>
            <span>Seguro Viagem para Estrangeiros</span>
          </NavLink>
        </div>

        <div className={classItem}>
          <NavLink to={INFO_FOR_FOREIGNERS_EN_ROUTE}>
            <span>Travelers Insurance Brazil</span>
          </NavLink>
        </div>

        <div className={classItem}>
          <NavLink to={INFO_FOR_FOREIGNERS_ES_ROUTE}>
            <span>Asistencia al Viajero Brasil</span>
          </NavLink>
        </div>
      </Col>
      {/* COLUNA 2 */}

      {/* COLUNA 3 */}
      <Col xs={24} md={6} style={{ padding: "36px 24px 0" }}>
        <p className={`font-weight-bold text-center`}>SOBRE A MULTI</p>
        <div className={classItem}>
          <NavLink to={INFO_COMPANY_ROUTE}>
            <span>Nossa empresa</span>
          </NavLink>
        </div>
        <div className={classItem}>
          <NavLink to={INFO_CONTACT_ROUTE}>
            <span>Entre em contato</span>
          </NavLink>
        </div>
        <div className={classItem}>
          <NavLink
            to="https://blog.multiseguroviagem.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Blog</span>
          </NavLink>
        </div>
        {/*<div className={classItem}>*/}
        {/*  <NavLink to={INFO_PROMOTIONS_ROUTE}>*/}
        {/*    <span>Promoções</span>*/}
        {/*  </NavLink>*/}
        {/*</div>*/}
        <div className={classItem}>
          <NavLink to={INFO_PRIVACY_POLICY_ROUTE}>
            <span>Política de Privacidade</span>
          </NavLink>
        </div>
      </Col>
      {/* COLUNA 3 */}

      {/* COLUNA 4 */}
      <Col xs={24} md={6} style={{ padding: "36px 24px 0" }}>
        <p className={`font-weight-bold text-center`}>
          SEGURANÇA E CERTIFICAÇÕES
        </p>

        {/* GOOGLE SECURITY */}
        <div className={classItem}>
          <NavLink
            to="https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fmultiseguroviagem.com.br%2F"
            target="_blank"
          >
            <img src={securityGoogle} alt="Google Security" width={100} />
          </NavLink>
        </div>
        {/* GOOGLE SECURITY */}

        <div className={classItem}>
          <img src={securitySSL} alt="Site Criptografado" width={100} />
        </div>
      </Col>
      {/* COLUNA 4 */}
    </Row>
  );
};

export default FooterLinks;
