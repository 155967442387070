import { Card, Col, Collapse, Row } from "antd";
import europaImg from "assets/images/europa.png";

const InfoEuropeTravelInsurance = () => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const items = buildItems();

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Row justify="center" className="mb-3">
      <Col style={{ width: 836 }}>
        <Card
          className="strong-border card-without-padding"
          title={
            <Row>
              <Col span={24}>
                <h1 className="text-center">Seguro Viagem Europa</h1>
                <h3 className="text-center text-muted mb-4">
                  Todas as Operadoras estão Aqui (até 50% OFF)
                </h3>
              </Col>
            </Row>
          }
          style={{
            margin: "18px 18px",
            maxWidth: 800,
          }}
          classNames="text-center"
        >
          <p className="font-size-md p-3 text-justify">
            Realizar uma viagem ao continente Europeu é o sonho de muitas
            pessoas, seja por conta da cultura dos países europeus, dos grandes
            centros históricos, museus e pontos turísticos ou então para
            conhecer novas culturas, estudar ou mesmo relaxar nos mais variados
            países e opções de hospedagem na Europa.
          </p>

          <Row justify="center">
            <img src={europaImg} alt="Europa" />
          </Row>

          <p className="font-size-md p-3 text-justify">
            Se estiver planejando uma viagem a Europa, um dos itens mais
            importantes a serem definidos é a aquisição de um plano de Seguro
            Viagem Europa, além de essencial para garantir que qualquer
            imprevisto não atrapalhe todo o seu planejamento financeiro da
            viagem, a contratação de um plano de Seguro Viagem é obrigatório no
            continente Europeu, e mais ainda, existe um valor de cobertura
            mínimo a ser contratado que deve ser respeitada caso não queira ter
            problemas em sua estadia no continente.
          </p>

          <Collapse accordion items={items} className="mb-4" ghost />
        </Card>
      </Col>
    </Row>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildItems() {
    return [
      {
        key: "1",
        label: (
          <h4 className="font-weight-bold">
            Seguro Viagem Europa é obrigatório?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Essa é uma pergunta muito comum feita por pessoas que estão
              visitando o continente e a resposta é SIM! O Seguro Viagem é
              obrigatório na maioria dos países do continente europeu, em resumo
              todos os países que fazem parte do Tratado de Schengen exigem a
              contratação de um plano de seguro viagem com cobertura médica
              equivalente à pelo menos EUR 30.000,00. A Multi Seguro Viagem
              possui um filtro exclusivo em seu site, que ao realizar a sua
              cotação para o continente Europeu, são exibidos apenas os planos
              que se enquadram no tratado de Schengen, desta forma você poderá
              escolher o plano com este quesito garantido pela seguradora
              escolhida. Para facilitar, listamos abaixo quais países fazem
              parte atualmente do tratado de Schengen, caso durante sua viagem
              você passar por um desses países é importante possuir um plano de
              seguro viagem ativo.
            </p>

            <p className="font-size-md text-justify">
              <strong>Países participantes do Tratado de Schengen:</strong>
            </p>

            <Row>
              <Col xs={24} sm={12}>
                <ul>
                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Alemanha
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Áustria
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Bélgica
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Dinamarca
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Eslováquia
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Eslovénia
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Espanha
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Estónia
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Finlândia
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; França
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Grécia
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Hungria
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Islândia
                    </p>
                  </li>
                </ul>
              </Col>

              <Col xs={24} sm={12}>
                <ul>
                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Itália
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Letónia
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Liechtenstein
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Lituânia
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Luxemburgo
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Malta
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Noruega
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Países Baixos (Holanda)
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Polónia
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Portugal
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; República Checa
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Suécia
                    </p>
                  </li>

                  <li>
                    <p
                      className="font-size-md text-justify"
                      style={{ marginLeft: 48, marginRight: 48 }}
                    >
                      &#x2022; Suíça
                    </p>
                  </li>
                </ul>
              </Col>
            </Row>
          </>
        ),
      },

      {
        key: "2",
        label: (
          <h4 className="font-weight-bold">
            Porque contratar o Seguro Viagem Europa?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              É muito importante ressaltar que além da obrigatoriedade no
              continente, o plano de Seguro Viagem tem uma função muito
              importante de proteção financeira e uma série de assistências aos
              viajantes que são fundamentais no acontecimento de imprevistos
              durante sua viagem. Ninguém deseja que acidentes ou imprevistos
              aconteçam durante sua viagem, porém é muito melhor estar protegido
              caso eles aconteçam, do que correr o risco de estragar todo um
              planejamento de uma viagem por conta de algo que poderia ser
              resolvido de maneira mais simples com a assistência prestada pelo
              plano de Seguro Viagem. O Custo do Seguro Viagem é calculado pela
              quantidade de dias de duração de sua viagem e também pelos valores
              de cobertura escolhidos no momento da contratação, quanto mais
              completas as coberturas, maior o investimento do seguro e
              consequentemente melhores os benefícios em caso de imprevistos. É
              importante ressaltar que o investimento realizado no seguro viagem
              para Europa, normalmente não chega a 5% dos custos de sua viagem,
              em caso de ocorrência de um acidente ou imprevisto coberto pelo
              seguro, com certeza o valor investido na contratação do plano, não
              chegará nem perto do valor de pagamento de um atendimento médico
              no exterior por exemplo.
            </p>
          </>
        ),
      },

      {
        key: "3",
        label: (
          <h4 className="font-weight-bold">
            Qual plano de Seguro Viagem para Europa devo contratar?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              A escolha do plano de seguro viagem é bastante pessoal, já que a
              ideia principal da contratação é que o plano atenda a cobertura
              das principais necessidades do perfil de sua viagem e também de
              seu perfil pessoal. A Multi Seguro Viagem comercializa planos de
              mais de 9 seguradoras renomadas no mercado de Seguro Viagem e
              possui disponível a comparação de aproximadamente 60 diferentes
              planos de seguro disponíveis para contratação online, portanto com
              certeza será possível encontrar um plano adequado a sua
              necessidade, caso tenha duvidas na contratação, nossa equipe de
              consultores terá o maior prazer em ajuda-lo nesta decisão. Para
              facilitar a sua escolha, listamos abaixo três opções de planos,
              divididos em básico, intermediário e completo que podem lhe
              atender em sua próxima viagem a Europa:
            </p>

            <p className="font-size-md text-justify">
              <strong>BÁSICO - Multi Seguros 30E</strong>
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguradora: Universal Assistance
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Assistência Médica: EUR 30.000,00
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Extravio de Bagagem: EUR 1.000,00
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Custo médio por dia de US$ 3,00*
                </p>
              </li>
            </ul>

            <p className="font-size-md text-justify">
              <strong>INTERMEDIÁRIO - Multi Seguros 50E</strong>
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguradora: Universal Assistance
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Assistência Médica: EUR 50.000,00
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Extravio de Bagagem: EUR 1.000,00
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Custo médio por dia de US$ 3,40*
                </p>
              </li>
            </ul>

            <p className="font-size-md text-justify">
              <strong>COMPLETO - Multi Seguros 250E</strong>
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguradora: Universal Assistance
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Assistência Médica: EUR 250.000,00
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Extravio de Bagagem: EUR 1.000,00
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Custo médio por dia de US$ 11,00*
                </p>
              </li>
            </ul>

            <p className="font-size-sm text-justify">
              * Todas as informações de preço são estimativas e podem ser
              revistas pela seguradora a qualquer momento, sem necessidade de
              aviso prévio, sendo válida sempre a condição de contratação
              disponível no sistema oficial de contratação do seguro no dia e
              horário da emissão da apólice de seguros.
            </p>
          </>
        ),
      },
    ];
  }
};

export default InfoEuropeTravelInsurance;
