import { Card, Col, Collapse, Row } from "antd";

const Faq = () => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const items = buildItems();

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Row justify="center" className="mb-3">
      <Col style={{ width: 836 }}>
        <Card
          className="strong-border card-without-padding"
          title={
            <Row>
              <Col span={24}>
                <h1 className="text-center">F.A.Q. - Perguntas frequentes</h1>
                <h3 className="text-center text-muted mb-4">
                  Perguntas frequentes de nossos clientes! Tire suas dúvidas
                  conosco
                </h3>
              </Col>
            </Row>
          }
          style={{
            margin: "18px 18px",
            maxWidth: 800,
          }}
          classNames="text-center"
        >
          <Collapse accordion items={items} className="mb-4" ghost />
        </Card>
      </Col>
    </Row>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildItems() {
    return [
      {
        key: "1",
        label: (
          <h4 className="font-weight-bold">
            Quais as responsabilidades da Multi Seguro Viagem e das Operadoras?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Nossa plataforma de comparação e venda online de Seguro Viagem tem
              como objetivo apresentar aos nossos clientes as melhores opções de
              planos, sendo a Operadora de Seguro Viagem contratada a única e
              exclusiva responsável por diversos procedimentos (incluindo
              reembolso de despesas), coberturas e limites do plano contratado,
              além de garantir atendimento ao viajante, antes, durante e após a
              viagem. Em caso emergência, a Operadora deve ser diretamente
              acionada pelo viajante. Recomendamos ao viajante ler atentamente
              todas as regras descritas nas condições gerais disponibilizadas no
              site da operadora contratada, para evitar dúvidas e
              inconveniências futuras. A Multi Seguro Viagem está habilitada a
              esclarecer as dúvidas oriundas dos planos fornecidos pelas
              operadoras parceiras, bem como auxiliá-lo a identificar o plano
              que melhor atenderá suas necessidades. É importante destacar que a
              Multi Seguro Viagem não atua como Operadora. Ela trabalha com
              diversos parceiros, com o objetivo oferecer ao viajante a
              facilidade de encontrar o plano ideal para o seu perfil, através
              do serviço de comparação e venda online de seguro viagem, feito na
              plataforma digital da própria Multi Seguro Viagem. Além de
              intermediar diferentes planos de diversas operadoras, e facilitar
              a comparação dos planos ao viajante, a responsabilidade da Multi
              Seguro Viagem é prestar atendimento de excelência, ter clareza e
              precisão nas informações prestadas aos clientes, intermediar e
              acompanhar o atendimento entre viajante e operadora, em caso de
              possíveis dificuldades de contato. Nossos especialistas estão
              sempre à disposição, dentro do horário comercial, para fazer esta
              “ponte” sempre que necessário. Precisou falar conosco? Após o
              contato inicial com a operadora, basta enviar um e-mail para
              contato@multiseguroviagem.com.br ou em nossa página de contato.
            </p>
          </>
        ),
      },

      {
        key: "2",
        label: (
          <h4 className="font-weight-bold">
            Quais são os benefícios de comprar com a Multi Seguro Viagem?{" "}
          </h4>
        ),
        children: (
          <ul>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Líderes do mercado: Aqui você encontra as melhores
                operadoras, nacionais e internacionais. São planos de qualidade,
                com preço justo e pagamento facilitado.
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Compare e compre seu seguro online: As opções de seguro
                viagem são muitas! Para facilitar sua vida, compare os planos e
                compre a cobertura ideal para seu perfil, 100% online.
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Mais facilidade e segurança: Viaje tranquilo com quem
                mais entende de Seguro Viagem. Aqui você tem uma plataforma
                ágil, para resolver tudo em um só lugar, com toda a segurança.
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Atendimento diferenciado: Nós acompanhamos seu
                atendimento junto às operadoras para agilizar suas solicitações.
              </p>
            </li>
          </ul>
        ),
      },

      {
        key: "3",
        label: (
          <h4 className="font-weight-bold">
            Recomendações Essenciais – Aviso ao Viajante:
          </h4>
        ),
        children: (
          <ul>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Confirme o recebimento de seu voucher. Lembre-se de
                imprimir e levá-lo junto com seus documentos de viagem.
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Verifique atentamente todas as condições descritas no
                voucher, inclusive as condições gerais do(s) plano(s)
                contratado(s) que seguem também disponibilizadas no site da
                Operadora.
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Antes de realizar qualquer atendimento ou utilizar
                qualquer cobertura, é obrigatório entrar em contato com a
                operadora contratada.
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Verifique no voucher do seguro viagem onde estão os
                contatos de telefone, e-mails e demais formas de atendimento da
                operadora, em caso de emergência.
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Após receber atendimento na clínica ou hospital da rede
                credenciada (autorizada pela operadora), não informe seu próprio
                endereço, para que não haja envio de cobranças futuras.
                Verifique no voucher qual endereço deve ser fornecido, ou
                informe-se junto à operadora.
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Toda apólice de seguro viagem tem o endereço de sua
                operadora. Para evitar futuras cobranças em sua residência,
                coloque o endereço no cadastro feito no hospital.
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Os planos de seguro e/ou assistência de viagem podem
                ser comercializados em canais habilitados para a venda, como é o
                caso da Multi Seguro Viagem.
              </p>
            </li>
          </ul>
        ),
      },

      {
        key: "4",
        label: (
          <h4 className="font-weight-bold">
            Por que eu preciso de um Seguro Viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              A principal vantagem de adquirir um seguro viagem é você minimizar
              qualquer situação que fuja do controle ou imprevisto que você
              possa ter durante a sua viagem. Sabia que um atendimento médico no
              exterior pode custar milhares de dólares? Além disso, é preciso se
              precaver em caso de extravio de bagagens, perda de documentos ou
              nos atrasos e cancelamentos dos voos. Se precisar de atendimento
              médico, por exemplo, com a assistência do seguro viagem você não
              gastará nada além das despesas já previstas em sua viagem.
              Qualquer plano que você escolher, dentre as operadoras parcerias
              do nosso site, o atendimento será totalmente em português, de
              qualquer lugar do mundo. Os seguros mais completos oferecem ainda
              viagens marítimas, atendimentos clínicos e odontológicos,
              assistência jurídica, medicamentos, repatriação, extensão de
              internação hospitalar, diárias em hotéis. Em casos específicos,
              também garantem passagem aérea para familiares. Viaje mais
              tranquilo sabendo que estamos aqui se precisar de ajuda. Entenda e
              conheça os planos, compare e escolha a opção ideal para a sua
              viagem!
            </p>
          </>
        ),
      },

      {
        key: "5",
        label: (
          <h4 className="font-weight-bold">
            Qual o valor do Seguro Viagem em relação ao total investido em uma
            viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Quando paramos para precificar nossas viagens, e colocamos na
              ponta do lápis todos os gastos envolvidos (hotéis, passagens
              aéreas, aluguel de carro, chip de celular, ingressos, gastos no
              cartão, IOF, etc.) percebemos que o valor do Seguro Viagem é
              irrisório perto do custo total de uma viagem. De acordo com
              pesquisas internas, esse investimento não passa de 3% do custo
              total da viagem, podendo variar de 1% a 2%, dependendo dos valores
              de coberturas e do gasto total da viagem. De qualquer forma,
              levando em consideração as coberturas de proteção para riscos de
              morte, acidente, invalidez, despesas médicas, hospitalares,
              odontológicas, diárias, perda ou roubo de bagagem, extravio de
              bagagem entre outros contratempos que possam surgir, percebemos
              que o seguro viagem se torna imprescindível, e sem dúvida o item
              mais barato do roteiro.
            </p>
          </>
        ),
      },

      {
        key: "6",
        label: (
          <h4 className="font-weight-bold">
            Qual o melhor plano de seguro viagem para a minha viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Antes de escolher o plano ideal, é necessário entender quais são
              suas necessidades e qual é o motivo/tipo de sua viagem: trabalho,
              lazer, viagem com a família, intercâmbio, viagem com esportes
              radicais ou ainda se participará de alguma competição esportiva,
              por exemplo.
            </p>

            <p className="font-size-md text-justify">
              Para te ajudar, aqui vão algumas perguntas:
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Qual valor de cobertura de Assistência Médica você
                  procura? Quanto maior a cobertura, mais seguro você estará!
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Possui alguma doença Pré-Existente?
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Vai praticar esportes dentro do período de viagem?
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Tem alguma cobertura que você considera importante,
                  além da assistência médica?
                </p>
              </li>
            </ul>

            <p className="font-size-md text-justify">
              Objetivo definido, basta comparar todas as coberturas e preços,
              para encontrar qual modelo mais se adequa ao perfil de sua viagem.
              Lembre-se: todas as seguradoras possuem atendimento padronizado.
              Nossa plataforma oferece atendimento de consultores especializados
              em Seguro Viagem. Em caso de dúvida, fale com nossa equipe!.
            </p>
          </>
        ),
      },

      {
        key: "7",
        label: (
          <h4 className="font-weight-bold">
            Como solicitar assistência fora do país?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Caso ocorra alguma emergência e você precise acionar o seu Seguro
              Viagem, é necessário entrar em contato com a operadora escolhida,
              que está identificada em sua própria apólice. Lá, você encontrará
              telefones do país de destino, telefones do Brasil, e-mail, skype
              e, em alguns casos, até atendimento via WhatsApp e aplicativo. A
              ligação pode ser feita a cobrar (Collect Call) de qualquer
              telefone fixo no exterior. Basta explicar para a atendente o que
              está acontecendo, qual cobertura você quer acionar e sua
              localização. Com essas informações, a central passará todo o
              procedimento que você deve seguir. A Central funciona 24h, com
              atendimento em inglês e português. Basta mencionar o idioma de sua
              preferência. Em caso de necessidade, nossos consultores estarão à
              disposição dentro do horário comercial. Após contato inicial com a
              operadora, basta enviar um e-mail para:
              contato@multiseguroviagem.com.br. O diferencial da Multi Seguro
              Viagem é realizar o acompanhamento do seu atendimento, do início
              ao fim.
            </p>
          </>
        ),
      },

      {
        key: "8",
        label: (
          <h4 className="font-weight-bold">
            Como funciona a utilização do Seguro Viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Todas as operadoras parcerias da Multi Seguro Viagem trabalham com
              atendimento por meio de redes credenciadas. Ao entrar em contato
              com a central de atendimento 24hs, você será encaminhado para a
              rede credenciada mais próxima ou encaminhará um médico até você.
              Fazendo esse procedimento, você não terá custos adicionais em seu
              atendimento. Caso queira, você também pode ir para um hospital ou
              centro médico de sua escolha, pagar pelo atendimento e solicitar o
              reembolso para a operadora que responsável pelo atendimento. “E se
              for um caso emergencial e eu não conseguir entrar em contato?”
              Fique tranquilo! Você será encaminhado para um hospital mais
              próximo e será atendido normalmente, porém, em algum momento é
              necessário entrar em contato com a operadora e explicar que
              ocorreu um caso extraordinário e que você está ou já foi atendido
              no hospital (nome do hospital). Se você precisar pagar por algum
              serviço, guarde todos os laudos médicos e recibos para que possa
              solicitar o reembolso do valor.
            </p>
          </>
        ),
      },

      {
        key: "9",
        label: (
          <h4 className="font-weight-bold">
            Quais são as formas de pagamento aceitas?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              O pagamento referente ao plano escolhido poderá ser realizado
              através do boleto bancário ou utilizando cartão de crédito das
              bandeiras MasterCard, Visa, Dinners e Hipercard.
            </p>
          </>
        ),
      },

      {
        key: "10",
        label: (
          <h4 className="font-weight-bold">
            Como posso alterar meu plano de seguro viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Para fazer qualquer tipo de alteração em sua apólice é necessário
              entrar em contato conosco através da nossa página de contato, até
              48h antes do Início da vigência de sua apólice, para que seja
              possível alterar e reencaminhar a nova apólice. Lembrando que,
              após o início de vigência de sua viagem, não será possível fazer
              qualquer tipo de alteração. Estamos disponíveis de segunda a
              sexta, das 9hrs às 18hrs.
            </p>
          </>
        ),
      },

      {
        key: "11",
        label: (
          <h4 className="font-weight-bold">
            Como posso cancelar meu Plano de Seguro Viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Independente do motivo, seja por cancelamento da viagem ou
              desistência de obter um plano, você poderá cancelar seu plano de
              Seguro Viagem entrando em contato conosco em até 48hs úteis. Se o
              pagamento foi feito em cartão de crédito, o valor será estornado
              em seu próprio cartão, em até 48hs úteis. Caso o pagamento tenha
              sido feito via boleto bancário, para que possamos realizar o
              estorno em sua conta é necessário que você passe todos os seus
              dados bancários: nome completo e CPF. Você poderá fazer esta
              solicitação através da nossa central de atendimento (19) 3204-0447
              e/ou enviar uma solicitação com o motivo do cancelamento, nº do
              voucher e operadora para o e-mail contato@multiseguroviagem.com.br
              Lembrando que, após o início de vigência de sua viagem, não será
              possível fazer qualquer tipo de cancelamento. Estamos disponíveis
              de segunda a sexta, das 9hrs às 18hrs.
            </p>
          </>
        ),
      },

      {
        key: "12",
        label: (
          <h4 className="font-weight-bold">
            Até quantos dias antes da viagem é possível contratar o seguro
            viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              O seguro viagem pode ser contratado até o dia de sua viagem. A
              única exigência é que o viajante ainda esteja em território
              nacional. Caso ocorra alguma urgência e o cliente precise utilizar
              o plano, a operadora faz a verificação através da passagem aérea
              que consta data e hora de embarque. De qualquer forma,
              recomendamos que não deixe para a última hora e adquira seu seguro
              no mesmo dia que comprar suas passagens aéreas. Em caso de
              dúvidas, entre em contato conosco
            </p>
          </>
        ),
      },

      {
        key: "13",
        label: (
          <h4 className="font-weight-bold">
            Existe Seguro Viagem para viagens acima de 1 ano?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              O seguro viagem poderá ser contratado com mínimo de 3 dias e
              máximo de 365 dias. A maioria das operadoras emitem apólices com
              até 1 ano. Porém, é possível renovar o seguro mesmo se o viajante
              estiver em plena viagem no exterior. Caso sua viagem seja superior
              a esse período, você deverá entrar em contato conosco 5 dias antes
              do termino da vigência da sua apólice, para solicitarmos a
              prorrogação da mesma.
            </p>
          </>
        ),
      },

      {
        key: "14",
        label: (
          <h4 className="font-weight-bold">
            Como é o procedimento para renovar meu seguro se eu já estiver no
            exterior e precisar prolongar a viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              É possível a renovação do seguro viagem mesmo se o viajante
              estiver em plena viagem no exterior. Para renovação, basta o
              viajante entrar em contato conosco 5 dias antes do termino da
              vigência da sua apólice para solicitarmos a prorrogação e
              autorização da mesma junto a operadora.
            </p>
          </>
        ),
      },

      {
        key: "15",
        label: (
          <h4 className="font-weight-bold">
            Como posso contratar um seguro viagem se eu já estiver no exterior?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Caso você esteja no exterior ou embarque sem contratar o seguro de
              viagem, é possível fazer a contratação do seguro durante o seu
              período de viagem. Hoje, a maioria das operadoras não vendem para
              clientes já em viagem, mas algumas operadoras trabalham com a
              venda mediante a autorização e preenchimento de uma declaração de
              saúde. Basta você entrar em contato com a Multi Seguro Viagem,
              através do e-mail contato@multiseguroviagem.com.br nos informando:
              nome completo, CPF e data de nascimento dos viajantes, bem como o
              país em que os mesmos se encontram e data de volta da viagem.
              Nossos consultores irão apresentar as opções de seguro e o
              procedimento que deverá ser feito para solicitar a autorização da
              compra do seguro. Após encaminhado, a operadora pode demorar até
              72hs para responder, aprovando ou não a contratação. Aprovado,
              emitiremos e encaminharemos a apólice ao viajante. Nos casos de
              contratação já em período de viagem, existe uma carência para
              utilização do plano que pode variar de 3 a 5 dias úteis.{" "}
            </p>
          </>
        ),
      },

      {
        key: "16",
        label: (
          <h4 className="font-weight-bold">
            Existe Seguro Viagem para viagens nacionais? Quando devo contratar?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Existe sim e você deve contratar sempre! O Seguro de Viagem
              Nacional é um produto super em conta para deixar sua viagem mais
              segura, especialmente pra quem não possui plano de saúde nacional.
              O Seguro de Viagem Nacional possui diversas coberturas para ajudar
              o viajante dentro de seu período de viagem. A principal cobertura
              é assistência médica. No entanto, o viajante também tem cobertura
              farmacêutica, odontológica, seguro por morte e invalidez
              acidental, dentre outras. Este seguro cobre qualquer tipo de
              viagem no Brasil, desde que o passageiro esteja a uma distância de
              pelo menos 100 km (quilômetros) de sua residência. Caso você
              precise utilizar qualquer um dos serviços oferecidos pelo plano,
              basta entrar em contato com a central de atendimento, que a
              operadora enviará o procedimento correto, encaminhando você para
              uma rede credenciada particular ou encaminham um médico até você.
              Selecione o destino “Brasil” em nosso contador para visualizar os
              planos disponíveis.
            </p>
          </>
        ),
      },

      {
        key: "17",
        label: (
          <h4 className="font-weight-bold">
            Qual documento deve constar nos Vouchers?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Cada operadora exige um tipo de documentação para a emissão das
              apólices, mas o documento principal é o CPF. Caso o viajante seja
              menor de idade, e não tenha CPF, basta apresentar o número do
              passaporte ou inserir o documento do responsável legal.
            </p>
          </>
        ),
      },

      {
        key: "18",
        label: (
          <h4 className="font-weight-bold">
            Como devo selecionar as datas de ida e de volta?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Este item da sua cotação é de extrema importância para que você
              não fique nenhum dia sem seguro. Na data de ida, selecione o dia
              exato em que você sairá do Brasil. Atenção: jamais coloque a data
              de chegada ao seu destino. Na data da volta, selecione a data
              exata em que chegará ao Brasil, pois isso garante 100% de
              cobertura durante toda a viagem, principalmente a cobertura para
              extravio da bagagem.
            </p>
          </>
        ),
      },

      {
        key: "19",
        label: (
          <h4 className="font-weight-bold">
            Qual o procedimento quando o passageiro for menor de idade e não
            possuir CPF?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Caso o viajante seja menor de idade e não tenha CPF, o viajante
              pode utilizar o número do passaporte com data de validade ou o CPF
              do responsável legal. Porém, nesses casos é necessário ver quais
              os documentos exigidos pela operadora.
            </p>
          </>
        ),
      },

      {
        key: "20",
        label: (
          <h4 className="font-weight-bold">
            O que fazer em caso de extravio de bagagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Primeiramente, é necessário que o viajante informe a companhia
              aérea e solicite o documento de comprovação de extravio de
              bagagem, chamado PIR (Property Irregularity Report). Com o número
              do PIR em mãos, basta entrar em contato com a sua operadora de
              seguro viagem, que irá informar o próximo passo. Caso sua bagagem
              seja extraviada, a cobertura de extravio de bagagem não cobre
              furtos ou danos. Além de ser pago, o valor por quilo pode variar
              de operadora para operadora, e essas informações são internas.
              Normalmente é pago U$ 30 a U$ 45 por quilo, até o limite da
              cobertura contratada. Não deixe de comunicar nossa central para
              que possamos acompanhar o atendimento.
            </p>
          </>
        ),
      },

      {
        key: "21",
        label: (
          <h4 className="font-weight-bold">
            Como funciona o seguro viagem para a prática de esportes? E se eu me
            machucar?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Não se preocupe. O seguro viagem também oferece cobertura nesse
              quesito. A maioria dos planos de seguro viagem já possui uma
              cobertura mínima e específica para Prática de Esportes ou o plano
              cobre acidentes derivados do esportes, dentro da cobertura de
              Assistência Médica. A única exigência é que, para acionar essa
              cobertura, o viajante tem de estar Praticando Esportes em caráter
              amador. Esportes decorrentes a competição ou profissional estão
              excluídos desta cobertura. Indicamos a contratação do Seguro
              Viagem especifico para esportes com um de nossos consultores, pois
              os mesmos oferecem cobertura superior para diversas situações que
              podem ocorrer durante a prática de esportes em sua viagem. Para
              identificar quais esportes são cobertos, confira sempre as
              Condições Gerais do plano ao seu consultor. Lá você encontrará
              essas e outras informações sobre os tipos de cobertura.
            </p>
          </>
        ),
      },

      {
        key: "22",
        label: (
          <h4 className="font-weight-bold">
            Como funciona o seguro viagem para gestante?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              O seguro viagem gestante é indispensável para mulheres em qualquer
              período de gravidez. A contratação deste serviço garante
              atendimento para exames e ultrassonografias de emergência,
              cobertura para abortos espontâneos ou acidentais, partos de
              emergência e também na ocorrência de partos prematuros.
              Atualmente, a maioria dos planos possui uma cobertura específica
              para a gestação ou cobertura de Assistência Médica para eventuais
              complicações gestacionais. É importante ressaltar que o seguro não
              cobre o acompanhamento de sua gestação. Exames e atendimentos de
              rotina não estão cobertos. O seguro cobre apenas atendimento
              emergencial. É importante ressaltar que cada operadora possui uma
              regra própria. No entanto, toda gestante precisa ter uma
              declaração de seu médico informando que se encontra em ótima
              condição de saúde e que está apta a viajar. Outro ponto é a idade
              permitida para a gestante. Alguns planos cobrem gestantes com no
              máximo 40 anos. A dica é sempre identificar até qual semana vocâ
              estará completamente coberta pelo plano. E lembre-se: quanto maior
              a sua cobertura, mais segura você e seu bebê estarão.
            </p>
          </>
        ),
      },

      {
        key: "23",
        label: (
          <h4 className="font-weight-bold">
            Como funciona o seguro viagem para a terceira idade? Posso
            contratar?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Funciona como qualquer outro seguro viagem, porém, como crianças e
              idosos apresentam maior probabilidade de risco, o uso poderá ser
              maior. A maioria dos planos possuem limitação de idade de até 100
              anos. Para idosos de 70 a 75 anos (dependendo da operadora), o
              valor de seu plano terá um acréscimo de 25 a 50% no valor. Mas os
              viajantes da terceira idade não precisam se preocupar, pois
              receberão o mesmo suporte e as mesmas coberturas dos demais grupos
              de viajantes. Dica: É importante ficar atento sobre a redução da
              cobertura para viajantes em idade avançada. Acima de 85 anos, por
              exemplo, a cobertura reduzirá 50% do valor. Na Multi Seguro
              Viagem, nenhum dos planos possuem redução de cobertura para
              viajantes da terceira idade.
            </p>
          </>
        ),
      },
      {
        key: "24",
        label: (
          <h4 className="font-weight-bold">
            Quais países exigem a contratação do Seguro Viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              A aquisição do seguro viagem é recomendável para qualquer destino.
              No entanto, alguns países exigem a contratação do seguro com um
              valor mínimo de cobertura. Confira aqui todos os detalhes dessas
              exigências. Ficou com dúvida? Não hesite em falar com a Multi
              Seguro Viagem.
            </p>
          </>
        ),
      },

      {
        key: "25",
        label: (
          <h4 className="font-weight-bold">
            O que é Tratado de Schengen? Quais são suas exigências?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              O Tratado de Schengen, existente há 30 anos, é um acordo entre
              alguns países europeus sobre a política de abertura de fronteiras
              para livre circulação de turistas e estudantes. Portanto, o seguro
              viagem é obrigatório para quem pretende visitar os países do
              tratado. A exigência é que o viajante adquira um seguro viagem com
              cobertura mínima de 30 mil Euros para despesas médicas e
              hospitalares. Embarcar sem este seguro poderá colocar sua viagem
              em risco, com grandes chances de ser deportado para o país de
              origem. É importante saber quais países fazem parte do Tratado e
              quais são as exigências para o desembarque nestes países Nós, da
              Multi Seguro Viagem, recomendamos sempre fazer um seguro, não
              importa o destino.{" "}
            </p>
          </>
        ),
      },

      {
        key: "26",
        label: (
          <h4 className="font-weight-bold">
            Quais países fazem parte do Tratado de Schengen?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Aproximadamente 27 países fazem parte do Tratado de Schengen:
              Alemanha, Áustria, Bélgica, Dinamarca, Eslováquia, Eslovênia,
              Espanha, Estônia, Finlândia, França, Grécia, Holanda, Hungria,
              Islândia, Itália, Letônia, Liechtenstein, Lituânia, Luxemburgo,
              Malta, Noruega, Polônia, Portugal, República Tcheca, Suécia e
              Suíça. Para viajar tranquilo, com segurança e garantir sua livre
              circulação em todos esses países, adquira seu seguro com a Multi
              Seguro Viagem!
            </p>
          </>
        ),
      },

      {
        key: "27",
        label: (
          <h4 className="font-weight-bold">Para que serve o Visto Schengen?</h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Se você planeja um intercâmbio ou viagem a trabalho para alguns
              dos países do Tratado de Schengen, saiba que o período superior
              aos 90 dias consecutivos exige a emissão de visto. A autorização é
              emitida pelo Consulado ou Embaixada do país onde você ficará
              instalado, seguindo o período solicitado na hora da emissão.
            </p>
          </>
        ),
      },

      {
        key: "28",
        label: (
          <h4 className="font-weight-bold">
            Quais documentos são exigidos para o Visto Schengen?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Se você pretende realizar um intercâmbio ou viagem a trabalho para
              alguns dos países que fazem parte do Tratado de Schengen, saiba
              que o período superior a 90 dias consecutivos exige a emissão de
              visto. A autorização é emitida pelo Consulado ou Embaixada do país
              que você ficará instalado, durante o período solicitado no momento
              da emissão. Alguns documentos devem ser apresentados para a
              liberação. Entre os principais, estão:
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro viagem com cobertura de até 30 mil euros
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Passaporte Original
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Comprovante de passagem aérea
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Reservas de hospedagem ou carta-convite: nos casos de
                  estadia em casas de amigos/parentes.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Comprovante de situação financeira: geralmente é
                  solicitado o extrato bancário dos últimos seis meses. Para
                  estudantes que não possuem renda é possível garantir a ajuda
                  de terceiros durante o período de estadia, por meio de uma
                  declaração oficial.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Formulário de aplicação de visto Schengen preenchido
                  e com foto 3x4 recente
                </p>
              </li>
            </ul>

            <p className="font-size-md text-justify">
              Alguns países, além do Tratado de Schengen, também exigem a
              obtenção do seguro viagem, porém sem coberturas mínimas:
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Países do Golfo – Emirados Árabes Unidos, Qatar e
                  Turquia
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Cuba
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Venezuela - Exige seguro viagem e mantém um padrão
                  parecido do Tratado de Schengen, porém com cobertura mínima de
                  40 mil dólares para assistência médica, repatriação e
                  funerária.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Reservas de hospedagem ou carta-convite: nos casos de
                  estadia em casas de amigos/parentes.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Austrália - Exige a cobertura de assistência viagem
                  para estudantes e a contratação de um seguro próprio, chamado
                  OSHC, para que o estudante tenha direito ao sistema público de
                  saúde do país.
                </p>
              </li>
            </ul>
          </>
        ),
      },

      {
        key: "29",
        label: (
          <h4 className="font-weight-bold">
            Quais modelos de coberturas os viajantes mais procuram no Seguro
            Viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Pela nossa experiência de anos no mercado, identificamos que as
              coberturas mais procuradas pelos viajantes são:
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Assistência Médica: cobertura concedida caso o
                  viajante sofra algum acidente ou adquira alguma enfermidade em
                  seu período de viagem. Nesta cobertura, você terá direito a um
                  atendimento médico particular.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Extravio de Bagagem: caso sua bagagem seja
                  extraviada, a operadora irá indenizar o valor para a compra de
                  alguns suprimentos básicos, até seu limite de cobertura, e de
                  acordo com o peso de sua bagagem.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Doença Pré-Existente: é uma cobertura para viajantes
                  que já tomam algum tipo de medicamento controlado ou que
                  tenham algum problema de saúde pré-existente.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Odontológica: cobertura para qualquer problema
                  dentário que impeça o viajante de prosseguir com sua viagem,
                  Em caso de dor e incômodo, a operadora também prestará o
                  atendimento odontológico.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Farmacêutica: caso você passe por um atendimento
                  médico e o mesmo receite algum medicamento, essa cobertura
                  ajudará nos custos farmacêuticos.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro por Morte Acidental: em caso de falecimento do
                  viajante, por acidente, o seguro pagará uma indenização aos
                  herdeiros legais.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Prática de Esportes: esta cobertura garante o direito
                  a uma assistência médica, em casos de acidente por algum
                  esporte de caráter amador
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Gestação: oferece assistência médica para gestantes,
                  em caso de emergência referente a problemas da gestação. Não
                  cobre acompanhamento médico, apenas atendimento emergencial.
                </p>
              </li>
            </ul>

            <p className="font-size-md text-justify">
              Identificou a cobertura de seu interesse? Fale com a Multi Seguro
              Viagem.
            </p>
          </>
        ),
      },

      {
        key: "30",
        label: (
          <h4 className="font-weight-bold">
            Como funciona Seguro Viagem para Cruzeiro/Seguro Marítimo?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Não é necessário contratar um plano específico para viagens de
              cruzeiro ou viagens marítimas, pois nenhum plano de Seguro Viagem
              diferencia viagens terrestres e marítimas. A questão é que os
              navios não possuem parcerias com operadoras de seguro viagem,
              sendo assim, quando o cliente estiver em alto mar e precisar de um
              atendimento médico, será necessário que o mesmo utilize o
              atendimento do próprio navio, arcando com os devidos custos, se
              houver. Porém, basta o viajante guardar todos os laudos,
              recibos/notas fiscais para solicitar o reembolso do valor depois
              da viagem. E, nos casos em que o viajante estiver em terra, ou
              seja, em uma parada do Navio para passeios terrestres, ele poderá
              entrar em contato com a central de atendimento 24hrs. Esta central
              irá passar todo procedimento a ser seguido e encaminhará o cliente
              para a rede credenciada, ou encaminhará o médico até o cliente.
              Tudo dependerá da sua localização e disponibilidade.{" "}
            </p>
          </>
        ),
      },

      {
        key: "31",
        label: (
          <h4 className="font-weight-bold">
            Vou viajar para continentes diferentes. Como funciona o seguro neste
            caso?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Quando o viajante for viajar para continentes diferentes, em um
              mesmo período de viagem, é necessário que o plano cubra as
              necessidades de ambos os continentes. No caso de viagens que
              incluem o continente Europeu, é imprescindível lembrar que o
              seguro viagem esteja dentro das exigências do Tratado de Schengem,
              que exige que o viajante tenha um Seguro/Assistência Viagem com
              cobertura para assistência médica de no mínimo 30 mil euros. A
              sugestão de nossos consultores é que o cliente contrate um seguro
              viagem com cobertura mínima de 50 mil dólares de assistência
              médica, pois os planos em dólares são válidos mundialmente. Assim,
              quando o viajante passar pelo território Europeu, a cobertura
              médica de seu seguro será convertido na moeda local, totalizando
              aproximadamente 40 mil euros de assistência médica internacional.
            </p>
          </>
        ),
      },

      {
        key: "32",
        label: (
          <h4 className="font-weight-bold">
            Qual a diferença entre Seguro Viagem e Assistência Viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Antigamente, no momento de contratação do seguro viagem , o
              viajante muitas vezes ficava confuso com as diferentes opções de
              planos de seguro oferecidas, principalmente ao contratar com
              agências de turismo, `que não tem a especialidade de vender
              seguros. O mercado disponibilizava planos de Seguro Viagem e
              Assistência Viagem , comercializados separadamente, por
              Seguradoras e também por operadoras de Assistência Viagem . O que
              gerava muita confusão aos viajantes é que, além de existirem as
              duas opções, a diferença entre os serviços prestados pela
              seguradora e os serviços das assistências não era clara e, muitas
              vezes, se confundiam pela extrema semelhança. Para esclarecer, é
              preciso compreender que os planos de Seguro Viagem têm, por
              natureza, características indenizatórias e possuem como principais
              coberturas a morte ou a invalidez permanente, ocorridos durante a
              vigência da apólice. Já a Assistência Viagem é um serviço cujo
              objetivo é assistir o viajante durante sua viagem, com a prestação
              da assistência médica e hospitalar , por meio de prestadores de
              serviços credenciados que são informados no momento da necessidade
              do viajante, quando o mesmo entra em contato com a central de
              atendimento. Antes, o objetivo da Assistência Viagem não era
              claramente indenizatório e não considerava-se então um seguro de
              fato, mas sim um serviço de assistência, que não era prestado por
              seguradoras e nem regulamentado por orgãos como a SUSEP . Ambos os
              planos tinham como objetivo atender o viajante durante todo o
              período de viagem contratado, porém, como tinham características
              de coberturas e dinâmicas diferentes, o viajante só percebia ao
              precisar, que não tinha contratado o plano correto para atender
              suas reais necessidades. Descobriam, por exemplo, que diante de
              algum procedimento não tinham o direito ao reembolso de despesas
              médicas, justamente quando mais necessitavam desses serviços. O
              produto Seguro Viagem padrão, que era oferecido por seguradoras e
              algumas operadoras de cartão de crédito, não possuíam um plano de
              assistência agregado. Em casos de imprevistos, o viajante deveria
              arcar com todas as despesas no momento do ocorrido, e só depois
              realizar o pedido de reembolso, o que trazia muitos problemas,
              especialmente devido aos altos custos para atendimento no exterior
              versus o limite disponível no cartão do cliente por exemplo.
              Nesses casos, o reembolso só acontece quando você chega ao Brasil,
              e com prazo máximo de até 30 dias para recebimento em moeda
              nacional. Isso causava transtornos diversos, uma vez que o
              viajante deveria ter recurso suficiente para pagar suas despesas
              médicas no exterior. O Serviço de Assistência Viagem , por sua
              vez, oferecido pelas Operadoras sérias do Mercado, realiza
              atendimento aos viajantes em sua rede credenciada , sem custo ao
              viajante. Em caso de imprevistos, o viajante segurado deve sempre
              acionar primeiro a central de atendimento, não sendo necessário
              utilizar seus recursos próprios. Mesmo não havendo rede
              credenciada , o viajante pode pagar o atendimento e solicitar o
              reembolso posteriormente no retorno ao Brasil, desde que esteja
              com a autorização da rede de Assistência que contratou. O que
              pegava muitos viajantes de surpresa era a obrigatoriedade da
              comunicação com a central de atendimento . Em alguns casos, sem a
              autorização devida, a operadora podia negar um reembolso de
              despesas. As duas opções tem o objetivo de auxiliar o viajante
              durante seu período em viagem, porém, pequenas particularidades
              nos modelos de atendimento acabavam confundindo muito o viajante
              na contratação. Se ainda tiver alguma dúvida, pode falar com um de
              nossos especialistas da Multi Seguro Viagem ok? Telefone (19)
              3201-0447
            </p>
          </>
        ),
      },

      {
        key: "33",
        label: (
          <h4 className="font-weight-bold">
            Qual a diferença entre o Plano de Seguro Viagem e Seguro Saúde?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              <strong>Plano de Seguro Viagem</strong>
            </p>
            <p className="font-size-md text-justify">
              Tem por objetivo garantir que qualquer emergência ocorrida durante
              a vigência do seguro (período de viagem), seja indenizada ou
              coberta através da prestação do serviço de assistência em viagem.
              Você pode utilizar toda a rede credenciada ou ainda obter
              reembolso, quando necessário. O objetivo se resume a tratar
              eventuais emergências, acidentes e imprevistos que venham a
              ocorrer exclusivamente no período da viagem.
            </p>

            <p className="font-size-md text-justify">
              <strong>Seguro Saúde</strong>
            </p>
            <p className="font-size-md text-justify">
              O seguro saúde assemelha-se a Assistência Médica, no caráter
              completo, incluindo a prevenção e tratamento de doenças, além de
              consultas e exames de rotina, que não fazem parte do rol de
              atendimento do Seguro Viagem. Porém, trata-se de um produto que
              não tem foco em viagens, mas sim na saúde do segurado,
              independente do mesmo estar viajando. Por isso, suas coberturas
              são exclusivamente relacionadas à saúde, excluindo-se coberturas
              de outros tipos, como perda de bagagem, por exemplo. Uma das
              grandes diferenças entre o Seguro Saúde e o Seguro Viagem é que,
              normalmente, os planos de Seguro Saúde não são contratados por
              curtos períodos. Já o Seguro Viagem, se limita ao período de
              viagem. Além disso, o custo do Seguro Saúde é elevadíssimo,
              chegando a quase 10 vezes o valor de um Seguro Viagem para o mesmo
              período.
            </p>
          </>
        ),
      },

      {
        key: "34",
        label: (
          <h4 className="font-weight-bold">
            Quais os cuidados ao contratar um Seguro Viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Se você sofre de alguma doença crônica, o cuidado na contratação
              do seguro deverá ser redobrado! Verifique se o plano que deseja
              possui cobertura para doença preexistente. É melhor optar por
              planos com valores de coberturas maiores e mais completos, e
              adequar assim o investimento da contratação de acordo com sua
              necessidade. Em países como os Estados Unidos, por exemplo, os
              tratamentos médicos podem sair muito caros. Se você for gestante
              ou pretende praticar alguma modalidade de esporte radical, por
              exemplo, também precisará de um seguro viagem com cobertura
              especial para o seu perfil de viajante. Na dúvida, é sempre bom
              pesquisar os custos de alguns procedimentos médicos, intervenção
              cirúrgica ou até mesmo o valor de uma diária em hotel, no país de
              destino.
            </p>
          </>
        ),
      },

      {
        key: "35",
        label: (
          <h4 className="font-weight-bold">
            Qual a diferença entre o Seguro Viagem emitido pelas Operadoras e os
            emitidos pelas empresas de Cartão de Credito?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Alguns clientes obtém o seguro viagem gratuitamente pelo cartão
              credito, quando compram passagens aéreas. No entanto, a utilização
              do seguro viagem via cartão de crédito é um pouco distinta do
              padrão de seguro oferecido pelas operadoras. Confira algumas
              diferenças:
            </p>

            <div className="text-center w-100">
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <th>
                    <strong>Seguro Viagem emitido pelas Operadoras</strong>
                  </th>
                  <th>
                    <strong>
                      Seguro Viagem emitido pelo Cartão de Crédito
                    </strong>
                  </th>
                </tr>
                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">
                    Os viajantes são livres para escolher a cobertura que melhor
                    se adequar ao seu perfil, e ao perfil de sua viagem.
                  </td>
                  <td className="p-3">
                    Geralmente o cliente só terá o seguro liberado se comprar as
                    passagens aéreas pelo cartão, e o valor de cobertura é
                    limitado, sem a opção de escolha.
                  </td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">
                    Empresa especialista em assistência de viagem, com rede
                    credenciada e possibilidade do médico ir até seu hotel
                  </td>
                  <td className="p-3">
                    {" "}
                    Diversas linhas de serviços, normalmente necessário o
                    Usuário gastar e depois pedir reembolso.
                  </td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">
                    {" "}
                    Central de atendimento 24h dedicada a atender passageiros
                    durante o período da viagem
                  </td>
                  <td className="p-3">
                    {" "}
                    Central de atendimento comum para informações sobre o seguro
                    e demais serviços monetários.
                  </td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">
                    {" "}
                    Possibilidade de contratação para acompanhantes, incluindo
                    planos especiais em diversos produtos
                  </td>
                  <td className="p-3">
                    {" "}
                    Contempla apenas do titular, conforme perfil do cartão.
                  </td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">
                    Amplas coberturas, serviços e conveniência para o viajante.
                  </td>
                  <td className="p-3">
                    {" "}
                    Coberturas e serviços limitados de acordo com o perfil do
                    cartão.
                  </td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">
                    {" "}
                    Opções de assistência integral, sem custo por meio de rede
                    credenciada ou mediante reembolso.
                  </td>
                  <td className="p-3">
                    {" "}
                    A maioria dos cartões funciona apenas por meio de reembolso.
                  </td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">
                    Período de contratação de até 365 dias consecutivos em
                    viagem
                  </td>
                  <td className="p-3">
                    {" "}
                    Período de contratação limitado de acordo com o perfil do
                    cartão.
                  </td>
                </tr>
              </table>
            </div>
          </>
        ),
      },
    ];
  }
};

export default Faq;
