import { Card, Col, Row } from "antd";
import {
  IoEyeOutline
} from "react-icons/io5";
import { FaRegHandBackFist } from "react-icons/fa6";
import { LiaHandHoldingHeartSolid } from "react-icons/lia";

const AboutUs = () => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Row justify="center" className="mb-3">
      <Col style={{ width: 836 }}>
        <Card
          className="strong-border card-without-padding"
          title={
            <Row>
              <Col span={24}>
                <h1 className="text-center">Sobre nós</h1>
                <h3 className="text-center text-muted mb-4">&nbsp;</h3>
              </Col>
            </Row>
          }
          style={{
            margin: "18px 18px",
            maxWidth: 800,
          }}
          classNames="text-center"
        >
          <p className="font-size-md p-3 text-justify">
            A Multi Seguro Viagem é uma plataforma online que reúne as
            principais Operadoras de Seguro Viagem em um local seguro e rápido,
            onde o usuário pode cotar, comparar planos e valores de operadoras
            qualificadas no mercado e finalizar a compra de maneira digital,
            rápida e econômica.
          </p>

          <Row>
            <Col xs={24} md={8} className="text-center p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <FaRegHandBackFist />
                </div>
                <strong className="text-uppercase">Missão</strong>
              </div>
              <p>
                Manter a máxima qualidade na apresentação de resultados em
                Seguro Viagem, atuando como cotador eficiente, rápido e
                responsivo em todos os dispositivos, navegadores e plataformas.
                Garantimos resultados de excelência, permitindo a escolha do
                plano que melhor se adeque às expectativas e necessidades do
                viajante, gerando resultado de excelência em compra online,
                atendimento e pós-venda.
              </p>
            </Col>
            <Col xs={24} md={8} className="text-center p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <IoEyeOutline />
                </div>
                <strong className="text-uppercase">Visão</strong>
              </div>
              <p>
                Ser referência em e-commerce como plataforma comparadora de
                Seguro Viagem no mercado brasileiro, sendo reconhecida por sua
                excelência no atendimento, precisão de resultados, clareza de
                informações e pela agilidade oferecida aos clientes.
              </p>
            </Col>

            <Col xs={24} md={8} className="text-center p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <LiaHandHoldingHeartSolid />
                </div>
                <strong className="text-uppercase">Valores</strong>
              </div>
              <p>
                Para nós, agilidade, tecnologia e compromisso caminham lado a
                lado e são imprescindíveis para realizar nosso trabalho com
                excelência e transparência, buscando sempre conquistar a
                credibilidade e satisfação de quem busca nossos serviços.
              </p>
            </Col>
          </Row>

          {/*<h4>*/}
          {/*  <strong>Sobre Nós</strong>*/}
          {/*</h4>*/}

          <p className="font-size-md p-3 text-justify">
            A Multi Seguro Viagem é uma ferramenta digital pensada e
            desenvolvida para ser acessada de onde estiver, com a facilidade e
            praticidade necessária para o dia a dia, sempre ofertando as
            melhores opções em Operadoras de Seguro Viagem. Assim é a Multi. Um
            local onde você insere os dados da sua viagem e encontra diversas
            opções disponíveis em um só ambiente, podendo filtrar pelos preços
            de Seguro Viagem, maiores coberturas e encontrando o plano de Seguro
            Viagem que melhor se adeque ao destino da sua viagem. Nosso
            compromisso é facilitar a busca de viajantes que desejam serviços
            cada vez mais personalizados e exclusivos, trabalhando sempre com
            segurança e transparência, buscando os melhores preços por meio de
            parcerias com seguradoras com as quais negociamos os melhores
            descontos e repassamos para você! A Multi Seguro Viagem, tem ajudado
            milhares de viajantes a aproveitarem seu destino com segurança, se
            antecipando a possíveis incidentes, garantindo uma viagem tranquila
            e sem dor de cabeça. O que comprova a receptividade de consumidores
            para essa nova modalidade de e-commerce. Conquistando cada vez mais
            o público com nossa maneira de priorizar os objetivos de nossos
            viajantes – Buscando Seguro Online, Comparando e Encontrando as
            Operadoras mais Qualificadas no Melhor Preço do Mercado, trazemos
            como diferencial todo o auxílio e suporte durante todo o processo,
            agindo sempre com objetividade, segurança e ética, o que nos torna
            referência no segmento.
          </p>
        </Card>
      </Col>
    </Row>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default AboutUs;
