import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "containers/Layout/Layout";
import Loader from "components/Loader/Loader";
import {
  AUTH_ROUTE,
  HOME_PAGE,
  INFO_BENEFITS_COVERAGE_ROUTE,
  INFO_COMPANY_ROUTE,
  INFO_CONTACT_ROUTE,
  INFO_EUROPE_TRAVEL_INSURANCE_ROUTE,
  INFO_EXCHANGE_TRAVEL_INSURANCE_ROUTE,
  INFO_FAQ_ROUTE,
  INFO_FOR_FOREIGNERS_EN_ROUTE,
  INFO_FOR_FOREIGNERS_ES_ROUTE,
  INFO_FOR_FOREIGNERS_PT_ROUTE,
  INFO_INTERNATIONAL_TRAVEL_INSURANCE_ROUTE,
  INFO_PRIVACY_POLICY_ROUTE, INFO_SCHENGEN_TREATY_ROUTE,
  INFO_TRAVEL_INSURANCE_ROUTE,
  INFO_WTS_TRAVEL_INSURANCE_ROUTE,
  ORDERS_ROUTE,
  QUOTATIONS_ROUTE
} from "./settings/constant";
import appmultiService from "./services/appmultiService";
import InfoTravelInsurance from "./containers/FooterLinks/InfoTravelInsurance";
import SchengenTreaty from "./containers/FooterLinks/SchengenTreaty";
import WhatIs from "./containers/FooterLinks/WhatIs";
import BenefitsAndCoverages from "./containers/FooterLinks/BenefitsAndCoverages";
import Faq from "./containers/FooterLinks/Faq";
import TravelInsuranceExchange from "./containers/FooterLinks/TravelInsuranceExchange";
import InfoInternationalTravelInsurance from "./containers/FooterLinks/InfoInternationalTravelInsurance";
import InfoEuropeTravelInsurance from "./containers/FooterLinks/InfoEuropeTravelInsurance";
import InfoForForeignersIndexPortuguese from "./containers/FooterLinks/InfoForForeignersIndexPortuguese";
import InfoForForeignersIndexEnglish from "./containers/FooterLinks/InfoForForeignersIndexEnglish";
import InfoForForeignersIndexSpanish from "./containers/FooterLinks/InfoForForeignersIndexSpanish";
import AboutUs from "./containers/FooterLinks/AboutUs";
import MultiContactForm from "./containers/FooterLinks/ContactForm";
import PrivacyPolicy from "./containers/FooterLinks/PrivacyPolicy";

// public routes
const HomePage = React.lazy(() => import("containers/Home/Home"));

const QuotationsIndex = React.lazy(() =>
  import("containers/Quotations/QuotationsIndex"),
);

// const InfoForForeignersIndex = React.lazy(() =>
//   import("containers/FooterLinks/InfoForForeignersIndexEnglish"),
// );

const AuthIndex = React.lazy(() => import("containers/Auth/AuthIndex"));

const OrdersIndex = React.lazy(() => import("containers/Orders/OrdersIndex"));

const NotFound = React.lazy(() => import("containers/404/404"));

// protected route

export default function AppRoutes() {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    // load all parameters (after application loaded)
    (async () => {
      await appmultiService.loadParameters();
      setReady(true);
    })();
  }, []);

  if (!ready) return <Loader />;

  return (
    <Routes>
      <Route path={HOME_PAGE} element={<Layout />}>
        {/* HOME */}
        <Route
          index
          element={
            <React.Suspense fallback={<Loader />}>
              <HomePage />
            </React.Suspense>
          }
        />
        {/* HOME */}

        {/* QUOTATION */}
        <Route
          path={QUOTATIONS_ROUTE}
          element={
            <React.Suspense fallback={<Loader />}>
              <QuotationsIndex />
            </React.Suspense>
          }
        />
        {/* QUOTATION */}

        {/* AUTH */}
        <Route
          path={AUTH_ROUTE}
          element={
            <React.Suspense fallback={<Loader />}>
              <AuthIndex />
            </React.Suspense>
          }
        />
        {/* AUTH */}

        {/* ORDERS */}
        <Route
          path={ORDERS_ROUTE}
          element={
            <React.Suspense fallback={<Loader />}>
              <OrdersIndex />
            </React.Suspense>
          }
        />
        {/* ORDERS */}

        {/* INFO TRAVEL INSURANCE */}
        <Route
          path={INFO_TRAVEL_INSURANCE_ROUTE}
          element={
            <React.Suspense fallback={<Loader />}>
              <InfoTravelInsurance />
            </React.Suspense>
          }
        />
        {/* INFO TRAVEL INSURANCE */}

        {/* INFO WTS TRAVEL INSURANCE */}
        <Route
          path={INFO_WTS_TRAVEL_INSURANCE_ROUTE}
          element={
            <React.Suspense fallback={<Loader />}>
              <WhatIs />
            </React.Suspense>
          }
        />
        {/* INFO WTS TRAVEL INSURANCE */}

        {/* INFO BENEFITS COVERAGES */}
        <Route
          path={INFO_BENEFITS_COVERAGE_ROUTE}
          element={
            <React.Suspense fallback={<Loader />}>
              <BenefitsAndCoverages />
            </React.Suspense>
          }
        />
        {/* INFO BENEFITS COVERAGES */}

        {/* INFO FAQ */}
        <Route
          path={INFO_FAQ_ROUTE}
          element={
            <React.Suspense fallback={<Loader />}>
              <Faq />
            </React.Suspense>
          }
        />
        {/* INFO FAQ */}

        {/* INFO SCHENGEN TREATY */}
        <Route
          path={INFO_SCHENGEN_TREATY_ROUTE}
          element={
            <React.Suspense fallback={<Loader />}>
              <SchengenTreaty />
            </React.Suspense>
          }
        />
        {/* INFO SCHENGEN TREATY */}

        {/* INFO EXCHANGE TRAVEL INSURANCE */}
        <Route
          path={INFO_EXCHANGE_TRAVEL_INSURANCE_ROUTE}
          element={
            <React.Suspense fallback={<Loader />}>
              <TravelInsuranceExchange />
            </React.Suspense>
          }
        />
        {/* INFO EXCHANGE TRAVEL INSURANCE */}

        {/* INFO INTERNATIONAL TRAVEL INSURANCE */}
        <Route
          path={INFO_INTERNATIONAL_TRAVEL_INSURANCE_ROUTE}
          element={
            <React.Suspense fallback={<Loader />}>
              <InfoInternationalTravelInsurance />
            </React.Suspense>
          }
        />
        {/* INFO EXCHANGE TRAVEL INSURANCE */}

        {/* INFO EUROPE TRAVEL INSURANCE */}
        <Route
          path={INFO_EUROPE_TRAVEL_INSURANCE_ROUTE}
          element={
            <React.Suspense fallback={<Loader />}>
              <InfoEuropeTravelInsurance />
            </React.Suspense>
          }
        />
        {/* INFO EUROPE TRAVEL INSURANCE */}

        {/* INFO FOR FOREIGNERS EN */}
        <Route
          path={INFO_FOR_FOREIGNERS_EN_ROUTE}
          element={
            <React.Suspense fallback={<Loader />}>
              <InfoForForeignersIndexEnglish />
            </React.Suspense>
          }
        />
        {/* INFO FOR FOREIGNERS EN */}

        {/* INFO FOR FOREIGNERS PT */}
        <Route
          path={INFO_FOR_FOREIGNERS_PT_ROUTE}
          element={
            <React.Suspense fallback={<Loader />}>
              <InfoForForeignersIndexPortuguese />
            </React.Suspense>
          }
        />
        {/* INFO FOR FOREIGNERS PT */}

        {/* INFO FOR FOREIGNERS ES */}
        <Route
          path={INFO_FOR_FOREIGNERS_ES_ROUTE}
          element={
            <React.Suspense fallback={<Loader />}>
              <InfoForForeignersIndexSpanish />
            </React.Suspense>
          }
        />
        {/* INFO FOR FOREIGNERS ES */}

        {/* INFO COMPANY */}
        <Route
          path={INFO_COMPANY_ROUTE}
          element={
            <React.Suspense fallback={<Loader />}>
              <AboutUs />
            </React.Suspense>
          }
        />
        {/* INFO COMPANY */}

        {/* INFO CONTACT */}
        <Route
          path={INFO_CONTACT_ROUTE}
          element={
            <React.Suspense fallback={<Loader />}>
              <MultiContactForm />
            </React.Suspense>
          }
        />
        {/* INFO CONTACT */}

        {/* INFO PROMOTIONS */}
        {/*<Route*/}
        {/*  path={INFO_PROMOTIONS_ROUTE}*/}
        {/*  element={*/}
        {/*    <React.Suspense fallback={<Loader />}>*/}
        {/*      <NotFound />*/}
        {/*    </React.Suspense>*/}
        {/*  }*/}
        {/*/>*/}
        {/* INFO PROMOTIONS */}

        {/* INFO PRIVACY POLICY */}
        <Route
          path={INFO_PRIVACY_POLICY_ROUTE}
          element={
            <React.Suspense fallback={<Loader />}>
              <PrivacyPolicy />
            </React.Suspense>
          }
        />
        {/* INFO PRIVACY POLICY */}

        <Route
          path="*"
          element={
            <React.Suspense fallback={<Loader />}>
              <NotFound />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
}
