import { Card, Col, Collapse, Row } from "antd";

const PrivacyPolicy = () => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const items = buildItems();

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Row justify="center" className="mb-3">
      <Col style={{ width: 836 }}>
        <Card
          className="strong-border card-without-padding"
          title={
            <Row>
              <Col span={24}>
                <h1 className="text-center">Política de Privacidade</h1>
                <h3 className="text-center text-muted mb-4">
                  Entenda como tratamos os dados cadastrados em nosso site.
                </h3>
              </Col>
            </Row>
          }
          style={{
            margin: "18px 18px",
            maxWidth: 800,
          }}
          classNames="text-center"
        >
          <p className="font-size-md p-3 text-justify">
            Este documento faz parte de um conjunto de ações que a Multi Seguro
            Viagem estabeleceu com o objetivo de respeitar e preservar o direito
            ao sigilo de identidade de cada cliente. O site Multi Seguro Viagem
            é identificado, neste documento, de duas formas: Como:
            www.multiseguroviagem.com.br ou, site
          </p>

          <p className="font-size-md p-3 text-justify">
            A presente Política de Privacidade e Segurança de Dados do
            www.multiseguroviagem.com.br contém diretrizes e informações da
            forma que a Multi Seguro Viagem trata e protege as informações
            capturadas por meio de seu site, e será regida pelas condições
            abaixo especificadas:
          </p>

          <Collapse accordion items={items} className="mb-4" ghost />
        </Card>
      </Col>
    </Row>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildItems() {
    return [
      {
        key: "1",
        label: <h4 className="font-weight-bold">Glossário</h4>,
        children: (
          <>
            <p className="font-size-md text-justify">
              Consumidor/usuários são todas as pessoas que usam os produtos e/ou
              serviços oferecidos pelo www.multiseguroviagem.com.br, pagos ou
              não. Por ser este serviço oferecido como ferramenta virtual, os
              consumidores/usuários, são entendidos como “internautas”. Cookie é
              um arquivo-texto enviado pelo servidor da Multi Seguro Viagem para
              o computador do internauta seja identificado, personalizando os
              acessos do mesmo e, obtendo dados de acesso, como páginas
              navegadas ou link's clicados. Um cookie é atribuído
              individualmente a cada computador, ele não pode ser usado para
              executar programas nem infectar computadores com vírus, e pode ser
              lido apenas pelo servidor que o enviou. Criptografia é a
              nomenclatura do processo de codificação de informações. As
              informações são codificadas (embaralhadas) na origem e
              decodificadas no destino, dificultando, dessa forma, que as
              informações que trafegam na internet sejam decifradas.
              Identificação individual é toda informação que não está disponível
              para o público em geral, e que, se divulgada, possa vir a causar
              algum tipo de constrangimento moral, danos ou prejuízos à própria
              pessoa ou à empresa a qual pertence. São exemplos de dados de
              identificação individual os nºs de CPF ou CNPJ, fornecidos para
              contato e dados sobre cobrança. Parceiro é toda pessoa física, ou
              jurídica, que mantém vínculo de contratante da Multi Seguro
              Viagem, disponibilizando no www.multiseguroviagem.com.br
              produtos/serviços.
            </p>
          </>
        ),
      },

      {
        key: "2",
        label: (
          <h4 className="font-weight-bold">Compromisso Multi Seguro Viagem</h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              A Multi Seguro Viagem está comprometida em coletar em seu site,
              apenas, as informações sobre identificação individual necessárias
              à viabilização de seus negócios e ao fornecimento de produtos e/ou
              serviços solicitados por seus usuários. Os usuários/consumidores
              cadastrados no sistema, automaticamente autorizam o recebimento de
              material informativo promocional, com a possibilidade de cancelar
              o recebimento a qualquer momento; Os cookies serão utilizados
              apenas para controlar a audiência e a navegação em seu site; A
              Multi Seguro Viagem compromete-se a cumprir rigorosamente todas as
              determinações desta Política de Privacidade e Segurança de Dados.
            </p>
          </>
        ),
      },

      {
        key: "3",
        label: (
          <h4 className="font-weight-bold">
            Privacidade de Dados / Captura de Dados
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Ao se cadastrar, navegar ou solicitar produtos ou serviços do
              www.multiseguroviagem.com.br, em algumas ações específicas, serão
              requisitadas informações sobre:
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Política de Privacidade
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Identificação individual do cliente;
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Preferências do cliente;
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Opiniões do internauta;
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Acesso do internauta (exemplo: data e horário de
                  realização do acesso);
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Perfil de acesso do internauta (exemplo: links
                  clicados, páginas visitadas);
                </p>
              </li>
            </ul>

            <p className="font-size-md text-justify">
              <strong>Utilização de Dados</strong>
              <p>
                As informações capturadas por meio do
                www.multiseguroviagem.com.br são utilizadas pela empresa com a
                finalidade de:
              </p>
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Permitir aos internautas navegar ou realizar as
                  operações disponibilizadas no site;
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Viabilizar o fornecimento de produtos ou serviços
                  solicitados no site;
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Identificar o perfil e a necessidades dos
                  internautas, a fim de aprimorar os produtos e/ou serviços
                  oferecidos pela empresa;
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Realizar estatísticas genéricas;
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Enviar informativos sobre produtos ou serviços que
                  interessem aos seus clientes;
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Fornecer as informações aos parceiros;
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Divulgar alterações, inovações ou promoções sobre os
                  produtos e serviços da Multi Seguro Viagem e de seus
                  parceiros;
                </p>
              </li>
            </ul>
          </>
        ),
      },

      {
        key: "4",
        label: <h4 className="font-weight-bold">Divulgação de Dados</h4>,
        children: (
          <>
            <p className="font-size-md text-justify">
              Multi Seguro Viagem não fornece a terceiros, além de seus
              parceiros, dados sobre a identificação individual de internautas,
              sem seu prévio consentimento. Excetuam-se os casos em que:
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Exista determinação judicial para fornecimento de
                  dados;
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; O internauta/usuário deste site autorize esta
                  divulgação quando participar de alguma Promoção onde seja
                  solicitada sua concordância com esta divulgação;
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; A viabilização dos negócios, produtos ou serviços
                  oferecidos pela Multi Seguro Viagem dependa do repasse de
                  dados a parceiros. Somente serão repassadas informações
                  estritamente necessárias aos parceiros;
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Exista a necessidade de identificar ou revelar dados
                  do internauta que esteja utilizando o site com propósitos
                  ilícitos a fim de apuração de responsabilidade civil e
                  criminal deste usuário;
                </p>
              </li>
            </ul>

            <p className="font-size-md text-justify">
              Nos demais casos, sempre que houver necessidade ou interesse em
              repassar a terceiros dados de identificação individual dos
              internautas, a Multi Seguro Viagem lhes solicitará autorização
              prévia. Informações que não sejam de identificação individual
              (como demográficas, por exemplo) poderão ser repassadas a
              anunciantes, fornecedores, patrocinadores e parceiros, com o
              objetivo de melhorar a qualidade dos produtos e serviços
              oferecidos pela Multi Seguro Viagem. Aos terceiros que,
              porventura, receberem da Multi Seguro Viagem informações, de
              qualquer natureza, sobre os internautas que acessam este site
              cabe, igualmente, a responsabilidade de zelar pelo sigilo e
              segurança das referidas informações, de acordo com o Termo de Uso
              firmado entre a Multi Seguro Viagem e parceiros.
            </p>
          </>
        ),
      },

      {
        key: "5",
        label: <h4 className="font-weight-bold">Garantia de Privacidade</h4>,
        children: (
          <>
            <p className="font-size-md text-justify">
              É possível acessar sites e portais de outras empresas a partir do
              site www.multiseguroviagem.com.br, sendo que cada portal e empresa
              possui políticas próprias para garantir o sigilo e a proteção de
              dados. Dessa forma, ressaltamos que esta Política se aplica apenas
              ao site da Multi Seguro Viagem, cabendo exclusivamente ao
              internauta a responsabilidade de se informar sobre as políticas
              para preservação e proteção vigentes para os demais sites. A Multi
              Seguro Viagem se empenha expressivamente para prover segurança e
              sigilo das informações que capta. Contudo, para que as medidas
              adotadas tenham eficácia, faz-se necessário que cada internauta
              também tenha atitude responsável, sendo cuidadoso com os dados de
              sua identificação individual sempre que acessar a internet,
              informando-os somente em operações em que exista a proteção de
              dados, nunca divulgando sua identificação de usuário e sempre
              desconectando a conta do www.multiseguroviagem.com.br tão logo
              deixe de acessá-la, principalmente se dividir o computador com
              outra pessoa.{" "}
            </p>
          </>
        ),
      },

      {
        key: "6",
        label: (
          <h4 className="font-weight-bold">Segurança e Proteção de Dados</h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              As transações efetuadas para escolha da forma e efetivação do
              cadastro, independente da forma escolhida, são realizadas em
              ambiente seguro e certificado por empresa especializada em
              segurança de dados para a internet. Todas as informações
              necessárias à realização do cadastro são criptografadas. O ícone
              "Cadeado Fechado", localizado na barra de status (parte inferior
              do monitor), é o indicativo de que as informações transacionadas
              serão criptografadas. Na eventual substituição manual da
              formatação HTTPS para HTTP, o usuário passará utilizar o site sem
              formato criptografado, assumindo todas responsabilidades e riscos.
              Em alguns pontos do site www.multiseguroviagem.com.br são
              coletadas informações de identificação individual e/ou cadastrais
              (como nome completo ou razão social, endereço, telefones)
              necessárias à navegação ou utilização dos serviços disponíveis.
              Como medida de proteção, as informações de identificação
              individual e/ou cadastral, coletadas pelo
              www.multiseguroviagem.com.br, passam por processo de criptografia
              antes de ser transmitidas pela internet. As informações passam por
              processo de criptografia em todas as páginas de coleta de dados e
              nas áreas seguras do site onde um usuário é solicitados. As
              informações identificáveis, coletadas pelo Multi Seguro Viagem,
              são manipuladas apenas por pessoal autorizado. As operações
              realizadas no www.multiseguroviagem.com.br por um usuário, bem
              como as informações associadas a estas operações, são exclusivas e
              só podem ser acessadas por este usuário.
            </p>
          </>
        ),
      },

      {
        key: "7",
        label: <h4 className="font-weight-bold">Identificação do Usuário</h4>,
        children: (
          <>
            <p className="font-size-md text-justify">
              Ao se cadastrar no www.multiseguroviagem.com.br para aquisição de
              produtos ou serviços (pagos ou não) oferecidos no site, cada
              usuário recebe uma identificação única; identificação esta que
              passa a ser requerida e autenticada nos demais acessos ao site,
              sendo certo que, essa identificação, para os fins de direito,
              serve como assinatura de concordância com qualquer proposta feita
              neste site. A identificação de usuário é exclusiva, intransferível
              e criptografada para ser transmitida ao servidor da Multi Seguro
              Viagem, sendo seu email ou telefone armazenada em banco de dados.
            </p>
          </>
        ),
      },

      {
        key: "8",
        label: <h4 className="font-weight-bold">Alterações na Política</h4>,
        children: (
          <>
            <p className="font-size-md text-justify">
              A Multi Seguro Viagem poderá alterar esta política de privacidade
              e segurança de dados a qualquer momento. Toda alteração na
              presente Política de Privacidade e Segurança de Dados será
              veiculada no endereço:
              www.multiseguroviagem.com.br/seguroviagem/politica-privacidade.
              Portanto, solicitamos a leitura periódica desta Política como meio
              de se cientificar sobre a forma que a Multi Seguro Viagem coleta,
              trata e protege as suas informações. Em caso de dúvidas sobre a
              Política de Privacidade do site da Multi Seguro Viagem,
              contate-nos através do suporte on line.
            </p>
          </>
        ),
      },
    ];
  }
};

export default PrivacyPolicy;
