import React, { useEffect, useState } from "react";
import { Button, Menu } from "antd";
import { LogoutOutlined, ShoppingOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { AUTH_ROUTE, ORDERS_ROUTE } from "settings/constant";
import { isValidCpf, isValidEmail } from "utils/helpers";

const AuthMenu = ({ className, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const loginProfile = ["buyer", "traveller"].includes(
    localStorage.getItem("loginProfile"),
  )
    ? localStorage.getItem("loginProfile")
    : null;
  const customerEmail = isValidEmail(localStorage.getItem("customerEmail"))
    ? localStorage.getItem("customerEmail")
    : null;
  const travellerCpf = isValidCpf(localStorage.getItem("travellerCpf"))
    ? localStorage.getItem("travellerCpf")
    : null;

  const [logged, setLogged] = useState(false);

  useEffect(() => {
    setLogged(
      location.pathname === ORDERS_ROUTE &&
        loginProfile &&
        (customerEmail || travellerCpf),
    );
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const menuItems = [
    {
      label: (
        <Button
          size="large"
          // danger={logged}
          className="w-100"
          // type="primary"
          type={logged ? "default" : "primary"}
          icon={logged ? <LogoutOutlined /> : <ShoppingOutlined />}
          onClick={() => {
            if (onClose) onClose();

            if (logged) {
              localStorage.removeItem("loginProfile");
              localStorage.removeItem("customerEmail");
              localStorage.removeItem("travellerCpf");
            }

            navigate(AUTH_ROUTE, { replace: true });
          }}
          // icon={<LockOutlined />}
        >
          {/*ACESSO CLIENTE*/}
          {logged ? <span>SAIR</span> : <span>MEUS PEDIDOS</span>}
        </Button>
      ),
      key: "menu-2",
    },
  ];
  return <Menu className={className} items={menuItems} />;
};

export default AuthMenu;
