import { Card, Col, Collapse, Row } from "antd";

const WhatIs = () => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const items = buildItems();

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Row justify="center" className="mb-3">
      <Col style={{ width: 836 }}>
        <Card
          className="strong-border card-without-padding"
          title={
            <Row>
              <Col span={24}>
                <h1 className="text-center">O que é seguro viagem?</h1>
                <h3 className="text-center text-muted mb-4">
                  Entenda a importância de seu Seguro Viagem
                </h3>
              </Col>
            </Row>
          }
          style={{
            margin: "18px 18px",
            maxWidth: 800,
          }}
          classNames="text-center"
        >
          <p className="font-size-md p-3 text-justify">
            Planejar uma boa viagem vai além de reservar passagens aéreas e
            hospedagem. Para montar o roteiro ideal, e checar todos os itens
            necessários, também é preciso uma boa dose de pesquisa e dedicação
            sobre o destino escolhido, além é claro de contratar alguns serviços
            especializados, como o seguro viagem. Diante de tantos itens talvez
            você se pergunte: “o seguro viagem é mesmo importante?” Bom, apesar
            de passar despercebido por alguns viajantes, vale lembrar que a
            falta do seguro pode impactar diretamente em seus planos,
            principalmente se acontecer algum imprevisto com você no exterior.
            Já pensou sentir dor de dente em outro país? Ou ter sua bagagem
            extraviada? Seja para problemas de saúde, atrasos de voos ou
            cancelamentos, perda ou roubo de bagagens, emergências médicas,
            perda de documentos, entre outros, a contratação do seguro é
            indispensável. Viajantes mais experientes e precavidos já sabem da
            importância desse serviço, e tem aderido aos planos oferecidos pelas
            operadoras, que sempre oferecem um seguro personalizado para cada
            tipo de roteiro. Se você nunca utilizou o seguro, fique tranquilo.
            Aqui, explicaremos em detalhes sobre a importância do seguro viagem.
          </p>

          <Collapse accordion items={items} className="mb-4" ghost />
        </Card>
      </Col>
    </Row>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildItems() {
    return [
      {
        key: "1",
        label: (
          <h4 className="font-weight-bold">
            Por que o seguro viagem é importante?
          </h4>
        ),
        children: (
          <p className="font-size-md p-3 text-justify">
            O seguro viagem é a modalidade que possibilita assistência completa
            para viajantes, durante a sua permanência no destino escolhido. A
            principal vantagem do seguro viagem é minimizar qualquer situação ou
            imprevisto que você possa ter durante uma viagem, e que fogem do seu
            controle. Sabia que um simples atendimento médico no exterior pode
            custar milhares de dólares? É preciso se precaver em todos os
            sentidos. Seguros mais completos oferecem assistência jurídica,
            medicamentos, assistência odontológica, repatriação, extensão de
            internação hospitalar e diárias em hotéis. Para casos mais
            específicos, algumas coberturas incluem até passagem aérea para
            familiares. Quanto ao custo do seguro viagem, fique tranquilo: o
            investimento não chega a 5% do valor total da viagem! É um gasto
            mínimo perto da tranquilidade que o viajante encontrará, caso surja
            algum imprevisto. Além disso, há diversas modalidades de planos de
            seguro viagem que podem ser contratados. As coberturas também são
            bastante amplas: vão desde à proteção para riscos de morte acidental
            do segurado, invalidez total ou parcial, até para despesas médicas,
            hospitalares, odontológicas, diárias, perda ou roubo de bagagem,
            danos de malas, entre outros acontecimentos inesperados. Já os
            planos, podem ser escolhidos de acordo com o tipo de viagem
            (familiar, negócios, lazer) e valor de cobertura. Se o destino for
            internacional, as regras são um pouco diferentes. Após a assinatura
            do Tratado de Schengen, alguns países da Europa passaram a exigir a
            contratação de um seguro que cubra assistência medica e hospitalar
            de no mínimo 30 mil euros. O requisito é obrigatório e pode ser
            exigido a apresentação do comprovante na hora do desembarque. Nos
            casos de viagens nacionais, apesar de alguns planos de saúde
            atenderem em todo o Brasil, o seguro viagem pode ajudar no momento
            de um atendimento médico, por exemplo, ou contratempos como extravio
            de bagagens.
          </p>
        ),
      },

      {
        key: "2",
        label: (
          <h4 className="font-weight-bold">
            Qual a diferença entre Seguro Viagem e Assistência Viagem?
          </h4>
        ),
        children: (
          <p className="font-size-md p-3 text-justify">
            Antigamente, no momento de contratação do seguro viagem, o viajante
            muitas vezes ficava confuso com as diferentes opções de planos de
            seguro oferecidas. O mercado disponibilizava planos de Seguro Viagem
            e Assistência Viagem, comercializados separadamente, por Seguradoras
            e também por operadoras de Assistência Viagem. O que gerava muitas
            dúvidas aos viajantes é que, além de existirem as duas opções, a
            diferença entre os serviços prestados pela seguradora e os serviços
            das assistências não era clara e, muitas vezes, se confundiam pela
            extrema semelhança. Para tornar mais claro, é preciso entender que
            os planos de Seguro Viagem têm, por natureza, características
            indenizatórias e possuem como coberturas principais a morte ou a
            invalidez permanente, ocorridos durante a vigência da apólice. Já a
            Assistência Viagem é um serviço cujo objetivo é assistir o viajante
            durante sua viagem, com a prestação da assistência médica e
            hospitalar, através de prestadores de serviços credenciados. Antes,
            o objetivo da Assistência Viagem não era claramente indenizatória e
            não considerada então um seguro de fato, mas sim um serviço de
            assistência, que não era prestado por seguradoras e nem
            regulamentado por órgãos como a SUSEP. Ambos os planos tinham como
            objetivo atender o viajante durante todo o período contratado,
            porém, como tinham características de coberturas e dinâmicas
            diferentes, o viajante só percebia depois que não tinha contratado o
            plano correto para atender suas reais necessidades. Descobriam, por
            exemplo, que diante de algum procedimento não tinham o direito ao
            reembolso de despesas médicas, justamente quando mais necessitavam
            desses serviços. O produto Seguro Viagem padrão, que era oferecido
            por seguradoras e algumas operadoras de cartão de crédito, não
            possuíam um plano de assistência agregado. Em casos de imprevistos,
            o viajante deveria arcar com todas as despesas no momento do
            ocorrido, e só depois realizar o pedido de reembolso. Nesses casos,
            o reembolso só acontece quando você chega ao Brasil, e com prazo
            máximo de até 30 dias para recebimento em moeda nacional. Isso
            causava alguns transtornos, uma vez que o viajante deveria ter
            recurso suficiente para pagar suas despesas médicas no exterior. O
            Serviço de Assistência Viagem, por sua vez, oferecido pelas
            Operadoras de Mercado, realiza atendimento aos viajantes em sua rede
            credenciada. Em caso de imprevistos, o viajante deve acionar
            primeiro a central de atendimento, não sendo necessário utilizar
            recursos próprios. Mesmo não havendo rede credenciada, o viajante
            pode pagar o atendimento e solicitar o reembolso posteriormente no
            retorno ao Brasil, desde que esteja com a autorização da rede de
            Assistência. O que pegava muitos viajantes de surpresa era a
            obrigatoriedade da comunicação com a central de atendimento. Em
            alguns casos, sem a autorização devida, podia negar um reembolso de
            despesas. As duas opções tem o objetivo de auxiliar o viajante
            durante seu período em viagem, porém, pequenas particularidades nos
            modelos de atendimento acabavam confundindo muito o viajante na
            contratação.
          </p>
        ),
      },

      {
        key: "3",
        label: (
          <h4 className="font-weight-bold">
            Como funciona o modelo de Seguro Viagem atual?
          </h4>
        ),
        children: (
          <p className="font-size-md p-3 text-justify">
            A boa notícia é que a Superintendência de Seguros Privados (SUSEP),
            preocupada com a segurança e bom atendimento dos viajantes,
            disponibilizou através da Resolução 315, novas regras que visam
            beneficiar os consumidores do seguro, de forma a organizar a
            comercialização, preencher esta lacuna do mercado e oferecer mais
            segurança aos viajantes que pretendem contratar um plano de Seguro
            Viagem, seja para suas viagens internacionais ou nacionais. Com base
            na nova resolução da SUSEP, os planos de Seguro Viagem foram
            organizados e devem conter, obrigatoriamente, as principais
            coberturas como: Médicas e hospitalares em viagem, doenças
            pré-existentes, entre outras. Hoje, os Planos de Seguro Viagem
            possuem coberturas com caráter indenizatório e como complemento
            também prestam serviço de Assistência Viagem.
          </p>
        ),
      },

      {
        key: "4",
        label: (
          <h4 className="font-weight-bold">
            Vai utilizar o Seguro Viagem pela primeira vez? Veja algumas dicas!
          </h4>
        ),
        children: (
          <p className="font-size-md p-3 text-justify">
            Antes de contratar um Plano de Seguro Viagem, a orientação aos
            viajantes que nunca utilizaram esse serviço é verificar,
            primeiramente, qual seguradora garantirá coberturas indenizatórias e
            qual é a Operadora de Assistência Viagem, aquela responsável pelo
            serviço nas coberturas de assistência. Não se esqueça também de
            contratar seu seguro com um corretor/serviço autorizado, como é o
            caso da plataforma Multi Seguro Viagem, da EKTO MULTI SEGURO VIAGEM
            LTDA.
          </p>
        ),
      },

      {
        key: "5",
        label: (
          <h4 className="font-weight-bold">
            Qual a diferença entre Plano de Seguro Viagem e Seguro Saúde?
          </h4>
        ),
        children: (
          <>
            <strong>Plano de Seguro Viagem</strong>
            <p className="font-size-md p-3 text-justify">
              O Plano de Seguro Viagem oferece diferentes tipos de cobertura,
              para diferentes situações emergenciais ocorridas durante a
              vigência do seguro (período de viagem). Este tipo de seguro inclui
              cobertura médica em caso de emergência e pode ser utilizado em
              toda rede credenciada à seguradora, com possibilidade de reembolso
              quando for o caso. No entanto, é preciso efetuar o pagamento na
              hora da consulta ou pronto-socorro, e solicitar o ressarcimento
              junto à operadora, somente após o retorno. O principal objetivo do
              Seguro Viagem é se precaver de fatos não previstos com o intuito
              de reduzir eventuais prejuízos durante a viagem. De modo resumido,
              o plano de Seguro Viagem não tem em seu escopo a realização de
              tratamentos médicos preventivos, consultas e exames de rotina, ou
              de interesse do segurado. O seu objetivo é garantir assistência em
              caso de eventuais emergências, acidentes e imprevistos que venham
              ocorrer exclusivamente no período da viagem.
            </p>

            <strong>Seguro Saúde</strong>
            <p className="font-size-md p-3 text-justify">
              O Seguro Saúde, por sua vez, tem uma característica relacionada à
              Assistência Médica, incluindo a prevenção e tratamento de doenças,
              além de consultas e exames de rotina que não fazem parte do rol de
              atendimento do Seguro Viagem. O foco principal deste serviço está
              na saúde do segurado, independente do mesmo estar viajando. Por
              isso, suas coberturas são exclusivamente relacionadas à saúde,
              excluindo-se coberturas como perda de bagagem por exemplo. Uma das
              grandes diferenças entre o Seguro Saúde e o Seguro Viagem é que
              normalmente os planos de Seguro Saúde não são contratados por
              curtos períodos, como no caso do Seguro Viagem, que se limita
              somente ao período de viagem. A contratação do Seguro Saúde é
              recomendada para pessoas que vão passar um grande período no
              exterior, já que sua contratação possui vigência usual de 12
              meses. Para viagens de curto período, a necessidade dos viajantes
              passa a ser o atendimento emergencial para imprevistos, e não a
              realização de consultas médicas ou tratamento de doenças. Além
              disso, o custo de um Seguro Saúde com cobertura internacional é
              bastante elevado, podendo chegar a 10 vezes o valor de um Seguro
              Viagem para o mesmo período, o que acaba limitando bastante o
              público que consegue ter acesso ao produto.
            </p>
          </>
        ),
      },

      {
        key: "6",
        label: (
          <h4 className="font-weight-bold">
            Cuidados ao contratar um Seguro Viagem
          </h4>
        ),
        children: (
          <p className="font-size-md p-3 text-justify">
            Se você possui uma doença crônica, o cuidado na hora da contratação
            do seguro precisa ser redobrado, já que a escolha do plano deve ser
            analisada para cobertura de doença preexistente. Em países onde os
            tratamentos médicos podem ser mais caros, como os Estados Unidos,
            por exemplo, é melhor optar por planos com valores de coberturas
            maiores e mais completos, adequando o investimento da contratação
            com a necessidade do viajante. Em caso de dúvidas, vale pesquisar os
            custos de alguns procedimentos médicos, intervenção cirúrgica ou até
            mesmo o valor de uma diária em um hotel, no país de destino. O
            seguro viagem gestante é outro produto indispensável para as
            grávidas de plantão, uma vez que oferece atendimento para exames e
            ultrassonografias de emergência, cobertura para abortos espontâneos
            ou acidentais, partos de emergência e na ocorrência de partos
            prematuros. Mas atenção: as seguradoras estabelecem um prazo limite
            que geralmente vai até o 6º mês, principalmente para viagens
            internacionais. Reforçamos também que nos planos de Seguro Viagem,
            exames e atendimentos de rotina não estão cobertos. Outro detalhe
            importante está na prática de esportes radicais ou esportes na neve.
            Alguns seguros e assistências não cobrem acidentes esportivos em
            suas categorias básicas. É importante se atentar para adquirir um
            plano que possua a cobertura necessária ou que seja possível incluir
            essa cobertura adicional, por um custo extra. Pretende contratar um
            Plano de Seguro Viagem? Verifique qual Seguradora garantirá as
            coberturas indenizatórias, e qual Operadora de Assistência Viagem
            prestará o serviço nas coberturas de Assistência. Além disso, não se
            esqueça de contratar o Seguro sempre com um Corretor autorizado a
            atuar no mercado, como a Multi Seguro Viagem e a EKTO MULTI SEGURO
            VIAGEM LTDA.
          </p>
        ),
      },

      {
        key: "7",
        label: (
          <h4 className="font-weight-bold">
            Países que exigem a contratação do Seguro Viagem
          </h4>
        ),
        children: (
          <p className="font-size-md p-3 text-justify">
            Se você ainda não sabe quais destinos exigem a contratação do seguro
            viagem com valor mínimo, confira alguns países em que o requisito é
            obrigatório. 27 países do Tratado de Schengen: Alemanha, Áustria,
            Bélgica, Dinamarca, Eslováquia, Eslovênia, Espanha, Estônia,
            Finlândia, França, Grécia, Holanda, Hungria, Islândia, Itália,
            Letônia, Liechtenstein, Lituânia, Luxemburgo, Malta, Noruega,
            Polônia, Portugal, República Tcheca, Suécia e Suíça.). Países que
            exigem a apresentação do certificado do Seguro Viagem na hora do
            desembarque: Países árabes (Emirados Árabes Unidos, Qatar e Turquia)
            e Cuba. Agora você já sabe: antes de embarcar, considere no seu
            checklist a contratação de um seguro viagem. Lembre-se de falar com
            um corretor especializado para evitar imprevistos e Boa Viagem!{" "}
          </p>
        ),
      },
    ];
  }
};

export default WhatIs;
