import React from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import { INFO_FOR_FOREIGNERS_EN_ROUTE } from "../../../settings/constant";

const navigations = [
  {
    key: "blog",
    label: (
      <NavLink
        to="https://blog.multiseguroviagem.com.br"
        target="_blank"
        rel="noopener noreferrer"
      >
        BLOG
      </NavLink>
    ),
  },
  {
    key: "infoForForeigners",
    label: (
      <NavLink to={INFO_FOR_FOREIGNERS_EN_ROUTE}>
        <div>TRAVELLERS INSURANCE BRAZIL</div>
      </NavLink>
    ),
  },
];

const FooterMenu = () => {
  // return <Menu items={navigations} />;

  return (
    <Menu style={{ width: "auto" }}>
      {navigations.map((item) => (
        <Menu.Item key={item.key} style={{ width: "max-content" }}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default FooterMenu;
