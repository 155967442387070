import { Card, Col, Collapse, Row } from "antd";
import intercambioImg from "assets/images/intercambio.png";

const TravelInsuranceExchange = () => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const items = buildItems();

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Row justify="center" className="mb-3">
      <Col style={{ width: 836 }}>
        <Card
          className="strong-border card-without-padding"
          title={
            <Row>
              <Col span={24}>
                <h1 className="text-center">Seguro Viagem para Intercâmbio</h1>
                <h3 className="text-center text-muted mb-4">
                  Todas as Operadoras estão Aqui (até 50% OFF)
                </h3>
              </Col>
            </Row>
          }
          style={{
            margin: "18px 18px",
            maxWidth: 800,
          }}
          classNames="text-center"
        >
          <p className="font-size-md text-justify">
            A realização de um intercâmbio é uma das melhores formas de conhecer
            novas culturas, vivenciar novas experiências, aliadas ao
            aprimoramento de um novo idioma ou a especialização e complemento de
            sua formação educacional. Muitas pessoas buscam através de uma
            viagem de intercâmbio evoluir suas competências pessoais e
            profissionais, seja através de um trabalho temporário no exterior,
            ou através da realização de um curso, ou pesquisa e especialização
            profissional.
          </p>

          <Row justify="center">
            <img src={intercambioImg} alt="Internacional" />
          </Row>

          <p className="font-size-md text-justify">
            É muito importante que no planejamento de sua viagem, seja sempre
            considerada a contratação de um Seguro Viagem para Intercâmbio,
            desta forma você estará garantindo que a sua viagem ocorra de
            maneira mais segura, com a cobertura financeira de diferentes tipos
            de imprevistos e também poder contar com a assistência garantida
            pelos planos de seguro viagem em situações de emergência. Existem
            diferentes modalidades de intercâmbio, que possuem durações
            variadas, desde cursos com curtos períodos de duração, até cursos de
            graduação e especialização que podem em alguns casos durar anos até
            sua conclusão. Independente da duração do seu intercambio ter a
            cobertura de um seguro viagem é fundamental para garantir a
            conclusão de seus objetivos sem que eventuais imprevistos acabem com
            o orçamento de sua viagem. É sempre importante reforçar que as
            despesas médicas no exterior são totalmente arcadas pelo viajante
            caso ocorra alguma doença ou acidente durante a viagem, além disso
            os tratamentos médicos costumam ter valores elevados no exterior,
            dependendo da situação que aconteça o valor do tratamento médico
            pode em alguns casos ultrapassar inclusive o valor investido em todo
            o intercambio, prejudicando todo planejamento financeiro realizado.
          </p>

          <Collapse accordion items={items} className="mb-4" ghost />
        </Card>
      </Col>
    </Row>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildItems() {
    return [
      {
        key: "1",
        label: (
          <h4 className="font-weight-bold">
            Seguro Viagem é obrigatório para Intercâmbio?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Talvez você ainda não tenha fechado o seu intercâmbio, porém é
              muito importante sempre antes de viajar, verificar quais são os
              requisitos da faculdade ou da instituição organizadora de seu
              intercambio. Existem diversos programas de intercâmbio que exigem
              a contratação de um plano de seguro viagem por conta do Estudante,
              exatamente para garantir a cobertura do viajante em caso de
              ocorrências de imprevistos. É muito importante ficar atento nas
              coberturas obrigatórias, existem algumas instituições que exigem
              coberturas mínimas de até US$ 250.000,00 para a assistência
              médica, além de solicitar obrigatoriedade de cobertura em
              Repatriações e Translados Médicos. Ao realizar uma cotação no site
              Multi Seguro Viagem, você poderá contar com mais de 60 diferentes
              planos de seguro viagem de diferentes seguradoras, com certeza
              você conseguirá encontrar um que tenha as coberturas necessárias,
              caso esteja com dificuldades, não hesite em contatar a nossa
              equipe de consultores para ajudar na escolha de seu plano.
            </p>
          </>
        ),
      },

      {
        key: "2",
        label: (
          <h4 className="font-weight-bold">
            Seguro Viagem para Intercâmbio com Descontos e Vantagens Especiais:
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              As Seguradoras desenvolvem diferentes planos de Seguro Viagem com
              coberturas bastante variadas, por conta da demanda deste perfil de
              viagem, existem vários planos de Seguro Viagem no mercado com
              condições especiais para pessoas que estão indo estudar no
              exterior. Estes planos costumam ter um custo benefício muito
              interessante, normalmente com coberturas bastante completas,
              disponíveis para viagens de duração de até um ano, com tarifas
              muito atrativas. No sistema de cotação online da Multi Seguro
              Viagem é possível filtrar os planos focados neste público através
              da escolha do tipo de viagem “estudante”, podendo escolher entre
              os planos disponíveis para este público das diversas seguradoras
              comercializadas. É muito importante ressaltar que se o seu
              intercambio for apenas cultural, sem a realização de estudos, a
              condição especial pode não ser válida, já que a seguradora poderá
              solicitar um documento que comprove a sua matricula em instituição
              de ensino no exterior. Neste caso é recomendado a contratação de
              um plano tradicional, mas não se preocupe existem vários planos
              para longa estadia no exterior com condições bastante atrativas
              disponíveis também.
            </p>
          </>
        ),
      },

      {
        key: "3",
        label: (
          <h4 className="font-weight-bold">
            Coberturas do Seguro Viagem para Intercâmbio:
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              As principais coberturas dos planos de Seguro Viagem para
              Intercâmbio estão relacionadas a coberturas de Despesas Médicas e
              Hospitalares, além do seguro de bagagem e os serviços de
              assistência ao viajante que fazem toda a diferença caso aconteça
              algum imprevisto durante a viagem. É muito importante se atentar
              aos valores de cobertura contratados, quanto maior o período da
              viagem, maior o valor de cobertura recomendado, para que desta
              forma as coberturas em caso de acionamentos múltiplos sejam
              suficientes.
            </p>

            <p className="font-size-md text-justify">
              Abaixo listamos as coberturas mais comuns dos planos de Seguro
              Viagem de Intercâmbio:
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Despesas Médica e Hospitalares ocorridas em Viagem
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Despesas Médicas ocorridas em viagem relacionadas a
                  doença pré-existente
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Despesas Odontológicas Emergenciais
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Translado Médico
                </p>
              </li>{" "}
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Regresso Sanitário
                </p>
              </li>{" "}
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Despesas Farmacêuticas
                </p>
              </li>{" "}
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro para Morte Acidental em Viagem
                </p>
              </li>{" "}
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro para Invalidez Total e Parcial por acidente
                </p>
              </li>{" "}
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro para Extravio de Bagagem
                </p>
              </li>{" "}
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro de Reembolso em caso de Atraso de Bagagem
                </p>
              </li>{" "}
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro para Danos ocasionados a Mala
                </p>
              </li>{" "}
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro para Cancelamento de Viagem
                </p>
              </li>
            </ul>

            <p className="font-size-md text-justify">
              Abaixo listamos as coberturas mais comuns dos planos de Seguro
              Viagem de Intercâmbio:
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Assistência Jurídica
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Adiantamento em caso de fiança
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Auxilio para reserva de hotel para acompanhante
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Reserva de Passagem aérea de ida e volta para um
                  familiar caso seja necessário
                </p>
              </li>{" "}
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Transmissão de mensagens urgentes
                </p>
              </li>{" "}
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Assistência na localização de bagagem
                </p>
              </li>{" "}
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Orientação em caso de perda de documentos / cartões
                  de crédito
                </p>
              </li>
            </ul>
          </>
        ),
      },

      {
        key: "4",
        label: (
          <h4 className="font-weight-bold">
            Principais Planos de Seguro Viagem de Intercâmbio:
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Os principais planos disponíveis para Intercâmbios e Viagens de
              Longa Estadia são:
            </p>

            <div className="text-center w-100">
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <th>
                    <strong>Multi Seguros 40k – Long Stay</strong>
                  </th>
                  <th>
                    <strong>Student Global</strong>
                  </th>
                </tr>
                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">Seguradora: Universal Assistance </td>
                  <td className="p-3">Seguradora: GTA</td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">Assistência Médica: US$ 40.000,00</td>
                  <td className="p-3">Assistência Médica: US$ 110.000,00</td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">Perda de Bagagem: US$ 1.000,00</td>
                  <td className="p-3">Perda de Bagagem: US$ 1.200,00</td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">Custo Médio de US$ 3.00 por dia*</td>
                  <td className="p-3">
                    Custo Médio de US$ 3.00 por dia* (em longos períodos)
                  </td>
                </tr>
              </table>
            </div>

            <p className="font-size-sm text-justify">
              * As Tarifas tem o preço estimado e podem ser alteradas pelas
              seguradoras sem aviso prévio, prevalecendo sempre a cotação
              vigente no momento do fechamento do seguro.
            </p>
          </>
        ),
      },
    ];
  }
};

export default TravelInsuranceExchange;
