import { Card, Row } from "antd";

const InfoForForeignersIndexPortuguese = () => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <div>
      <Row justify="center" className="mb-3">
        <Card
          className="strong-border card-without-padding"
          title={
            <div>
              <h1 className="text-center">
                Seguro Viagem para Estrangeiros pro Brasil
              </h1>
              <h3 className="text-center text-muted mb-4">
                Estrangeiros em viagem ao Brasil
              </h3>
            </div>
          }
          style={{
            margin: "18px 18px",
            maxWidth: 800,
          }}
          classNames="text-center"
        >
          <p className="font-size-md p-3 text-justify">
            Se você é estrangeiro e pretende visitar o Brasil, a contratação de
            um seguro viagem para estrangeiros é essencial para a sua segurança
            e garantia de uma viagem tranquila! Além de todos os benefícios de
            possuir um plano de seguro viagem, de acordo com a portaria
            “PORTARIA CC-PR MJSP MINFRA MS Nº 419, DE 26 DE AGOSTO DE 2020” é
            obrigatória a contratação de seguro viagem com os seguintes
            requisitos:
          </p>

          <ul>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; § 2º O seguro citado no § 1º deste artigo deverá ter
                como finalidade a cobertura com gastos de saúde e atender aos
                seguintes requisitos mínimos:
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; I - prazo de validade correspondente ao período
                programado da viagem;
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; II - cobertura mínima de R$ 30.000,00 reais
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; III - ser firmado em língua portuguesa ou inglesa.
              </p>
            </li>
          </ul>

          <p className="font-size-md p-3 text-justify">
            Fique tranquilo, a Multi Seguro Viagem possui um plano compatível
            com a Portaria do Governo Federal Como a emissão destes planos é
            personalizada, para receber uma cotação entre em contato através do
            seguinte telefone:&nbsp;
            <span className="font-weight-bold">+55 19 3201-0447</span>
          </p>
        </Card>
      </Row>
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default InfoForForeignersIndexPortuguese;
