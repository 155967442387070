import { Card, Col, Row } from "antd";
import { GrLocation, GrPhone } from "react-icons/gr";

const MultiContactForm = () => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Row justify="center" className="mb-3">
      <Col style={{ width: 836 }}>
        <Card
          className="strong-border card-without-padding"
          title={
            <Row>
              <Col span={24}>
                <h1 className="text-center">Contato</h1>
                <h3 className="text-center text-muted mb-4">&nbsp;</h3>
              </Col>
            </Row>
          }
          style={{
            margin: "18px 18px",
            maxWidth: 800,
          }}
          classNames="text-center"
        >
          <Row>
            <Col xs={24} md={12} className="text-center p-5">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <GrLocation />
                </div>
                <strong className="text-uppercase">Nosso Escritório</strong>
              </div>
              <p>
                Rua Marechal Rondon, 700
                <br />
                Jardim Chapadão, Campinas - SP
                <br />
                13070-173
              </p>
            </Col>
            <Col xs={24} md={12} className="text-center p-5">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <GrPhone />
                </div>
                <strong className="text-uppercase">Fale com a Multi</strong>
              </div>
              <p>Estamos disponíveis de segunda à sexta, das 9h às 18h</p>
              <p>
                <a
                  href="mailto:contato@multiseguroviagem.com.br"
                  target="_blank"
                  rel="noreferrer"
                >
                  Envie-nos um email
                </a>
              </p>
              <p>
                <a
                  href="https://api.whatsapp.com/send?phone=551932010447&text=Gostaria%20de%20receber%20mais%20informa%C3%A7%C3%B5es%20sobre%20seguro%20viagem!"
                  target="_blank"
                  rel="noreferrer"
                >
                  Envie um WhatsApp
                </a>
              </p>
              <p>
                <a href="tel:+551932010447" target="_blank" rel="noreferrer">
                  Ligue para (19) 3201 0447
                </a>
              </p>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default MultiContactForm;
