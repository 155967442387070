import { Card, Row } from "antd";

const InfoForForeignersIndexEnglish = (props) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <div>
      <Row justify="center" className="mb-3">
        <Card
          className="strong-border card-without-padding"
          title={
            <div>
              <h1 className="text-center">Travel Insurance to Brazil</h1>
              <h3 className="text-center text-muted mb-4">
                Foreign, Traveling to Brazil!
              </h3>
            </div>
          }
          style={{
            margin: "18px 18px",
            maxWidth: 800,
          }}
          classNames="text-center"
        >
          <p className="font-size-md p-3 text-justify">
            If you intend to visit Brazil, taking out travel insurance is
            essential for your safety and can help you have a peaceful trip! In
            addition to all the benefits of purchasing a travel insurance plan,
            the Brazilian government published on August 26, 2020 a decree,
            called “ORDINANCE CC-PR MJSP MINFRA MS Nº 419, OF AUGUST 26, 2020”
            that requires all travelers, have a travel insurance plan with at
            least the following coverage:
          </p>

          <ul>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; I – The Brazil travel insurance plan must cover the
                entire duration of the trip
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; II - Medical coverage policy with a minimum value of R$
                30,000.00
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; III – The language of the insurance policy must be
                Brazilian Portuguese or English
              </p>
            </li>
          </ul>

          <p className="font-size-md p-3 text-justify">
            For your safety, Multi Seguro Viagem has great plan options from the
            best insurance companies, which cover the Brazilian ordinance and
            other diverse situations on your trip to Brazil. To receive a quote,
            you can contact us at the following number:&nbsp;
            <span className="font-weight-bold">+55 19 3201-0447</span>
          </p>
        </Card>
      </Row>
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default InfoForForeignersIndexEnglish;
