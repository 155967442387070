import { Card, Col, Collapse, Row } from "antd";

const SchengenTreaty = () => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const items = buildItems();

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Row justify="center" className="mb-3">
      <Col style={{ width: 836 }}>
        <Card
          className="strong-border card-without-padding"
          title={
            <Row>
              <Col span={24}>
                <h1 className="text-center">Tratado de Schengen</h1>
                <h3 className="text-center text-muted mb-4">&nbsp;</h3>
              </Col>
            </Row>
          }
          style={{
            margin: "18px 18px",
            maxWidth: 800,
          }}
          classNames="text-center"
        >
          <Collapse accordion items={items} className="mb-4" ghost />
        </Card>
      </Col>
    </Row>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildItems() {
    return [
      {
        key: "1",
        label: (
          <h4 className="font-weight-bold">
            Surgimento do Tratado de Schengen
          </h4>
        ),
        children: (
          <p className="font-size-md text-justify">
            Firmado em 14 de junho de 1985, este acordo feito entre países
            europeus, foi feito em Luxemburgo, na pequena cidade de Schengen, e
            seu intuito era justamente instituir a livre circulação de pessoas
            entre os estados que formavam a União Européia. Inicialmente, a
            permissão foi dada apenas aos cidadãos europeus, e os primeiros
            países a adotarem a nova política foram França, Alemanha, Bélgica,
            Luxemburgo e Países Baixos. As regras incluíam a concessão de vistos
            para estadias de curta duração, para os pedidos de asilo, no
            controle das fronteiras e na cooperação entre autoridades judiciais.
            Mas foi apenas em 1997 que o Tratado de Schengen foi integrado
            formalmente à União Europeia e hoje, cerca de 27 países adotam o
            acordo, assim como as suas regras e seu impacto na questão do seguro
            viagem. Dentre eles estão: Alemanha, Áustria, Bélgica, Dinamarca,
            Eslováquia, Eslovênia, Espanha, Estônia, Finlândia, França, Grécia,
            Holanda, Hungria, Islândia, Itália, Letônia, Liechtenstein,
            Lituânia, Luxemburgo, Malta, Noruega, Polônia, Portugal, República
            Tcheca, Suécia e Suíça. Mas atenção: apesar do Acordo de Schengen
            abranger vários países da Europa, a Inglaterra e Irlanda não fazem
            parte do tratado de Schengen. A Inglaterra faz parte do Reino Unido
            e, por isso, possui regras próprias para a entrada no país. O
            turista pode solicitar o visto de permanência temporária de entrada
            da Inglaterra, que geralmente é de até 6 meses. Já para a Irlanda, o
            visto de permanência é de até 90 dias.
          </p>
        ),
      },

      {
        key: "2",
        label: <h4 className="font-weight-bold">Desembarque</h4>,
        children: (
          <p className="font-size-md text-justify">
            A viagem para os destinos que fazem parte do acordo de Schengen não
            exige a emissão de visto para os brasileiros, devido a um acordo com
            nosso país. No entanto, há algumas regras para a permanência no
            local: é permitido apenas para um período máximo de até 90 dias
            consecutivos após o desembarque em um dos territórios Schengen. E
            com certeza você não quer ficar todo este tempo sem um seguro viagem
            não é mesmo? Para o período de permanência superior ao estabelecido,
            é necessário que o turista ou estudante espere, ou retorne no mínimo
            após 3 meses para as áreas que fazem parte do Tratado. Caso o
            turista retorne ao país de origem, antes da data estabelecida, é
            possível voltar à área Schengen e permanecer no local, até a
            expiração do prazo. Ao chegar a um destes países, ainda no
            aeroporto, é preciso justificar o motivo da visita para o oficial da
            imigração e apresentar os documentos que comprovem a sua estadia:
            reserva da acomodação, curso, passagem aérea com data de volta,
            comprovação que você tem dinheiro suficiente para o período da
            visita, através de moeda em espécie, cartão de crédito e/ou cartão
            de débito e o certificado de um seguro viagem, de acordo com as
            normas exigidas.
          </p>
        ),
      },

      {
        key: "3",
        label: <h4 className="font-weight-bold">Visto Schengen</h4>,
        children: (
          <>
            <p className="font-size-md text-justify">
              Nos casos em que a permanência nos países do tratado seja superior
              ao período permitido é necessário a emissão de um visto.
              Geralmente, esta regra é aplicada para viagens a trabalho ou
              intercâmbio, que podem ser mais longas. A duração do visto, vai
              depender do tempo de permanência no país ou de acordo com a
              necessidade solicitada. Para isto, é preciso ir ao Consulado ou
              Embaixada do país em que você ficará residente, e levar os
              seguintes documentos:
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro viagem com cobertura de no mínimo 30 mil
                  euros;
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Passaporte original;
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Comprovante de passagem aérea;
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Reservas de hospedagem ou carta-convite: nos casos de
                  estadia em casas de amigos.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Comprovante de situação financeira: normalmente o
                  extrato bancário dos últimos seis meses é o suficiente para
                  justificar a renda suficiente para permanência no destino.
                  Para os estudantes que não possuem renda, a comprovação pode
                  ser por meio de uma declaração oficial garantindo que outra
                  pessoa se responsabiliza pelo seu sustento durante o período
                  de estadia no país.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Formulário de aplicação de visto Schengen preenchido
                  e com foto 3x4 recente;
                </p>
              </li>
            </ul>
          </>
        ),
      },

      {
        key: "4",
        label: <h4 className="font-weight-bold">Seguro Viagem na Europa</h4>,
        children: (
          <>
            <p className="font-size-md text-justify">
              Para a entrada nos países da Europa que fazem parte do Tratado de
              Schengen, um dos itens obrigatórios é a contratação de um Seguro
              Viagem. No entanto, algumas regras devem ser observadas e
              seguidas, a partir das normas do acordo. A lei que exige o Seguro
              Viagem está apoiada pelo Regulamento (CE) n.º 810/2009, do
              Parlamento Europeu e do Conselho, em vigor desde 05 de abril de
              2010. A apólice do seguro viagem deve ter a cobertura mínima de
              até 30 mil euros ou 50 mil dólares e garantir assistência médica
              internacional emergencial para doenças ou imprevistos durante a
              viagem, como a perda de bagagens, documentos, acidentes pessoais
              ou até mesmo nos casos de repatriação funerária. Portanto, se um
              turista estrangeiro embarcar para a Europa sem a apólice do seguro
              viagem, saiba que pode ser barrado no aeroporto de destino, e
              deportado para o país de origem. O valor estipulado é válido para
              todos os países pertencentes ao Acordo, com exceção da Irlanda que
              não exige o valor mínimo na contratação do seguro viagem. A maior
              parte dos países da Europa exige o Seguro viagem, porque não
              oferecem atendimento gratuito na área da saúde para os
              estrangeiros. As consultas particulares costumam ter um alto
              valor, o que é reembolsado posteriormente, com aquisição da
              apólice. Se a viagem englobar a locação de veículo, é preciso
              estar atento com as regras de cobertura para acidentes que possam
              ferir a terceiros. A mesma regra vale nos casos de turistas com
              doenças preexistentes, com a contratação de um seguro viagem que
              cubra as despesas médicas, em casos de crises. Para acionar o
              seguro basta estar com apólice em mãos, e seguir o passo a passo
              lá descrito. Além disso, ligar para o número de telefone indicado,
              para que você possa contatar o representante no país onde você se
              encontra.
            </p>
          </>
        ),
      },
    ];
  }
};

export default SchengenTreaty;
