import React from "react";
import { Trans } from "react-i18next";
import i18n from "i18next";
import { notification } from "antd";
import axios from "axios";
import IntlMessage from "components/util-components/IntlMessage";
import { v4 as uuidv4 } from "uuid";
import {
  PiNumberCircleEight,
  PiNumberCircleFive,
  PiNumberCircleFour,
  PiNumberCircleNine,
  PiNumberCircleOne,
  PiNumberCircleSeven,
  PiNumberCircleSix,
  PiNumberCircleThree,
  PiNumberCircleTwo,
  PiNumberCircleZero,
} from "react-icons/pi";
import dayjs from "dayjs";
import phone from "phone";
import { saveAs } from "file-saver";

const tmr = {};
let tmrDebounce;
let workingQueue = {};

export const getUrlFromBrowser = () => window.location.href;
/*
export const getUrlByLocation = location => {
  return location.pathname + location.search;
};

export const getUrlQueryStringByLocation = location => {
  const queryString = new URLSearchParams(location.search);

  // Converte todos os parâmetros da Query String em um objeto
  const queryParams = {};
  for (const [key, value] of queryString.entries()) {
    queryParams[key] = value;
  }

  return queryParams;
};

export const updateUrlByQueryStringObject = (queryStringObj, navigate) => {
  const queryString = new URLSearchParams(queryStringObj).toString();

  // Atualiza a URL sem recarregar os componentes
  const updateURL = () => {
    navigate({ search: `?${queryString}` });
  };
};*/
export const onProduction = () => {
  return !getUrlFromBrowser().includes("localhost");
};

export const sort = (a, b, key) => {
  if (!key) {
    if (!isNaN(a)) a = Number(a);
    if (!isNaN(b)) b = Number(b);

    if (typeof a === "number" && typeof b === "number") {
      return a - b;
    }

    if (typeof a === "string" && typeof b === "string") {
      a = a.toLowerCase();
      b = b.toLowerCase();

      return a > b ? 1 : b > a ? -1 : 0;
    }
  } else {
    if (!isNaN(a[key])) a[key] = Number(a[key]);
    if (!isNaN(b[key])) b[key] = Number(b[key]);

    if (typeof a[key] === "number" && typeof b[key] === "number") {
      return a[key] - b[key];
    }

    if (typeof a[key] === "string" && typeof b[key] === "string") {
      a = a[key].toLowerCase();
      b = b[key].toLowerCase();

      return a > b ? 1 : b > a ? -1 : 0;
    }
  }
};

export const translateWord = (inComponent, key) => {
  const returnObject = typeof inComponent === "boolean" && inComponent;
  const id = key || inComponent;
  const t = <Trans i18nKey={id} />;
  return returnObject ? t : i18n.t(id);
};

export const translateX = (id, replaceFrom = [], replaceTo = []) => {
  let t = i18n.t(id);

  if (
    Array.isArray(replaceFrom) &&
    replaceFrom.length > 0 &&
    Array.isArray(replaceTo) &&
    replaceTo.length > 0
  ) {
    replaceFrom.map((from, idx) => {
      const to = replaceTo?.[idx] && i18n.t(replaceTo[idx]);
      t = to ? t.replace(from, i18n.t(to)) : t;
      return from;
    });
  }

  return t;
};

export const isValidEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (Array.isArray(email)) {
    let success = true;
    email.map((e) => !regex.test(e) && (success = false));
    return success;
  } else {
    return regex.test(email);
  }
};

export const isValidUrl = (website) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  ); // fragment locator
  return !!pattern.test(website);
};

export const isValidCpf = (cpf) => {
  if (!cpf) return;

  cpf = cpf.toString().replace(/[^\d]+/g, "");
  if (cpf === "") return false;

  // remove invalids cpfs
  if (
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  ) {
    return false;
  }

  // check the first digit
  let add = 0;
  for (let i = 0; i < 9; i++) {
    add += parseInt(cpf.charAt(i)) * (10 - i);
  }

  let rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;

  // check the second digit
  add = 0;
  for (let i = 0; i < 10; i++) {
    add += parseInt(cpf.charAt(i)) * (11 - i);
  }

  rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) rev = 0;

  return rev === parseInt(cpf.charAt(10));
};

export const isValidCnpj = (cnpj) => {
  if (typeof cnpj === "undefined") return false;

  cnpj = returnOnlyNumbers(cnpj);

  if (
    cnpj === "" ||
    cnpj.length !== 14 ||
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  ) {
    return false;
  }

  // check digits
  let length = cnpj.length - 2;
  let number = cnpj.slice(0, length);
  let digits = cnpj.slice(length);
  let sum = 0;
  let suffix = length - 7;

  for (let i = length; i >= 1; i--) {
    sum += number.charAt(length - i) * suffix--;
    if (suffix < 2) {
      suffix = 9;
    }
  }

  let resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (resultado.toString() !== digits.charAt(0)) {
    return false;
  }

  length = length + 1;
  number = cnpj.slice(0, length);
  sum = 0;
  suffix = length - 7;

  for (let i = length; i >= 1; i--) {
    sum += number.charAt(length - i) * suffix--;
    if (suffix < 2) {
      suffix = 9;
    }
  }
  resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  return resultado.toString() === digits.charAt(1);
};

export const isCpfOrCnpj = (value) => {
  if (!value) return false;

  if (isValidCnpj(value)) return "cnpj";
  if (isValidCpf(value)) return "cpf";

  return false;
};

export const isValidCpfCnpj = (value) => {
  if (!value) return false;

  const v = returnOnlyNumbers(value);
  if (v?.toString().length <= 11) {
    return isValidCpf(v);
  } else {
    return isValidCnpj(v);
  }
};

export const isValidZip = (cep) => {
  if (!cep) return false;

  const maskedCep = applyNumberMask(returnOnlyNumbers(cep), "99999-999");
  const regex = /[0-9]{5}-[\d]{3}/;
  return regex.test(maskedCep);
};

export const returnOnlyNumbers = (str) => {
  if (!str) return;
  return str?.toString()?.replace(/\D/g, "");
};

export const applyNumberMask = (value, mask, acceptSpace) => {
  if (!value) return;

  let result = "";

  acceptSpace = false;

  const rule = acceptSpace ? "[0-9] " : "[0-9]";
  const regex = new RegExp(rule, "ig");

  let inc = 0;
  Array.from(value).forEach((letter, index) => {
    if (!mask[index + inc]) return result;

    if (!mask[index + inc].match(regex)) {
      setMask(index);
    }

    result += letter;
  });

  return result;

  function setMask(index) {
    result += mask[index + inc];
    inc++;

    if (mask[index + inc] && !mask[index + inc].match(regex)) setMask(index);
  }
};

export const toast = (type, title, message, duration = 4.5, key) => {
  if (!type) return console.error("type missing in toast() helper function!");

  // eslint-disable-next-line default-case
  switch (type.toLowerCase()) {
    case "s":
      type = "success";
      break;

    case "e":
    case "f":
      type = "error";
      break;

    case "w":
      type = "warning";
      break;

    case "i":
      type = "info";
      break;
  }

  if (!["success", "error", "warning", "info"].includes(type)) {
    console.error(`unknowing type '${type}' in toast() helper function!`);
    return;
  }

  notification[type]({
    message: title,
    description: message,
    duration: duration,
    key: key,
  });
};

// export const modal = options => {
//   if (!options) {
//     return console.error(`'options' missing in modal() helper function!`);
//   } else if (!options.type) {
//     return console.error(`'options.type' missing in modal() helper function!`);
//   } else if (!options.title) {
//     return console.error(`'options.title' missing in modal() helper function!`);
//   } else if (!options.message) {
//     return console.error(
//       `'options.message' missing in modal() helper function!`,
//     );
//   }
//
//   let type = options.type;
//
//   // eslint-disable-next-line default-case
//   switch (type.toLowerCase()) {
//     case 's':
//       type = 'success';
//       break;
//
//     case 'e':
//     case 'f':
//       type = 'error';
//       break;
//
//     case 'w':
//       type = 'warning';
//       break;
//
//     case 'i':
//       type = 'info';
//       break;
//
//     case 'c':
//       type = 'confirm';
//       break;
//   }
//
//   if (!['success', 'error', 'warning', 'info', 'confirm'].includes(type)) {
//     console.error(`unknowing type '${type}' in modal() helper function!`);
//     return;
//   }
//
//   Modal[type]({
//     title: options.title,
//     content: options.message,
//     cancelText: options.cancelText || 'Cancel',
//     okText: options.okText || 'OK',
//     onOk: options.onOk || null,
//     onCancel: options.onCancel || null,
//     wrapClassName: 'modal-without-title',
//   });
// };

export const generateCode = (levels, passwordLength = 4) => {
  const fruits = [
    "abacaxi",
    "banana",
    "caju",
    "damasco",
    "figo",
    "goiaba",
    "kiwi",
    "limao",
    "manga",
    "nectarina",
    "laranja",
    "papaia",
    "framboesa",
    "morango",
    "tangerina",
    "melancia",
    "ameixa",
    "uva",
    "graviola",
    "jaca",
    "mamao",
  ];

  const colors = [
    "amarelo",
    "azul",
    "verde",
    "vermelho",
    "roxo",
    "laranja",
    "rosa",
    "cinza",
    "preto",
    "branco",
    "marrom",
    "bege",
    "ciano",
    "magenta",
    "turquesa",
    "prata",
    "ouro",
    "bronze",
    "lilas",
    "violeta",
  ];

  const countries = [
    "alemanha",
    "argentina",
    "australia",
    "brasil",
    "canada",
    "china",
    "dinamarca",
    "egito",
    "espanha",
    "franca",
    "grecia",
    "india",
    "italia",
    "japao",
    "mexico",
    "noruega",
    "polonia",
    "portugal",
    "russia",
    "suica",
    "suecia",
    "tailandia",
    "turquia",
    "ucrania",
    "venezuela",
    "chile",
    "colombia",
    "equador",
    "peru",
    "uruguai",
  ];

  const flowers = [
    "rosa",
    "margarida",
    "orquidea",
    "girassol",
    "violeta",
    "cravo",
    "tulipa",
    "begonia",
    "lirio",
    "azaleia",
    "hibisco",
    "jasmine",
    "petunia",
    "bromelia",
    "dalia",
    "horteia",
    "camelia",
    "calandiva",
    "gardenia",
    "primavera",
    "suculenta",
    "alegria",
    "anturio",
  ];

  let lightPassword = [...fruits, ...colors, ...countries, ...flowers];

  if (!levels) {
    const index = Math.floor(Math.random() * lightPassword.length);
    return lightPassword[index];
  }

  const lowercase = "abcdefghijklmnopqrstuvwxyz";
  const numbers = "0123456789";
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const symbols = "!@#$%^&*()<>,.?/[]{}-=_+|/";

  let hardPassword = "";
  if (levels.includes(1)) hardPassword += lowercase;
  if (levels.includes(2)) hardPassword += uppercase;
  if (levels.includes(3)) hardPassword += numbers;
  if (levels.includes(4)) hardPassword += symbols;
  if (!hardPassword) hardPassword = lightPassword;

  let password = "";
  for (let i = 0; i < passwordLength; i++) {
    password += $getRandomCharacter();
  }
  return password;

  function $getRandomCharacter() {
    const randomIndex = Math.floor(Math.random() * hardPassword.length);
    return hardPassword[randomIndex];
  }
};

export const getIpWan = async () => {
  const domains = [
    "https://api.seeip.org/jsonip?",
    "https://api.ipify.org?format=json",
  ];

  let response,
    ip = "0.0.0.0";

  for (const url of domains) {
    ip = await $getIp(url);
    if (ip !== "0.0.0.0") return ip;
  }

  return ip;

  async function $getIp(url) {
    try {
      response = await axios.get(url);

      return response?.data?.ip;
    } catch {
      return "0.0.0.0";
    }
  }
};

export const getLocale = (full) => {
  const lang = i18n.language;

  if (!full) return lang;

  switch (lang) {
    case "pt":
      return "pt-BR";

    case "en":
      return "en-US";

    default:
      console.error("no language locale set in getLocale():helpers.js");
      return lang;
  }
};

export const isValidPhoneNumber = (pn, profile) => {
  if (!pn) return;

  pn = returnOnlyNumbers(pn);

  if (!pn) return false;

  if (profile) {
    if (profile.toUpperCase() === "FREE") {
      return (
        pn.length === 11 &&
        (pn.substring(0, 4) === "0800" || pn.substring(0, 4) === "0300")
      );
    } else if (profile.toUpperCase() === "MOBILE") {
      return pn.length === 11 && pn.substring(2, 3) === "9";
    } else if (profile.toUpperCase() === "WIRED") {
      return pn.length === 10;
    } else {
      return false;
    }
  } else {
    if (returnOnlyNumbers(pn).length === 11) {
      return (
        pn.substring(0, 4) === "0800" ||
        pn.substring(0, 4) === "0300" ||
        pn.substring(2, 3) === "9"
      );
    } else if (returnOnlyNumbers(pn).length === 10) {
      return (
        pn.substring(0, 1) !== "0" &&
        pn.substring(0, 4) !== "0800" &&
        pn.substring(0, 4) !== "0300" &&
        pn.substring(2, 3) !== "9"
      );
    } else {
      return false;
    }
  }
};

export const initCounter = (
  setCounter,
  maxCounter,
  key = "default",
  reverse,
  delay = 1000,
) => {
  if (reverse) {
    setCounter(maxCounter);

    tmr[key] = setInterval(() => {
      setCounter((prev) => {
        if (prev === 1) {
          clearInterval(tmr[key]);
          return 0;
        } else {
          return prev - 1;
        }
      });
    }, delay);
  } else {
    setCounter(1);

    tmr[key] = setInterval(() => {
      setCounter((prev) => {
        if (prev === maxCounter) {
          clearInterval(tmr[key]);
          return maxCounter;
        } else {
          return prev + 1;
        }
      });
    }, delay);
  }
};

export const toPascalCase = (str) => {
  return str
    .replace(/\w+/g, (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .replace(/\s+/g, "");
};

export const toCapitalCase = (str, level = 0) => {
  if (!str) return;

  const words = str.split(/\s+/);

  const capitalWords = words.map((word) => {
    if (!level || (level === 1 && word.length > 2)) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    } else {
      return word.toLowerCase();
    }
  });

  return capitalWords.join(" ");
};

export const clone = (obj) => {
  if (!obj || typeof obj !== "object") return;
  return JSON.parse(JSON.stringify(obj));
};

export const isAntdIcon = (icon) => typeof icon === "object";

export const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

export const normalize = (str, type) => {
  if (!str) return;

  str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  if (type === "lower") {
    return str.toLowerCase();
  } else if (type === "upper") {
    return str.toUpperCase();
  } else {
    return str;
  }
};

export const CounterIcon = (props) => {
  const { number = 1, fontSize = 22 } = props;

  const iconProps = {
    // className: 'mr-2',
    style: { fontSize: fontSize },
  };

  const iconIdx = number % 10;

  const iconArr = [
    <PiNumberCircleZero {...props} {...iconProps} />,
    <PiNumberCircleOne {...props} {...iconProps} />,
    <PiNumberCircleTwo {...props} {...iconProps} />,
    <PiNumberCircleThree {...props} {...iconProps} />,
    <PiNumberCircleFour {...props} {...iconProps} />,
    <PiNumberCircleFive {...props} {...iconProps} />,
    <PiNumberCircleSix {...props} {...iconProps} />,
    <PiNumberCircleSeven {...props} {...iconProps} />,
    <PiNumberCircleEight {...props} {...iconProps} />,
    <PiNumberCircleNine {...props} {...iconProps} />,
  ];

  return iconArr[iconIdx];
};

export const getDocumentStatusColor = (status) => {
  switch (status) {
    case "not_updated":
      return "red";

    case "in_analysis":
    case "pending_update":
      return "orange";

    case "with_reservation":
      return "cyan";

    case "revised":
      return "blue";

    case "updated":
      return "green";

    case "archive":
    case "removed":
      return "gray";

    default:
      return "red";
  }
};

export const getDocumentStatusDescription = (status) => {
  switch (status) {
    case "pending_update":
      return "waiting_for_update";

    case "not_updated":
      return "update_expired";

    case "updated":
      return "document_updated";

    case "in_analysis":
      return "unmoderated_document";

    case "with_reservation":
      return "document_with_reservation";

    case "revised":
      return "moderated_document";

    default:
      return "n_a";
  }
};

export const isObjEquals = (objA, objB) => {
  if (!objA || !objB) return;

  let isEquals = true;

  const obj1 = clone(objA);
  const obj2 = clone(objB);

  // reference: https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript

  Object.keys(obj1).forEach((k) => !obj1[k] && delete obj1[k]);
  Object.keys(obj2).forEach((k) => !obj2[k] && delete obj2[k]);

  Object.keys(obj1).map((k) => {
    if ((obj1[k] || obj2[k]) && $pure(obj1[k]) !== $pure(obj2[k])) {
      isEquals = false;
    }
    return k;
  });

  Object.keys(obj2).map((k) => {
    if ((obj1[k] || obj2[k]) && $pure(obj1[k]) !== $pure(obj2[k])) {
      isEquals = false;
    }
    return k;
  });

  return isEquals;

  function $pure(value) {
    if (!value) return;

    if (typeof value === "object") {
      return JSON.stringify(value);
    } else if (typeof value !== "string") {
      return value.toString();
    } else {
      return value;
    }
  }
};

export const isMongoObjectId = (str) => {
  let regex = /^[0-9a-fA-F]{24}$/;
  return regex.test(str);
};

export const parseDateToDB = (date) => {
  if (!date || !dayjs.isDayjs(date)) return;
  return dayjs(date);
  // return dayjs(date).toISOString();
};

export const parseDateToDisplay = (date) => {
  if (!date) return;
  if (dayjs.isDayjs(date)) return date;
  return dayjs(date);
};

export const parsePhoneToDB = (str) => {
  if (!str) return;
  return buildPhone(str).numberFull;
};

export const parseCpfToDB = (str) => {
  if (!str) return;
  return str && returnOnlyNumbers(str);
};

export const parseCpfToDisplay = (str) => {
  if (!str) return;
  return numberMask(returnOnlyNumbers(str), "999.999.999-99");
};

export const parseCnpjToDB = (str) => {
  if (!str) return;
  return returnOnlyNumbers(str);
};

export const parseCnpjToDisplay = (str) => {
  if (!str) return;
  return numberMask(returnOnlyNumbers(str), "99.999.999/9999-99");
};

export const parseZipToDB = (str) => {
  if (!str) return;
  return returnOnlyNumbers(str);
};

export const parseZipToDisplay = (str) => {
  if (!str) return;
  return numberMask(returnOnlyNumbers(str), "99999-999");
};

export const parseCityToDisplay = (city, province) => {
  if (!city && !province) return;
  if (!province) return city;
  if (!city) return province;
  return `${city} - ${province}`;
};

export const parsePhoneToDisplay = (str) => {
  if (!str) return;
  return buildPhone(str).phone;
};

export const buildPhone = (fullNumber) => {
  if (!fullNumber) return null;
  const isFreeCall =
    fullNumber?.substring(0, 4) === "0800" ||
    fullNumber?.substring(0, 4) === "0300";

  if (isFreeCall) {
    fullNumber = returnOnlyNumbers(fullNumber);
  } else {
    if (fullNumber.slice(0, 1) !== "+")
      fullNumber = `+55${returnOnlyNumbers(fullNumber)}`;
  }

  const phone = decomposePhoneNumber(fullNumber, isFreeCall);

  return {
    isValid: phone?.isValid || null,
    numberFull: phone?.fullPhoneNumber || null,
    dialCode: phone?.countryCode || null,
    areaCode: phone?.areaCode || null,
    coreNumber: phone?.coreNumber || null,
    core: phone?.core || null,
    number: phone?.phoneNumber || null,
    phone: phone?.phone || null,
    countryCode: phone?.countryIso2?.toLowerCase() || null,
    phoneMask: phone?.phoneMask || null,
  };
};

export const decomposePhoneNumber = (value, isFreeCall) => {
  if (isFreeCall) {
    return {
      phoneNumber: value,
      phoneMask: "9999-999-9999",
      phone: numberMask(value, "9999-999-9999"),
      core: numberMask(value, "9999-999-9999"),
      coreNumber: value,
      areaCode: "",
      fullPhoneNumber: value,
      countryCode: "+55",
      countryIso2: "BR",
      countryIso3: "BRA",
    };
  }

  // workaround for wired phone BR
  const wiredPhone =
    value?.length === 13
      ? value.slice(0, 5) + "0" + value.slice(5, 13)
      : undefined;

  const data = phone(wiredPhone || value, {
    country: "BR",
    validateMobilePrefix: false,
  });

  if (data.isValid) {
    if (wiredPhone) data.phoneNumber = value;

    data.phoneNumber = data.phoneNumber.substring(
      data.countryCode.length,
      data.phoneNumber.length,
    );

    data.phoneMask = returnPhoneMask(data.phoneNumber, data.countryIso2);

    data.phone = numberMask(data.phoneNumber, data.phoneMask);

    let i = data.phone.indexOf("(");
    const f = data.phone.indexOf(")");

    data.areaCode = data.phone.substring(++i, f);
    data.core = data.phone.substring(f + 2, data.phone.length);
    data.coreNumber = returnOnlyNumbers(data.core);
    data.fullPhoneNumber = `${data.countryCode}${data.phoneNumber}`;
  } else {
    data.phoneNumber = null;
    data.phoneMask = null;
    data.phone = null;
    data.core = null;
    data.coreNumber = null;
    data.areaCode = null;
    data.fullPhoneNumber = null;
  }

  return data;
};

export const returnPhoneMask = (str, countryCode = "br") => {
  if (!str) return;

  countryCode = countryCode.toLowerCase();

  if (countryCode === "br") {
    if (str.length === 11) {
      if (str.substring(0, 1) === "0") {
        // 0800-PJMONEY

        return "9999 99 9999";
      } else {
        return "(99) 9.9999-9999";
      }
    } else if (str.length === 10) {
      return "(99) 9999-9999";
    } else {
      return str;
    }
  } else {
    return str;
  }
};

export const numberMask = (value, mask) => {
  let result = "";

  const acceptSpace = false;
  const rule = acceptSpace ? "[0-9] " : "[0-9]";
  const regex = new RegExp(rule, "ig");

  if (value) value = returnOnlyNumbers(value);

  let inc = 0;
  Array.from(value).forEach((letter, index) => {
    if (!mask[index + inc]) return result;

    if (!mask[index + inc].match(regex)) {
      setMask(index);
    }

    result += letter;
  });

  return result;

  function setMask(index) {
    result += mask[index + inc];
    inc++;

    if (mask[index + inc] && !mask[index + inc].match(regex)) setMask(index);
  }
};

export const divider = (char = "*", qty = 78) => {
  return char.repeat(qty);
};

export const imageExists = (src) => {
  const img = new Image();
  img.src = src;
  return img.complete;
};

export const getDateFormatByLocale = (locale) => {
  if (!locale) locale = dayjs().locale();

  if (locale === "pt-br") {
    // return date.format("DD/MM/YYYY");
    return "DD/MM/YYYY";
  } else if (locale === "en-us") {
    // return date.format("MM/DD/YYYY");
    return "MM/DD/YYYY";
  } else {
    // return date.format("YYYY-MM-DD");
    return "YYYY-MM-DD";
  }
};

export const buildArrayNumberZeroToLimit = (limit = 0) => {
  return Array.from({ length: limit + 1 }, (_, index) => index);
};

export const getFromLocalStorage = (key) => {
  switch (key) {
    case "pageSize":
      return ["10", "20", "50", "100"].includes(
        localStorage.getItem("pageSize"),
      )
        ? Number(localStorage.getItem("pageSize"))
        : 10;

    default:
      return null;
  }
};

export const debounce = (fn, delay = 200) => {
  if (tmrDebounce) clearTimeout(tmrDebounce);
  tmrDebounce = setTimeout(() => fn(), delay);
};

export const isOdd = (number = 0) => number % 2 === 0;

export const generateToken = () => uuidv4();

export const generateHexColor = (
  toHexOppositeColor = null,
  blackWhite = false,
) => {
  // font...
  // https://stackoverflow.com/questions/35969656/how-can-i-generate-the-opposite-color-according-to-current-color
  // https://github.com/onury/invert-color

  if (toHexOppositeColor) return $invertColor(toHexOppositeColor, blackWhite);

  return "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0");

  // invert color for toHexOppositeColor
  function $invertColor(hex, blackWhite = false) {
    if (hex.indexOf("#") === 0) hex = hex.slice(1);

    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    if (hex.length !== 6) {
      console.error(`Invalid hex color: ${hex}!`);
      return hex;
    }

    let r = parseInt(hex.slice(0, 2), 16);
    let g = parseInt(hex.slice(2, 4), 16);
    let b = parseInt(hex.slice(4, 6), 16);

    if (blackWhite) {
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#ffffff";
    }

    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + $$padZero(r) + $$padZero(g) + $$padZero(b);

    function $$padZero(str, len = 2) {
      const zeros = new Array(len).join("0");
      return (zeros + str).slice(-len);
    }
  }
};

export const getObjectByField = (record, field) => {
  // ex:
  // field: '_metadata.audit.updatedAt'
  // => record['_metadata']['audit']['updatedAt']
  if (!record || !field) return;

  if (Array.isArray(field)) field = field.join(".");

  const keys = field.split(".");
  let result = record;

  for (const key of keys) {
    if (result && typeof result === "object" && key in result) {
      result = result[key];
    } else {
      return undefined;
    }
  }

  return result;
};

export const simulateApiDelay = (timeout, success, response) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (success) {
        resolve(response);
      } else {
        reject(response);
      }
    }, timeout);
  });
};

export const queue = (component, value = 0) => {
  if (typeof workingQueue[component] === "undefined") {
    workingQueue[component] = 0;
  }
  workingQueue[component] = workingQueue[component] + value;

  return workingQueue[component];
};

export const strToNumber = (valueStr) => {
  if (!valueStr) return 0;

  if (!isNaN(valueStr)) {
    return Number(valueStr);
  } else {
    if (valueStr?.includes(",")) {
      return parseFloat(valueStr.replace(/[^\d,-]/g, "").replace(",", "."));
    } else {
      return parseFloat(valueStr?.replace(/[^\d,.-]/g, ""));
    }
  }
};

export const openUrl = (url, openInNewPage = true) => {
  if (!url) return;
  window.open(url, openInNewPage ? "_blank" : "_self");
};

export const downloadFile = (url, fileName) => {
  // const url = 'https://www.info.com/file.pdf';
  // saveAs(url, 'file.pdf');
  saveAs(url, fileName);
};
