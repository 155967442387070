import { Image } from "antd";
import { Link } from "react-router-dom";
import wppIcon from "./whatsapp-icon.png";
import { returnOnlyNumbers } from "../../../../utils/helpers";

const WhatsAppFloatButton = (props) => {
  const { countryCode = "+55", phone, text } = props;
  const cc = returnOnlyNumbers(countryCode);
  const pn = returnOnlyNumbers(phone);

  let url = `https://wa.me/${cc}${pn}`;

  if (text) url += `?${encodeURIComponent(text)}`;

  return (
    <Link
      to={url}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        width: "60px",
        height: "60px",
      }}
    >
      <Image preview={false} src={wppIcon} />
    </Link>
  );
};

export default WhatsAppFloatButton;
