import { Card, Col, Row } from "antd";
import {
  MdOutlineCancel,
  MdOutlineLuggage,
  MdOutlineMedicalServices,
  MdSportsTennis,
} from "react-icons/md";
import {
  IoAirplaneOutline,
  IoArrowBackSharp
} from "react-icons/io5";
import { GiMedicines, GiThorHammer } from "react-icons/gi";
import { AiOutlineMedicineBox } from "react-icons/ai";
import { TfiBackLeft, TfiFaceSad } from "react-icons/tfi";
import { PiAirplaneLight, PiBaby } from "react-icons/pi";
import { GoPeople } from "react-icons/go";
import { RiMessage2Line } from "react-icons/ri";
import { TbDental, TbPigMoney } from "react-icons/tb";
import { GrMoney } from "react-icons/gr";
import { FaHandHoldingMedical } from "react-icons/fa";
import { LuHotel } from "react-icons/lu";

const BenefitsAndCoverages = () => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Row justify="center" className="mb-3">
      <Col style={{ width: 836 }}>
        <Card
          className="strong-border card-without-padding"
          title={
            <Row>
              <Col span={24}>
                <h1 className="text-center">Benefícios e Coberturas</h1>
                <h3 className="text-center text-muted mb-4">
                  Entenda as coberturas oferecidas pelas operadoras
                </h3>
              </Col>
            </Row>
          }
          style={{
            margin: "18px 18px",
            maxWidth: 800,
          }}
          classNames="text-center"
        >
          <p className="font-size-md p-3 text-justify">
            Os planos de Seguro Viagem oferecem diversas opções de coberturas e
            benefícios, que variam conforme as seguradoras, planos e condições
            gerais do plano escolhido. Entenda cada uma das coberturas
            oferecidas pelas operadoras:
          </p>

          <Row>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <MdOutlineMedicalServices />
                </div>
                <strong className="text-uppercase">Assistência Médica</strong>
              </div>
              <p>
                O segurado receberá atendimento médico local, pela rede de
                atendimento autorizada. Mas caso não seja possível, o segurado
                receberá um reembolso pelas despesas médicas e hospitalares,
                conforme Condições Gerais do seguro contratado.
              </p>
            </Col>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <GiMedicines />
                </div>
                <strong className="text-uppercase">
                  Assistência Farmacêutica
                </strong>
              </div>
              <p>
                O segurado receberá reembolso de despesas com medicamentos,
                desde que prescritos por orientação médica e durante o período
                de viagem, conforme Condições Gerais do seguro contratado.
              </p>
            </Col>
          </Row>

          <Row>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <AiOutlineMedicineBox />
                </div>
                <strong className="text-uppercase">
                  Assistência Médica Para Pré-Existência
                </strong>
              </div>
              <p>
                Atendimento para crises decorrentes de doença preexistente ou
                crônica, em caso de emergência ou urgência, é um item essencial
                para alguns viajantes. As normas estão no item Condições Gerais
                do seguro contratado.
              </p>
            </Col>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <MdOutlineCancel />
                </div>
                <strong className="text-uppercase">
                  Cancelamento De Viagem
                </strong>
              </div>
              <p>
                Reembolso de despesas ou depósitos pagos previamente pelo
                segurado, em caso de cancelamento ou interrupção da viagem,
                conforme Condições Gerais do seguro contratado.
              </p>
            </Col>
          </Row>

          <Row>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <IoAirplaneOutline />
                </div>
                <strong className="text-uppercase">Atraso de Voo</strong>
              </div>
              <p>
                Caso o voo do atrase por mais de 6 horas, o segurado receberá
                reembolso até o valor limite da cobertura, incluindo gastos de
                hotel, de refeições e de comunicação, conforme Condições Gerais
                do seguro contratado.
              </p>
            </Col>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <TfiBackLeft />
                </div>
                <strong className="text-uppercase">
                  Repatriação Sanitária ou por Morte
                </strong>
              </div>
              <p>
                Se alguma fatalidade ocorrer, o corpo é repatriado ao Brasil
                para os processos fúnebres.
              </p>
            </Col>
          </Row>

          <Row>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <GoPeople />
                </div>
                <strong className="text-uppercase">
                  Passagem Gratuita e Acompanhamento Familiar
                </strong>
              </div>
              <p>
                Em caso de hospitalização do segurado, viajando sozinho, por um
                período superior a 05 dias, ele pode receber acompanhamento de
                um familiar direto, com direito à cobertura de gastos com
                passagem e hotel, conforme estabelecido em Condições Gerais do
                seguro contratado.
              </p>
            </Col>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <MdSportsTennis />
                </div>
                <strong className="text-uppercase">
                  Cobertura para Prática de Esportes
                </strong>
              </div>
              <p>
                O segurado terá direito ao atendimento emergencial, em caso de
                acidente pessoal ou enfermidade súbita e aguda, durante todo o
                período da viagem, conforme estabelecido em Condições Gerais do
                seguro contratado.
              </p>
            </Col>
          </Row>

          <Row>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <PiBaby />
                </div>
                <strong className="text-uppercase">
                  Acompanhamento de Menores
                </strong>
              </div>
              <p>
                Em caso de acidente ou enfermidade, menores de 15 anos e idosos
                acima de 75 anos terão acompanhante durante viagem de regresso
                ao país de origem, sob a responsabilidade da seguradora,
                conforme o item Condições Gerais do seguro contratado.
              </p>
            </Col>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <RiMessage2Line />
                </div>
                <strong className="text-uppercase">Mensagens Urgentes</strong>
              </div>
              <p>
                Transmissão de mensagens urgentes à família do segurado estão
                garantidas, através da Central Operativa, conforme Condições
                Gerais do seguro contratado.
              </p>
            </Col>
          </Row>

          <Row>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <TbDental />
                </div>
                <strong className="text-uppercase">
                  Assistência odontológica
                </strong>
              </div>
              <p>
                O segurado poderá receber atendimento médico odontológico,
                dentro do valor limite de sua cobertura, conforme Condições
                Gerais do seguro contratado.
              </p>
            </Col>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <GiThorHammer />
                </div>
                <strong className="text-uppercase">Assistência Jurídica</strong>
              </div>
              <p>
                Assistência e reembolso para gastos gerados com problemas
                judiciais, desde que ocorridos durante a viagem, conforme
                Condições Gerais do seguro contratado.
              </p>
            </Col>
          </Row>

          <Row>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <GrMoney />
                </div>
                <strong className="text-uppercase">Reembolso por Gastos</strong>
              </div>
              <p>
                Em caso de atraso de bagagem, o segurado receberá indenização
                para comprar itens de necessidade básica (higiene pessoal e
                roupas) para viagens aéreas de ida, conforme Condições Gerais do
                seguro contratado.
              </p>
            </Col>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <MdOutlineLuggage />
                </div>
                <strong className="text-uppercase">Seguro de Bagagens</strong>
              </div>
              <p>
                Em caso de extravio, roubo, furto ou destruição da bagagem, o
                segurado receberá uma indenização de acordo com o valor da
                cobertura contratada, conforme Condições Gerais do seguro
                contratado.
              </p>
            </Col>
          </Row>

          <Row>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <PiAirplaneLight />
                </div>
                <strong className="text-uppercase">Translados Gratuitos</strong>
              </div>
              <p>
                Para casos de emergência, a remoção e transporte do segurado até
                um centro médico mais próximo, conforme Condições Gerais do
                seguro contratado.
              </p>
            </Col>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <LuHotel />
                </div>
                <strong className="text-uppercase">
                  Convalescença em Hotel e Extensão de internação hospitalar
                </strong>
              </div>
              <p>
                Cobertura de custo prolongado de convalescença em hotel, até
                cinco dias, e extensão de internação hospitalar por recomendação
                médica são itens que constam de alguns planos de seguro viagem.
                Podem ser verificados em Condições Gerais do seguro contratado.
              </p>
            </Col>
          </Row>

          <Row>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <FaHandHoldingMedical />
                </div>
                <strong className="text-uppercase">Check-up Médico</strong>
              </div>
              <p>
                Por alguma doença que não seja pré-existente, sendo previamente
                autorizado pela Central Operativa.
              </p>
            </Col>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <TfiFaceSad />
                </div>
                <strong className="text-uppercase">
                  Seguro de Vida por Morte Acidental e Invalidez
                </strong>
              </div>
              <p>
                Para todos os planos que oferecem cobertura em caso de morte ou
                invalidez permanente por acidente, ocorrido durante o período de
                viagem ao exterior, as normas especificas estão esclarecidas em
                Condições Gerais
              </p>
            </Col>
          </Row>

          <Row>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <TbPigMoney />
                </div>
                <strong className="text-uppercase">
                  Assistência de Fiança Judicial
                </strong>
              </div>
              <p>
                Caso seja necessário, será adiantado um valor a título de
                empréstimo para pagamento da fiança, conforme Condições Gerais
                do seguro contratado.
              </p>
            </Col>
            <Col xs={24} md={12} className="text-justify p-3">
              <div className="text-center pb-3">
                <div className="pb-2 font-size-xxl">
                  <IoArrowBackSharp />
                </div>
                <strong className="text-uppercase">Garantia de Regresso</strong>
              </div>
              <p>
                Em caso de falecimento ou doença grave de um familiar direto, a
                operadora garante o regresso antecipado do segurado ao seu país
                de origem, conforme Condições Gerais do seguro contratado.
              </p>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default BenefitsAndCoverages;
