import axiosService from "./axiosService";
import { API_ORDERS_URL } from "../settings/constant";
import appService from "./appService";
import { isValidCpf, isValidEmail, translateX } from "../utils/helpers";

const orderService = {};

orderService.getOrderById = async (_id, projection) => {
  let url = API_ORDERS_URL;

  if (projection) url += `/${projection}`;

  const axiosOptions = { url, _id };
  const [doc] = await axiosService.get(axiosOptions);
  return doc;
};

orderService.getOrderByBuyerId = async (buyerId, projection) => {
  let url = `${API_ORDERS_URL}/get-by-buyer-id`;

  if (projection) url += `/${projection}`;

  const axiosOptions = {
    url,
    _id: buyerId,
    headers: { "x-resultas": "array" },
    returnAsObject: false,
  };
  const [doc] = await axiosService.get(axiosOptions);

  return doc;
};

orderService.getOrderByTravellerId = async (travellerId, projection) => {
  let url = `${API_ORDERS_URL}/get-by-traveller-id`;

  if (projection) url += `/${projection}`;

  const axiosOptions = {
    url,
    _id: travellerId,
    headers: { "x-resultas": "array" },
    returnAsObject: false,
  };
  const [doc] = await axiosService.get(axiosOptions);
  return doc;
};

orderService.getOrderByCustomerEmail = async (email) => {
  if (!isValidEmail(email)) return;

  const axiosOptions = {
    url: `${API_ORDERS_URL}/get-by-customer-email/${email}`,
  };
  const [doc] = await axiosService.get(axiosOptions);

  return doc;
};

orderService.getOrderByTravellerCpf = async (cpf) => {
  if (!isValidCpf(cpf)) return;

  const axiosOptions = {
    url: `${API_ORDERS_URL}/get-by-traveller-cpf/${cpf}`,
  };
  const [doc] = await axiosService.get(axiosOptions);

  return doc;
};

orderService.cancelOrder = async (order, quiet) => {
  return new Promise((resolve, reject) => {
    const axiosOptions = {
      _id: order?._id,
      url: `${API_ORDERS_URL}/cancel-by-id`,
      data: { status: "cancelled_by_customer" },
    };

    if (quiet) return $done();

    const options = {
      Content: "cancel_order_description",
      okText: "yes",
      cancelText: "no",
      onOk: $done,
      onCancel: () => {
        reject();
      },
    };

    appService.modal("c", "cancel_order_title", options);

    async function $done() {
      try {
        const [doc] = await axiosService.put(axiosOptions);
        appService.message(
          "s",
          translateX("cancel_order_success"),
          "cancelled",
        );
        resolve(doc);
        orderService.sendEmail(doc?._id, "cancelled").then();
      } catch (error) {
        reject(error);
      }
    }
  });
};

orderService.sendEmail = async (_id, action) => {
  let url = `${API_ORDERS_URL}/send-order-email`;

  const axiosOptions = { url, data: { action, _id } };
  const [doc] = await axiosService.post(axiosOptions);
  return doc;
};

export default orderService;
