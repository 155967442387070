import { Button, Drawer } from "antd";
import MultiLogo from "assets/images/logo.png";
import Navbar from "components/Navbar/Navbar";
import Logo from "components/UI/Logo/Logo";
import Text from "components/UI/Text/Text";
import TextLink from "components/UI/TextLink/TextLink";
import { AuthContext } from "context/AuthProvider";
import { LayoutContext } from "context/LayoutProvider";
import useWindowSize from "library/hooks/useWindowSize";
import { useCallback, useContext, useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import Sticky from "react-stickynode";
import { AGENT_PROFILE_PAGE } from "settings/constant";
import AuthMenu from "./AuthMenu";
import HeaderWrapper, {
  AvatarImage,
  AvatarInfo,
  AvatarWrapper,
  CloseDrawer,
  LogoArea,
  MobileNavbar,
} from "./Header.style";
import MainMenu from "./MainMenu";
import MobileMenu from "./MobileMenu";
import ProfileMenu from "./ProfileMenu";
import DarkMultiLogo from "assets/images/logo-116x70.png";

const avatarImg = `http://s3.amazonaws.com/redqteam.com/isomorphic-reloaded-image/profilepic.png`;

export default function Header() {
  let location = useLocation();
  const [{ searchVisibility }] = useContext(LayoutContext);
  const { loggedIn } = useContext(AuthContext);
  const { width } = useWindowSize();
  const [state, setState] = useState(false);
  const [logo, setLogo] = useState(MultiLogo);
  const sidebarHandler = () => {
    setState(!state);
  };
  const headerType = location.pathname === "/" ? "transparent" : "default";

  const [y, setY] = useState(window.scrollY);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;

      if (location.pathname === "/") {
        if (window.scrollY !== y) {
          setLogo(DarkMultiLogo);
        }

        if (window.scrollY === 0) {
          setLogo(MultiLogo);
        }
      }

      setY(window.scrollY);
    },
    [y, location], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (location.pathname !== "/") {
      setLogo(DarkMultiLogo);
    } else {
      setLogo(MultiLogo);
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth", // Para um comportamento de rolagem suave
    });
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <HeaderWrapper>
      <Sticky
        top={headerType === "transparent" ? -1 : 0}
        // innerZ={1050}
        innerZ={999}
        activeClass="isHeaderSticky"
      >
        {width > 991 ? (
          <Navbar
            logo={
              <Link to={"/"}>
                <img src={logo} width={140} alt="Multi Seguro Viagem." />
              </Link>
            }
            navMenu={<MainMenu />}
            authMenu={<AuthMenu />}
            isLogin={loggedIn}
            avatar={<Logo src={avatarImg} />}
            profileMenu={<ProfileMenu avatar={<Logo src={avatarImg} />} />}
            headerType={headerType}
            // searchComponent={<NavbarSearch />}
            location={location}
            searchVisibility={searchVisibility}
          />
        ) : (
          <MobileNavbar className={headerType}>
            <LogoArea>
              <>
                <Link to={"/"}>
                  <img src={logo} width={140} alt="Multi Seguro Viagem." />
                </Link>
              </>
              {/*<NavbarSearch />*/}
            </LogoArea>
            <Button
              className={`hamburg-btn ${state ? "active" : ""}`}
              onClick={sidebarHandler}
            >
              <span />
              <span />
              <span />
            </Button>
            <Drawer
              placement="right"
              closable={false}
              onClose={sidebarHandler}
              width="285px"
              className="mobile-header"
              open={state}
            >
              <CloseDrawer>
                <button onClick={sidebarHandler}>
                  <IoIosClose />
                </button>
              </CloseDrawer>
              {loggedIn ? (
                <AvatarWrapper>
                  <AvatarImage>
                    <Logo src={avatarImg} />
                  </AvatarImage>
                  <AvatarInfo>
                    <Text as="h3" content="Nova Scotia" />
                    <TextLink
                      link={AGENT_PROFILE_PAGE}
                      content="View Profile"
                    />
                  </AvatarInfo>
                </AvatarWrapper>
              ) : (
                <AuthMenu className="auth-menu" onClose={sidebarHandler} />
              )}
              <MobileMenu className="main-menu" />
            </Drawer>
          </MobileNavbar>
        )}
      </Sticky>
    </HeaderWrapper>
  );
}
