import { onProduction } from "../utils/helpers";

// configuration
export const SUBSCRIPTION_ID = "643ec14175fdf864855d7183";
export const COMPANY_ID = "65c5286cc542c2ee065e3686";
export const BANK_CODE = "461";
export const BANK_AGENCY_CODE = "4610001";
// export const BANK_ACCOUNT_CODE = "46100011134052"; // SANDBOX
export const BANK_ACCOUNT_CODE = "461000139268958"; // PRODUCTION

// apis
export const API_AUTH_URL = "/v1/auth";
export const API_TOOLS_URL = "/v1/tool";
export const API_ENTITIES_URL = "/v1/entity";
export const API_ORDERS_URL = "/v1/order";
export const API_RECEIVABLES_URL = "/v1/receivables";

// Internal Pages
export const QUOTATIONS_ROUTE = "/travel-insurance-quotations";
export const AUTH_ROUTE = "/login";
export const ORDERS_ROUTE = "/my-orders";

// Info pages
export const INFO_FOR_FOREIGNERS_EN_ROUTE = "/info-for-foreigners-en";
export const INFO_FOR_FOREIGNERS_PT_ROUTE = "/info-for-foreigners-pt";
export const INFO_FOR_FOREIGNERS_ES_ROUTE = "/info-for-foreigners-es";
export const INFO_TRAVEL_INSURANCE_ROUTE = "/info-travel-insurance";
export const INFO_WTS_TRAVEL_INSURANCE_ROUTE = "/info-wts-travel-insurance";
export const INFO_BENEFITS_COVERAGE_ROUTE = "/info-benefits-coverage";
export const INFO_FAQ_ROUTE = "/info-faq";
export const INFO_SCHENGEN_TREATY_ROUTE = "/info-schengen-Treaty";
export const INFO_EXCHANGE_TRAVEL_INSURANCE_ROUTE =
  "/info-exchange-travel-insurance";
export const INFO_INTERNATIONAL_TRAVEL_INSURANCE_ROUTE =
  "/info-international-travel-insurance";
export const INFO_EUROPE_TRAVEL_INSURANCE_ROUTE =
  "/info-europe-travel-insurance";

export const INFO_COMPANY_ROUTE = "/info-company";
export const INFO_CONTACT_ROUTE = "/info-contact";
export const INFO_PROMOTIONS_ROUTE = "/info-promotions";
export const INFO_PRIVACY_POLICY_ROUTE = "/info-privacy-policy";

// -------------------------------------------------------------------------- //

// General Page Section
export const HOME_PAGE = "/";

// Listing Single Page Section
export const LISTING_POSTS_PAGE = "/listing";
export const SINGLE_POST_PAGE = "/post";

// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = "/profile";
export const AGENT_ACCOUNT_SETTINGS_PAGE = "/account-settings";
export const AGENT_IMAGE_EDIT_PAGE = "change-image";
export const AGENT_PASSWORD_CHANGE_PAGE = "change-password";

// Other Pages
export const PRICING_PLAN_PAGE = "/pricing-plan";
export const PRIVACY_PAGE = "/privacy";
export const ADD_HOTEL_PAGE = "/add-hotel";

// Login / Registration Page
export const LOGIN_PAGE = "/sign-in";
export const REGISTRATION_PAGE = "/sign-up";
export const CHANGE_PASSWORD_PAGE = "/change-password";
export const FORGET_PASSWORD_PAGE = "/forget-password";

// Jarvisly Constants
export const ROW_GUTTER = 16;

export const APP = {
  ID: "appmulti",
  NAME: "Multi Seguro Viagem",
  THEME_COLOR: "CUSTOM_MULTI_APP",
  DOMAINS: [
    "multiseguroviagem.com.br",
    "www.multiseguroviagem.com.br",
    "www.multiseguroviagens.com.br",
    "multiseguroviagem.jarvisly.com",
  ],
  PROFILE: "singleCompany",
  DEV_MODE: !onProduction(),
  COPY_RIGHT: "Multi Seguro Viagem",
  API_DEV_HOST: "localhost",
  API_DEV_HTTPS: false,
  API_PORT: 8001,
  WS_PORT: 9001,
  API_HOST_DEV_LOCALHOST: "localhost",
  API_HOST_DEV_VPS: "vps.jarvisly.com",
  API_HOST_PRODUCTION: "vps.jarvisly.com",
  TOKEN: process.env.REACT_APP_APPMULTI_TOKEN,
  LANGUAGE: "pt",
  LOCALE: "pt-br",
  // LANGUAGE: "en",
  // LOCALE: "en-us",
  LOGO: "/public/images/logo.png",
  LOGO_WHITE: "/public/images/logo-white.png",
  LOGO_SM: "/public/images/logo-sm.png",
  LOGO_SM_WHITE: "/public/images/logo-sm-white.png",
  TITLE: "Multi Seguro Viagem",
  SLOGAN: "",
  SLOGAN2: "",
  SLOGAN_REGISTRATION: "registration_slogan",
  SLOGAN_REGISTRATION2: "",
  APPLICATION_LAYER: "adm",
  LOGIN_IDENTIFICATION: "email",
  SELF_REGISTRATION: false,
  GOOGLE_LOGIN: false,
  FACEBOOK_LOGIN: false,
  RESET_PASSWORD: true,
  NAVIGATION: {
    TYPE: "MENU",
    CONFIGURATIONS: false,
    LANGUAGES: false,
  },
  ENABLE_MARKETPLACE: false,
  FIREBASE_CONFIG: JSON.parse(process.env.REACT_APP_APPMULTI_FIREBASE_CONFIG),
};
