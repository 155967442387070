import { Card, Col, Collapse, Flex, Row, Table } from "antd";
import imgAffinity from "assets/images/affinity.png";
import imgAssistcard from "assets/images/assistcard.png";
import imgCoris from "assets/images/CORIS.png";
import imgGta from "assets/images/gta.png";
import imgAssistMed from "assets/images/assistMed.jpg";
import imgIntermac from "assets/images/intermac.png";
import imgUa from "assets/images/logos_ua_horizontal_baja.jpg";
import imgVital from "assets/images/vitalcard.png";

const InfoForForeigners = (props) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const itemsKey39 = buildItemsKey39();
  const itemsKey12 = buildItemsKey12();
  const itemsKey9 = buildItemsKey9();
  const itemsKey1 = buildItemsKey1();
  const items = buildItems();

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Row justify="center" className="mb-3">
      <Col style={{ width: 836 }}>
        <Card
          className="strong-border card-without-padding"
          title={
            <Row>
              <Col span={24}>
                <h1 className="text-center">Tudo sobre Seguro Viagem</h1>
                <h3 className="text-center text-muted mb-4">
                  Saiba tudo para cotar seu Seguro Viagem com segurança!
                </h3>
              </Col>
            </Row>
          }
          style={{
            margin: "18px 18px",
            maxWidth: 800,
          }}
          classNames="text-center"
        >
          <p className="font-size-md p-3 text-justify">
            Viajar com segurança e tranquilidade vai muito além de um bom
            destino, passagens aéreas, hospedagem e um bom roteiro. Nesse
            artigo, conheceremos mais sobre Seguro Viagem. Ter um seguro viagem
            adequado a seu perfil é fundamental para sua segurança e nesta
            página você saberá tudo sobre o tema, pois a Multi Seguro Viagem é
            especialista em seguro viagem. Ao adquirir um seguro viagem você
            conta com coberturas que te ajudam a resolver imprevistos como:
            Outras coberturas que nossos consultores podem te explicar melhor e
            que poderá se informar nesta página, que é a mais completa com
            informações sobre seguro viagem. Diante de tantas opções e
            coberturas talvez você se pergunte:
          </p>

          <ul>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Assistência médica e hospitalar de urgência e
                emergência em qualquer país do mundo;
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Seguro Viagem internacional para Europa, com tratado de
                Schengen;
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Seguro Viagem com proteções específicas para gestantes;
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Você é um atleta ou amante de esportes? Temos proteção
                especial em caso prática de esportes;
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Extravio de bagagens;
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Cobertura para atraso ou cancelamento de voo;
              </p>
            </li>
          </ul>

          <p className="font-size-md p-3 text-justify">
            Outras coberturas que nossos consultores podem te explicar melhor e
            que poderá se informar nesta página, que é a mais completa com
            informações sobre seguro viagem. Diante de tantas opções e
            coberturas talvez você se pergunte:
          </p>

          <Collapse accordion items={items} className="mb-4" ghost />
        </Card>
      </Col>
    </Row>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildItems() {
    const columns5 = [
      {
        title: "Operadora",
        dataIndex: "operadora",
        key: "operadora",
        render: (text) => <p>{text}</p>,
      },
      {
        title: "Telefones",
        dataIndex: "telefone",
        key: "telefone",
        render: (text) => <p>{text}</p>,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (text) => <p>{text}</p>,
      },
      {
        title: "WhatsApp",
        dataIndex: "whatsapp",
        key: "whatsapp",
        render: (text) => <p>{text}</p>,
      },
    ];
    const data5 = [
      {
        key: "1",
        operadora: "Affinity",
        telefone: (
          <>
            <p>
              <strong>VIAGENS INTERNACIONAIS</strong>
              <br />
              Ligação a cobrar do exterior para 1954 306 0622
            </p>
            <p>
              <strong>VIAGENS NO BRASIL</strong>
              <br />
              0800 038 06 21 ou 11 3132 9308
            </p>
          </>
        ),
        email: (
          <>
            <p>assistencia@affinityseguro.com.br</p>
          </>
        ),
        whatsapp: (
          <>
            <p>+1 863 201 4897</p>
          </>
        ),
      },
      {
        key: "2",
        operadora: "GTA",
        telefone: (
          <>
            <p>
              <strong>VIAGENS INTERNACIONAIS</strong>
              <br />
              DDR +55 11 25269311
            </p>
            <p>
              <strong>VIAGENS NO BRASIL</strong>
              <br />
              +55 11 31329280
            </p>
          </>
        ),
        email: (
          <>
            <p>assistenciaw@gtaassist.com.br</p>
          </>
        ),
        whatsapp: (
          <>
            <p>+1 863 201 4364</p>
          </>
        ),
      },
      {
        key: "3",
        operadora: "Assist Card",
        telefone: (
          <>
            <p>+55 11 3191 8699</p>
            <p>
              <strong>VIAGENS NO BRASIL</strong>
              <br />
              0800 1706133
            </p>
            <p>
              <strong>AMÉRICA LATINA</strong>
              <br />
              +54 (11) 5555-1500
            </p>
            <p>
              <strong>AMÉRICA DO NORTE</strong>
              <br />
              +1 800-874-2223
            </p>
            <p>
              <strong>EUROPA</strong>
              <br />
              +34 (91) 788-3333
            </p>
            <p>
              <strong>ÁSIA</strong>
              <br />
              +82 (2) 2023-5858
            </p>
          </>
        ),
        email: (
          <>
            <p>atendimento@starrcompanies.com</p>
          </>
        ),
        whatsapp: (
          <>
            <p>+54 91127039665</p>
          </>
        ),
      },
      {
        key: "4",
        operadora: "Intermac",
        telefone: (
          <>
            <p>
              <strong>ARGENTINA</strong>
              <br />
              +54 11 7700 4192
            </p>
            <p>
              <strong>ESPANHA</strong>
              <br />
              +34 91 108 9456
            </p>
            <p>
              <strong>AUSTRÁLIA</strong>
              <br />
              +61 2 8073 0588
            </p>
            <p>
              <strong>ESTADOS UNIDOS</strong>
              <br />
              +1 407 634 3302
            </p>
            <p>
              <strong>BRASIL</strong>
              <br />
              +55 11 4810 0669
            </p>
          </>
        ),
        email: (
          <>
            <p>asistencias@intermacinsurance.com</p>
          </>
        ),
        whatsapp: (
          <>
            <p>+1 (786) 786-1194</p>
          </>
        ),
      },

      {
        key: "5",
        operadora: "Assist Seguro Viagem",
        telefone: (
          <>
            <p>
              <strong>VIAGENS INTERNACIONAIS</strong>
              <br />
              Ligação a cobrar do exterior para +55 11 3042 2167
            </p>
            <p>
              <strong>VIAGENS NACIONAIS</strong>
              <br />
              0800 892 1341
            </p>
          </>
        ),
        email: (
          <>
            <p>contato@assistseguroviagem.com.br</p>
          </>
        ),
        whatsapp: (
          <>
            <p>+1 863 238 2786</p>
          </>
        ),
      },
      {
        key: "6",
        operadora: "Coris",
        telefone: (
          <>
            <p>
              <strong>VIAGENS INTERNACIONAIS</strong>
              <br />
              Chamada gratuita do exterior +55 11 2185-9696
            </p>
            <p>
              <strong>VIAGENS NACIONAIS</strong>
              <br />
              0800-11-08720
            </p>
          </>
        ),
        email: (
          <>
            <p>ops@assist24h.com.br</p>
          </>
        ),
        whatsapp: (
          <>
            <p>+55 11 9 9742-5892</p>
          </>
        ),
      },

      {
        key: "7",
        operadora: "Vital Card",
        telefone: (
          <>
            <p>
              <strong>REINO UNIDO</strong>
              <br />
              +44 (20) 3769 1990
            </p>
            <p>
              <strong>ESTADOS UNIDOS</strong>
              <br />
              Ligação gratuita para +1 (888) 2152 641
            </p>
            <p>
              <strong>ESTADOS UNIDOS</strong>
              <br />
              +1 786 2337 254
            </p>
            <p>
              <strong>ESPANHA</strong>
              <br />
              +34 (91) 0605 956
            </p>
            <p>
              <strong>BRASIL</strong>
              <br />
              0800 591 5071
            </p>
          </>
        ),
        email: (
          <>
            <p>assistencia@vitalcard.com.br</p>
          </>
        ),
        whatsapp: (
          <>
            <p>+54 9113137-2382</p>
          </>
        ),
      },

      {
        key: "8",
        operadora: "Universal Assistance",
        telefone: (
          <>
            <p>
              <strong>VIAGEM INTERNACIONAL</strong>
              <br />
              +54 11 4323-7770
            </p>
            <p>
              <strong>BRASIL</strong>
              <br />
              0800-761-9154
            </p>
          </>
        ),
        email: (
          <>
            <p>asistencias@universal-assistance.com</p>
          </>
        ),
        whatsapp: (
          <>
            <p>APP DISPONÍVEL</p>
          </>
        ),
      },
    ];

    return [
      {
        key: "1",
        label: (
          <h4 className="font-weight-bold">
            O seguro viagem é mesmo importante?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Apesar de muitas vezes o seguro viagem passar despercebido por
              alguns viajantes, vale lembrar que a falta de um bom seguro pode
              impactar diretamente em seus planos, principalmente se acontecer
              algum imprevisto com você ou sua família no exterior. Já pensou
              sentir dor de dente em outro país? Ou uma simples conjuntivite? Ou
              ter sua bagagem extraviada? Seja para problemas de saúde, atrasos
              de voos ou cancelamentos, perda ou roubo de bagagens, emergências
              médicas, perda de documentos, entre outros, a contratação do
              seguro viagem é fundamental. Viajantes mais precavidos e
              experientes já sabem da importância desse serviço e não dependem
              do seguro de seu cartão de crédito, muitas vezes limitado. Por
              isso tem aderido aos planos de assistência viagem oferecidos por
              operadoras profissionais e com muito tempo no mercado brasileiro e
              que apresentaremos mais abaixo e que sempre oferecem um seguro
              viagem qualificado e personalizado para cada tipo de roteiro. Se
              você nunca utilizou o seguro viagem, fique tranquilo e venha com a
              Multi Seguro Viagem, pois somos especialistas e estamos no mercado
              desde 2013 ajudando a pessoas de todo Brasil a fazerem uma viagem
              mais segura e tranquila. Veja aqui em detalhes sobre a importância
              do seguro viagem e como comprar seguro viagem.
            </p>

            <Collapse accordion items={itemsKey1} />
          </>
        ),
      },

      {
        key: "2",
        label: <h4 className="font-weight-bold">Formas de Pagamento</h4>,
        children: (
          <>
            <p className="font-size-md text-justify">
              Comprar seu seguro viagem é muito fácil e você pode fazer de
              diversas formas e condições:
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Desconto de 5% no boleto e PIX;
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Cartão de Crédito: Compre e parcele em até 6x sem
                  juros nas principais bandeiras do mercado como VISA,
                  MasterCard, Dinners e Hypercard e outras;
                </p>
              </li>
            </ul>

            <p className="font-size-md text-justify">
              Você pode comprar seguro viagem com a Multi Seguro Viagem das
              seguintes formas:{" "}
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Diretamente no Site da Multi Seguro Viagem;
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Pelo WhatsApp da Multi Seguro Viagem;
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Por telefone: (19) 3204-0447;
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Por e-mail: contato@multilseguroviagem.com.br;
                </p>
              </li>
            </ul>

            <p className="font-size-md text-justify">
              Todas as apólices de seguro viagem emitidas pela Multi Seguro
              Viagem, que pertence a Corretora EKTO MULTI SEGURO VIAGEM LTDA
              CNPJ: 43.514.278/0001-90, seguem todas as normas da SUSEP e do
              Conselho Nacional de Seguros Privados.
            </p>
          </>
        ),
      },

      {
        key: "3",
        label: (
          <h4 className="font-weight-bold">
            Por que o seguro viagem é fundamental para sua viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              O seguro ou assistência viagem é a única modalidade de seguro que
              possibilita assistência completa para todo tipo de viajantes,
              durante a sua permanência no destino escolhido, seja no Brasil ou
              no exterior. A principal vantagem do seguro viagem é dar suporte
              para qualquer situação ou imprevisto que você possa ter durante
              uma viagem, e que fogem do seu controle e vontade. Imagine quebrar
              um pé? Ou ter conjuntivite? Ou uma cólica de rim? Um simples
              atendimento médico em outro país pode te custar milhares de
              dólares se você não tiver um seguro viagem. É preciso se precaver
              e se assegurar para evitar prejuízos e uma experiência negativa em
              sua próxima viagem. Seguros mais completos oferecem por exemplo
              extensão de internação hospitalar e diárias em hotéis, assistência
              jurídica, medicamentos, assistência odontológica, repatriação,
              dentre outros. Você pode falar com nossa equipe de especialistas
              em Seguro Viagem para entender a melhor opção para sua
              necessidade. Isso é muito importante, pois agentes de viagem são
              especialistas em pacotes turísticos, e não em seguro viagem. Para
              casos mais específicos, algumas coberturas incluem até passagem
              aérea para familiares, interessante, não é? Quanto ao custo do
              seguro viagem, fique calmo: o investimento não chega a 5% do valor
              total que você investe em uma viagem! É um gasto mínimo perto da
              tranquilidade, suporte e segurança que o você encontra, caso surja
              algum problema ou imprevisto. É importante sempre lembrar que uma
              agência de turismo é especializada em pacotes turísticos, e muitas
              vezes não saberá orientá-lo na compra de um melhor seguro viagem.
              Além disso, existem diversas modalidades de planos de seguro
              viagem que podem ser contratados e casos onde não se pode
              contratar um seguro viagem, como prática profissional de esportes.
              Para estes casos, fale com nossa equipe que está pronta para te
              atender. As coberturas também são bastante amplas e variadas: vão
              desde à proteção para riscos de morte acidental do segurado,
              diárias, perda ou roubo de bagagem, danos de malas, invalidez
              total ou parcial, até para despesas médicas, hospitalares,
              odontológicas, entre outros acontecimentos inesperados. Já os
              planos, podem ser escolhidos de acordo com o tipo e perfil da
              viagem (familiar, negócios, lazer ou até um “mochilão”), valor de
              cobertura e claro, o preço que você deseja ou pode pagar. Se o
              destino for internacional, as regras são diferentes em relação a
              planos de viagens nacionais. Com a assinatura do Tratado de
              Schengen, alguns países da Europa passaram a exigir a contratação
              de um seguro viagem que cubra assistência medica e hospitalar de
              no mínimo 30 mil euros. O requisito é obrigatório e poderá ser
              exigido a apresentação do comprovante na hora do desembarque no
              destino Europeu. Nos casos de viagens nacionais, apesar de alguns
              planos de saúde atenderem em todo o Brasil, o seguro viagem pode
              ajudar no momento de um atendimento médico, por exemplo, ou
              contratempos como extravio de bagagens.
            </p>
          </>
        ),
      },

      {
        key: "5",
        label: (
          <h4 className="font-weight-bold">
            Como solicitar assistência no exterior?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Caso ocorra alguma emergência e você precise acionar seu Seguro
              Viagem, é necessário entrar em contato com a operadora escolhida,
              que está identificada em sua própria apólice. Lá, você encontrará
              telefones da central de atendimento do país de destino ou um geral
              para todos os países do mundo, com ligação gratuita ou a cobrar,
              telefones do Brasil, e-mail, skype e, em alguns casos, até
              atendimento por meio de WhatsApp e aplicativo. A ligação pode ser
              feita a cobrar (Collect Call) de qualquer telefone fixo no
              exterior. Ao ligar, basta explicar para a atendente o seu
              problema, qual cobertura você quer acionar e sua localização. Com
              essas informações, a central passará todo o procedimento que você
              deverá seguir. A Central funciona 24h, com atendimento em inglês e
              português. Mencione o idioma de sua preferência. Apesar da
              responsabilidade de atendimento no exterior ser da operadora
              contratada, em caso de necessidade, nossos consultores estarão
              também à disposição dentro do horário comercial. Após contato
              inicial com a operadora, basta enviar um e-mail para:
              contato@multiseguroviagem.com.br e podemos ajudar sempre que você
              precisar. Um dos diferencias da Multi Seguro Viagem é realizar o
              acompanhamento do seu atendimento, do início ao fim.
            </p>

            <Flex justify="center">
              <h3 className="text-center p-4">
                Contatos de emergência das operadoras de Seguro Viagem para
                solicitar assistência fora do país
              </h3>
            </Flex>

            <Table columns={columns5} dataSource={data5} pagination={false} />
          </>
        ),
      },

      {
        key: "6",
        label: (
          <h4 className="font-weight-bold">
            Vai utilizar o Seguro Viagem pela primeira vez? Veja algumas dicas!
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Antes de contratar qualquer Plano de Seguro Viagem, a orientação
              aos futuros viajantes que nunca utilizaram esse serviço é que
              verifiquem, primeiramente, procurem um especialista e não um
              agente de turismo, que é especializado em pacotes turísticos e
              normalmente não é um corretor de seguros. A Operadora de
              Assistência Viagem, é aquela responsável pelo serviço nas
              coberturas de assistência, e normalmente tem uma seguradora por
              trás, para garantir as indenizações. Não se esqueça também de
              contratar seu seguro com um corretor/serviço autorizado, como é o
              caso da plataforma Multi Seguro Viagem, da EKTO MULTI SEGURO
              VIAGEM LTDA.
            </p>
          </>
        ),
      },

      {
        key: "7",
        label: (
          <h4 className="font-weight-bold">
            Qual a diferença entre Seguro Viagem e Assistência Viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Antigamente, no momento de contratação do seguro viagem , o
              viajante muitas vezes ficava confuso com as diferentes opções de
              planos de seguro oferecidas, principalmente ao contratar com
              agências de turismo, `que não tem a especialidade de vender
              seguros. O mercado disponibilizava planos de Seguro Viagem e
              Assistência Viagem , comercializados separadamente, por
              Seguradoras e também por operadoras de Assistência Viagem . O que
              gerava muita confusão aos viajantes é que, além de existirem as
              duas opções, a diferença entre os serviços prestados pela
              seguradora e os serviços das assistências não era clara e, muitas
              vezes, se confundiam pela extrema semelhança. Para esclarecer, é
              preciso compreender que os planos de Seguro Viagem têm, por
              natureza, características indenizatórias e possuem como principais
              coberturas a morte ou a invalidez permanente, ocorridos durante a
              vigência da apólice. Já a Assistência Viagem é um serviço cujo
              objetivo é assistir o viajante durante sua viagem, com a prestação
              da assistência médica e hospitalar , por meio de prestadores de
              serviços credenciados que são informados no momento da necessidade
              do viajante, quando o mesmo entra em contato com a central de
              atendimento. Antes, o objetivo da Assistência Viagem não era
              claramente indenizatório e não considerava-se então um seguro de
              fato, mas sim um serviço de assistência, que não era prestado por
              seguradoras e nem regulamentado por orgãos como a SUSEP . Ambos os
              planos tinham como objetivo atender o viajante durante todo o
              período de viagem contratado, porém, como tinham características
              de coberturas e dinâmicas diferentes, o viajante só percebia ao
              precisar, que não tinha contratado o plano correto para atender
              suas reais necessidades. Descobriam, por exemplo, que diante de
              algum procedimento não tinham o direito ao reembolso de despesas
              médicas, justamente quando mais necessitavam desses serviços. O
              produto Seguro Viagem padrão, que era oferecido por seguradoras e
              algumas operadoras de cartão de crédito, não possuíam um plano de
              assistência agregado. Em casos de imprevistos, o viajante deveria
              arcar com todas as despesas no momento do ocorrido, e só depois
              realizar o pedido de reembolso, o que trazia muitos problemas,
              especialmente devido aos altos custos para atendimento no exterior
              versus o limite disponível no cartão do cliente por exemplo.
              Nesses casos, o reembolso só acontece quando você chega ao Brasil,
              e com prazo máximo de até 30 dias para recebimento em moeda
              nacional. Isso causava transtornos diversos, uma vez que o
              viajante deveria ter recurso suficiente para pagar suas despesas
              médicas no exterior. O Serviço de Assistência Viagem , por sua
              vez, oferecido pelas Operadoras sérias do Mercado, realiza
              atendimento aos viajantes em sua rede credenciada , sem custo ao
              viajante. Em caso de imprevistos, o viajante segurado deve sempre
              acionar primeiro a central de atendimento, não sendo necessário
              utilizar seus recursos próprios. Mesmo não havendo rede
              credenciada , o viajante pode pagar o atendimento e solicitar o
              reembolso posteriormente no retorno ao Brasil, desde que esteja
              com a autorização da rede de Assistência que contratou. O que
              pegava muitos viajantes de surpresa era a obrigatoriedade da
              comunicação com a central de atendimento . Em alguns casos, sem a
              autorização devida, a operadora podia negar um reembolso de
              despesas. As duas opções tem o objetivo de auxiliar o viajante
              durante seu período em viagem, porém, pequenas particularidades
              nos modelos de atendimento acabavam confundindo muito o viajante
              na contratação. Se ainda tiver alguma dúvida, pode falar com um de
              nossos especialistas da Multi Seguro Viagem ok? Telefone (19)
              3201-0447
            </p>
          </>
        ),
      },

      {
        key: "8",
        label: (
          <h4 className="font-weight-bold">
            Como funciona o modelo de Seguro Viagem atual?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              A boa notícia é que a Superintendência de Seguros Privados
              (SUSEP), preocupada com a segurança e bom atendimento dos
              viajantes, disponibilizou através da Resolução 315, novas regras
              que visam beneficiar os consumidores do seguro, de forma a
              organizar a comercialização, preencher esta lacuna do mercado e
              oferecer mais segurança aos viajantes que pretendem contratar um
              plano de Seguro Viagem, seja para suas viagens internacionais ou
              nacionais. Com base na nova resolução da SUSEP, os planos de
              Seguro Viagem foram organizados e devem conter, obrigatoriamente,
              as principais coberturas como: Médicas e hospitalares em viagem,
              doenças pré-existentes, entre outras. Hoje, os Planos de Seguro
              Viagem possuem coberturas com caráter indenizatório e como
              complemento também prestam serviço de Assistência Viagem.
            </p>
          </>
        ),
      },

      {
        key: "9",
        label: (
          <h4 className="font-weight-bold">
            Qual a diferença entre Plano de Seguro Viagem e Seguro Saúde?
          </h4>
        ),
        children: (
          <>
            <Collapse accordion items={itemsKey9} />
          </>
        ),
      },

      {
        key: "10",
        label: (
          <h4 className="font-weight-bold">
            Cuidados ao contratar um Seguro Viagem
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Se você possui uma doença crônica, o cuidado na hora da
              contratação do seguro precisa ser redobrado, já que a escolha do
              plano deve ser analisada para cobertura de doença preexistente. Em
              países onde os tratamentos médicos podem ser mais caros, como os
              Estados Unidos, por exemplo, é melhor optar por planos com valores
              de coberturas maiores e mais completos, adequando o investimento
              da contratação com a necessidade do viajante. Em caso de dúvidas,
              vale pesquisar os custos de alguns procedimentos médicos,
              intervenção cirúrgica ou até mesmo o valor de uma diária em um
              hotel, no país de destino. O seguro viagem gestante é outro
              produto indispensável para as grávidas, pois oferece atendimento
              para exames e ultrassonografias de emergência, cobertura para
              abortos espontâneos ou acidentais, partos de emergência e na
              ocorrência de partos prematuros. Mas atenção: as seguradoras
              estabelecem um prazo limite que geralmente vai até o 6º mês,
              principalmente para viagens internacionais. Reforçamos também que
              nos planos de Seguro Viagem, exames e atendimentos de rotina não
              estão cobertos. Outro detalhe importante está na prática de
              esportes radicais ou esportes na neve. Alguns seguros e
              assistências não cobrem acidentes esportivos em suas categorias
              básicas. É importante se atentar para adquirir um plano que possua
              a cobertura necessária ou que seja possível incluir essa cobertura
              adicional, por um custo extra. Pretende contratar um Plano de
              Seguro Viagem? Verifique qual Seguradora garantirá as coberturas
              indenizatórias, e qual Operadora de Assistência Viagem prestará o
              serviço nas coberturas de Assistência. Além disso, não se esqueça
              de contratar o Seguro sempre com um Corretor autorizado a atuar no
              mercado, como a Multi Seguro Viagem e a EKTO MULTI SEGURO VIAGEM
              LTDA.
            </p>
          </>
        ),
      },

      {
        key: "11",
        label: (
          <h4 className="font-weight-bold">
            Países que exigem a contratação do Seguro Viagem
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Se você ainda não sabe quais destinos exigem a contratação do
              seguro viagem com valor mínimo, confira alguns países em que o
              requisito é obrigatório. 27 países do Tratado de Schengen:
              Alemanha, Áustria, Bélgica, Dinamarca, Eslováquia, Eslovênia,
              Espanha, Estônia, Finlândia, França, Grécia, Holanda, Hungria,
              Islândia, Itália, Letônia, Liechtenstein, Lituânia, Luxemburgo,
              Malta, Noruega, Polônia, Portugal, República Tcheca, Suécia e
              Suíça.). Países que exigem a apresentação do certificado do Seguro
              Viagem na hora do desembarque: Países árabes (Emirados Árabes
              Unidos, Qatar e Turquia) e Cuba. Agora você já sabe: antes de
              embarcar, considere no seu checklist a contratação de um seguro
              viagem. Lembre-se de falar com um corretor especializado para
              evitar imprevistos e Boa Viagem! O Tratado de Schengen, existente
              há 30 anos, é um acordo entre alguns países europeus sobre a
              política de abertura de fronteiras para livre circulação de
              turistas e estudantes. Portanto, se você está pensando em realizar
              um intercâmbio ou viagem para a Europa, é importante saber quais
              são os países que fazem parte do Tratado de Schengen e quais são
              as exigências para o desembarque nestes países.
            </p>
          </>
        ),
      },

      {
        key: "12",
        label: (
          <h4 className="font-weight-bold">
            Seguro Viagem - Tratado de Schengen
          </h4>
        ),
        children: (
          <>
            <Collapse accordion items={itemsKey12} />
          </>
        ),
      },

      {
        key: "13",
        label: (
          <h4 className="font-weight-bold">
            Recomendações Essenciais – Aviso ao Viajante
          </h4>
        ),
        children: (
          <>
            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Os planos de seguro e/ou assistência de viagem podem
                  ser comercializados em canais habilitados para a venda, como é
                  o caso da Multi Seguro Viagem.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Confirme o recebimento de seu voucher. Lembre-se de
                  imprimi-lo e levá-lo junto com seus documentos de viagem,
                  especialmente se for para Europa ou países onde o seguro é
                  obrigatório.
                </p>
              </li>{" "}
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Verifique atentamente todas as condições descritas no
                  voucher, inclusive as condições gerais do(s) plano(s)
                  contratado(s) que são também disponibilizadas no site da
                  Operadora.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Antes de realizar qualquer atendimento ou utilizar
                  qualquer cobertura, é obrigatório entrar em contato com a
                  operadora contratada. Caso aconteça algo urgente que te impeça
                  de contatar a operadora antes do atendimento, faça isso assim
                  que possível para evitar dores de cabeça.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Verifique no voucher do seguro viagem onde estão os
                  contatos de telefone, e-mails e demais formas de atendimento
                  da operadora, em caso de qualquer emergência.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Após receber atendimento na clínica, hospital da rede
                  credenciada (autorizada pela operadora), ou seu próprio hotel,
                  não informe seu próprio endereço, para que não haja envio de
                  cobranças futuras. Verifique no voucher qual endereço deve ser
                  fornecido, ou informe-se junto à operadora.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Toda apólice de seguro viagem tem o endereço da
                  operadora que você contratou. Para evitar futuras cobranças em
                  sua residência, coloque o endereço no cadastro feito no
                  hospital.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Evite comprar com agências de turismo, pois estas não
                  são especializadas em Seguro Viagem, e sim em pacotes
                  turísticos. Fora isso, agências de turismo normalmente
                  trabalham com apenas uma ou duas opções de seguro e não tem um
                  preço tão competitivo como a Multi Seguro Viagem.
                </p>
              </li>
            </ul>
          </>
        ),
      },

      {
        key: "14",
        label: (
          <h4 className="font-weight-bold">
            Qual o valor do Seguro Viagem em relação ao total investido em uma
            viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Antes de escolher o plano ideal para você é necessário entender
              quais são suas necessidades e qual é o motivo/tipo de sua viagem:
              trabalho, lazer, viagem com a família, intercâmbio, viagem com
              esportes radicais, se tem idosos com você ou grávidas, ou ainda se
              participará de alguma competição esportiva, por exemplo. Para te
              ajudar, aqui vão algumas perguntas:
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Tem alguma cobertura que você considera importante,
                  além da assistência médica?
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Qual valor de cobertura de Assistência Médica você
                  procura? Quanto maior a cobertura, mais seguro você estará!
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Possui alguma doença Pré-Existente? E você sabe que
                  tem esta doença?
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Vai praticar esportes dentro do período de viagem?
                </p>
              </li>
            </ul>

            <p className="font-size-md text-justify">
              Objetivo bem definido, basta comparar e analisar todas as
              coberturas e preços, para encontrar qual modelo mais se adequa ao
              perfil de sua viagem. Lembre-se: todas as seguradoras possuem
              atendimento personalizado e centrais 24h para te atender em
              português em caso de urgências e em horário comercial, nossa
              equipe comercial de consultores, também poderá ajudar caso você
              precise. Nossa plataforma oferece atendimento de consultores
              especializados em Seguro Viagem. Em caso de dúvida, fale com nossa
              equipe!.{" "}
            </p>
          </>
        ),
      },

      {
        key: "15",
        label: (
          <h4 className="font-weight-bold">
            Como funciona a utilização do Seguro Viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Todas as operadoras parcerias da Multi Seguro Viagem trabalham com
              atendimento por meio de redes credenciadas. Ao entrar em contato
              com a central de atendimento 24hs da operadora que você contratou,
              você será encaminhado para a rede credenciada mais próxima ou a
              empresa encaminhará um médico até você. Fazendo esse procedimento,
              você não terá custos adicionais em seu atendimento. Caso queira,
              você também pode ir para um hospital ou centro médico de sua
              escolha, pagar pelo atendimento e solicitar o reembolso para a
              operadora que é a única responsável por seu atendimento. “E se for
              um caso emergencial e eu não conseguir entrar em contato?” Fique
              tranquilo(a)! Você será encaminhado ou pode você mesmo ir para um
              hospital mais próximo e será atendido normalmente, porém, assim
              que for possível é necessário entrar em contato com a operadora e
              explicar que ocorreu um caso extraordinário e que você está ou já
              foi atendido no hospital (nome do hospital). Se você precisar
              pagar por algum serviço, guarde todos os laudos médicos e recibos
              para que possa solicitar o reembolso do valor, lembrando sempre de
              não dar seu endereço, mas sim o endereço da operadora para que a
              cobrança seja enviada a eles, se este for o caso.
            </p>
          </>
        ),
      },

      {
        key: "16",
        label: (
          <h4 className="font-weight-bold">
            Quais são as formas de pagamento aceitas na Multi Seguro Viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              O pagamento referente ao plano escolhido poderá ser realizado
              através do boleto bancário e pix ou utilizando cartão de crédito
              das bandeiras MasterCard, Visa, Dinners e Hipercard.
            </p>
          </>
        ),
      },

      {
        key: "17",
        label: (
          <h4 className="font-weight-bold">
            Como posso alterar meu plano de seguro viagem, após contratado?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Para fazer qualquer tipo de alteração em sua apólice é necessário
              entrar em contato conosco por meio da nossa página de contato, até
              48h úteis antes do Início da vigência de sua apólice, para que
              seja possível alterar e reencaminhar a você a nova apólice. Após o
              início de vigência de sua viagem, não será possível fazer qualquer
              tipo de alteração. Estamos disponíveis de segunda a sexta, das
              9hrs às 17hrs.
            </p>
          </>
        ),
      },

      {
        key: "18",
        label: (
          <h4 className="font-weight-bold">
            Posso cancelar meu Plano de Seguro Viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Independente do motivo, seja por cancelamento da viagem ou
              desistência de obter um plano, você poderá cancelar seu plano de
              Seguro Viagem entrando em contato conosco em até 48hs úteis. Se o
              pagamento foi feito em cartão de crédito, o valor será estornado
              em seu próprio cartão, em até 48hs úteis. Caso o pagamento tenha
              sido feito via boleto bancário, para que possamos realizar o
              estorno em sua conta é necessário que você nos informe todos os
              seus dados bancários: nome completo e CPF. Você poderá fazer esta
              solicitação por meio da nossa central de atendimento (19)
              3201-0447 ou enviar uma solicitação com o motivo do cancelamento,
              nº do voucher e operadora para o e-mail
              contato@multiseguroviagem.com.br Após o início de vigência de sua
              viagem, não será possível fazer qualquer tipo de cancelamento de
              seu Seguro Viagem. Estamos disponíveis de segunda a sexta, das
              9hrs às 17hrs.
            </p>
          </>
        ),
      },

      {
        key: "19",
        label: (
          <h4 className="font-weight-bold">
            Até quantos dias antes da viagem é possível contratar o seguro
            viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              O seguro viagem pode ser contratado até o dia de sua viagem ou
              seja, no mesmo dia que partirá para seu destino. A única exigência
              é que o viajante ainda esteja em território nacional. Caso ocorra
              alguma urgência e o cliente precise utilizar o plano, a operadora
              faz a verificação por meio da passagem aérea que consta data e
              hora de embarque. Guarde sempre sua passagem aérea, pois a
              operadora poderá solicitar que você comprove sua data de embarque.
              De qualquer forma, recomendamos que não deixe para a última hora e
              adquira seu seguro no mesmo dia que comprar suas passagens aéreas.
              Em caso de dúvidas, entre em contato conosco
            </p>
          </>
        ),
      },

      {
        key: "20",
        label: (
          <h4 className="font-weight-bold">
            Existe Seguro Viagem para viagens acima de 1 ano?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              O seguro viagem poderá ser contratado com mínimo de 3 dias e
              máximo de 365 dias. A maioria das operadoras emitem apólices
              somente até 1 ano. Mas existem opções com prazo maior. Porém, é
              possível renovar o seguro mesmo se o viajante estiver ainda no
              exterior. Caso sua viagem seja superior a esse período de 1 ano,
              você deve entrar em contato conosco com pelo menos 5 dias antes do
              término da vigência da sua apólice, para que possamos solicitar a
              prorrogação da mesma junto a operadora responsável por sua apólice
              de seguro.
            </p>
          </>
        ),
      },

      {
        key: "21",
        label: (
          <h4 className="font-weight-bold">
            Como é o procedimento para renovar meu seguro viagem se eu já
            estiver no exterior e precisar prolongar a viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              É possível fazer a renovação do seguro viagem mesmo se o viajante
              estiver em plena viagem no exterior. Para renovação, basta o
              cliente entrar em contato conosco com no mínimo 5 dias antes do
              término da vigência da sua apólice para solicitarmos a prorrogação
              e autorização da mesma junto a operadora.
            </p>
          </>
        ),
      },

      {
        key: "22",
        label: (
          <h4 className="font-weight-bold">
            Existe Seguro Viagem para viagens nacionais? Quando devo contratar?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Existe sim e você deve contratar sempre pois é diferente de um
              plano de saúde. O Seguro de Viagem Nacional é um produto
              extremamente acessível em termos de preço, para realmente deixar
              sua viagem mais segura, especialmente pra quem não possui plano de
              saúde nacional. O Seguro de Viagem Nacional possui diversas
              coberturas para ajudar o viajante dentro de seu período de viagem.
              A principal cobertura é assistência médica. No entanto, o viajante
              também tem cobertura farmacêutica, odontológica, seguro por morte
              e invalidez acidental, dentre outras. Este seguro cobre qualquer
              tipo de viagem no Brasil, desde que o passageiro esteja a uma
              distância de pelo menos 100 km (quilômetros) de sua residência.
              Caso você precise utilizar qualquer um dos serviços oferecidos
              pelo plano de seguro viagem, basta entrar em contato com a central
              de atendimento, e a operadora lhe enviará o procedimento correto a
              ser seguido, encaminhando você para uma rede credenciada
              particular ou encaminhando um médico até você. Selecione o destino
              “Brasil” em nosso cotador online para visualizar os planos
              disponíveis.
            </p>
          </>
        ),
      },

      {
        key: "23",
        label: (
          <h4 className="font-weight-bold">
            Eu preciso mesmo de um Seguro Viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              A principal vantagem de adquirir um seguro viagem é você minimizar
              qualquer situação que fuja do controle ou imprevisto que você
              possa ter durante a sua viagem. Um atendimento médico no exterior
              pode custar milhares de dólares, você sabia? Além disso, é preciso
              se precaver em caso de extravio de bagagens, perda de documentos
              ou nos atrasos e cancelamentos dos voos. Se precisar de
              atendimento médico, por exemplo, com a assistência do seguro
              viagem você não gastará nada além das despesas já previstas em sua
              viagem. Qualquer plano que você escolher, dentre as operadoras
              parcerias do nosso site, o atendimento será totalmente em
              português, de qualquer lugar do mundo que você estiver. Os seguros
              mais completos oferecem ainda viagens marítimas, atendimentos
              clínicos e odontológicos, assistência jurídica, medicamentos,
              repatriação, extensão de internação hospitalar, diárias em hotéis.
              Em casos específicos, também garantem passagem aérea para
              familiares. Viaje mais tranquilo sabendo que estamos aqui se
              precisar de ajuda. Entenda e conheça os planos, compare e escolha
              a opção ideal para a sua viagem!
            </p>
          </>
        ),
      },

      {
        key: "24",
        label: (
          <h4 className="font-weight-bold">
            O que fazer em caso de extravio de malas?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Primeiramente, é necessário que o viajante informe a companhia
              aérea e solicite o documento de comprovação de extravio de
              bagagem, chamado PIR (Property Irregularity Report). Com o número
              do PIR em mãos, entre em contato com a sua operadora de seguro
              viagem, que irá informar o próximo passo. Caso sua bagagem seja
              extraviada, a cobertura de extravio de bagagem não cobre furtos ou
              danos. Além de ser pago, o valor por quilo pode variar de
              operadora para operadora, e essas informações são internas.
              Normalmente é pago U$ 30 a U$ 45 por quilo, até o limite da
              cobertura contratada. Verifique as regras do plano que decidir
              contratar. Lembre-se de comunicar também nossa central para que
              possamos acompanhar o atendimento e te ajudar a acelerar as coisas
              junto às operadoras.
            </p>
          </>
        ),
      },

      {
        key: "25",
        label: (
          <h4 className="font-weight-bold">
            Qual documento deve constar nos meus Vouchers?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Cada operadora exige um tipo de documentação para a emissão das
              apólices, mas o documento principal é seu CPF. Caso o viajante
              seja menor de idade, e não tenha CPF, apresente o número do
              passaporte ou insira o documento do responsável legal.
            </p>
          </>
        ),
      },

      {
        key: "26",
        label: (
          <h4 className="font-weight-bold">
            O que fazer quando o passageiro for menor de idade e não possuir
            CPF?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Caso o viajante seja menor de idade e não tenha CPF, o viajante
              pode utilizar o número do passaporte com data de validade ou o CPF
              do responsável legal. Porém, nesses casos é necessário ver quais
              os documentos exigidos pela operadora. Fale conosco em caso de
              dúvidas.
            </p>
          </>
        ),
      },

      {
        key: "27",
        label: (
          <h4 className="font-weight-bold">
            Como funciona o seguro viagem para a prática de esportes? E se eu me
            machucar?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Não se preocupe. O seguro viagem também oferece cobertura nesse
              quesito. A maioria dos planos de seguro viagem já possui uma
              cobertura mínima e específica para Prática de Esportes ou o plano
              cobre acidentes derivados do esportes, dentro da cobertura de
              Assistência Médica. A única exigência é que, para acionar essa
              cobertura, o viajante tem de estar Praticando Esportes em caráter
              amador. Esportes decorrentes a competição ou profissional estão
              excluídos desta cobertura. Indicamos a contratação do Seguro
              Viagem especifico e focado para esportes com um de nossos
              consultores, pois oferecem coberturas superiores para diversas
              situações que podem ocorrer durante a prática de esportes em sua
              viagem. Para identificar quais esportes são cobertos, confira
              sempre as Condições Gerais do plano ou fale com seu consultor aqui
              na Multi. Lá você encontrará essas e outras informações sobre os
              tipos de cobertura.
            </p>
          </>
        ),
      },

      {
        key: "28",
        label: (
          <h4 className="font-weight-bold">
            Como funciona a seleção das datas de ida e de volta na hora de uma
            simulação no site da Multi?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Este item da sua cotação é de extrema importância para que você
              não fique nenhum dia sem seguro. Na data de ida, selecione o dia
              exato em que você sairá do Brasil. Atenção: jamais coloque a data
              de chegada ao seu destino. Na data da volta, selecione a data
              exata em que chegará ao Brasil, pois isso garante 100% de
              cobertura durante toda a viagem, principalmente a cobertura para
              extravio da bagagem, que ocorre na ida ou na volta, certo?
            </p>
          </>
        ),
      },

      {
        key: "29",
        label: (
          <h4 className="font-weight-bold">
            Posso contratar um seguro viagem se eu ou meu familiar já estiver no
            exterior?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Caso você já esteja no exterior ou embarque sem contratar o seguro
              de viagem, é possível fazer a contratação do seguro durante o seu
              período de viagem. Atualmente, a maioria das operadoras não vende
              para clientes já em viagem, mas algumas operadoras trabalham com a
              venda mediante a autorização e preenchimento de uma declaração de
              saúde e período de carência. Para isso, você precisa entrar em
              contato com a Multi Seguro Viagem, por meio do e-mail
              contato@multiseguroviagem.com.br nos informando: seu nome
              completo, CPF e data de nascimento dos viajantes, bem como o país
              em que os mesmos se encontram e data de volta da viagem. Nossos
              consultores irão apresentar as opções de seguro e o procedimento
              que deverá ser feito para solicitar a autorização da compra do
              seguro. Após encaminhado, a operadora pode demorar até 72hs para
              responder, aprovando ou não a contratação. Se aprovado, emitiremos
              e encaminharemos a apólice ao viajante. Nos casos de contratação
              já em período de viagem, existe normalmente uma carência para
              utilização do plano que pode variar de 3 a 5 dias úteis.
            </p>
          </>
        ),
      },

      {
        key: "30",
        label: (
          <h4 className="font-weight-bold">
            Como funciona o seguro viagem para gestante?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              O seguro viagem gestante é indispensável para mulheres em qualquer
              período de gravidez. A contratação deste serviço garante
              atendimento para exames e ultrassonografias de emergência,
              cobertura para abortos espontâneos ou acidentais, partos de
              emergência e também na ocorrência de partos prematuros.
              Atualmente, a maioria dos planos possui uma cobertura específica
              para a gestação ou cobertura de Assistência Médica para eventuais
              complicações gestacionais. É importante ressaltar que o seguro não
              cobre o acompanhamento de sua gestação. Exames e atendimentos de
              rotina não estão cobertos. O seguro cobre apenas atendimento
              emergencial, ou seja consultas de rotina não podem ser feitas. É
              importante ressaltar que cada operadora possui uma regra própria.
              No entanto, toda gestante precisa ter uma declaração de seu médico
              informando que se encontra em ótima condição de saúde e que está
              apta a viajar. Outro ponto é a idade permitida para a gestante.
              Alguns planos cobrem gestantes com no máximo 40 anos. A dica
              importante é sempre identificar até qual semana você estará
              completamente coberta pelo plano. Lembre-se: quanto maior a sua
              cobertura, mais segura você e seu bebê estarão. Consulte sempre um
              médico antes de fazer sua viagem.
            </p>
          </>
        ),
      },

      {
        key: "31",
        label: (
          <h4 className="font-weight-bold">
            Como funciona o seguro viagem para a terceira idade? Posso
            contratar?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Funciona como qualquer outro seguro viagem, porém, como crianças e
              idosos apresentam maior probabilidade de risco, o uso poderá ser
              maior e por isso tendem a ser mais caros. A maioria dos planos
              possuem limitação de idade de até 100 anos. Para idosos de 70 a 75
              anos (dependendo da operadora), o valor de seu plano terá um
              acréscimo de 25 a 50% no valor. Mas os viajantes da terceira idade
              não precisam se preocupar, pois receberão o mesmo suporte e as
              mesmas coberturas dos demais grupos de viajantes. Dica: É
              importante ficar atento sobre a redução da cobertura para
              viajantes em idade avançada. Acima de 85 anos, por exemplo, a
              cobertura poderá ser reduzirá 50% do valor.
            </p>
          </>
        ),
      },

      {
        key: "32",
        label: (
          <h4 className="font-weight-bold">Para que serve o Visto Schengen?</h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Se você planeja um intercâmbio ou viagem a trabalho para alguns
              dos países do Tratado de Schengen, saiba que o período superior
              aos 90 dias consecutivos exige a emissão de visto. A autorização é
              emitida pelo Consulado ou Embaixada do país onde você ficará
              instalado, seguindo o período solicitado na hora da emissão.
            </p>
          </>
        ),
      },

      {
        key: "33",
        label: (
          <h4 className="font-weight-bold">
            Quais países fazem parte do Tratado de Schengen?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Aproximadamente 27 países fazem parte do Tratado de Schengen:
              Alemanha, Áustria, Bélgica, Dinamarca, Eslováquia, Eslovênia,
              Espanha, Estônia, Finlândia, França, Grécia, Holanda, Hungria,
              Islândia, Itália, Letônia, Liechtenstein, Lituânia, Luxemburgo,
              Malta, Noruega, Polônia, Portugal, República Tcheca, Suécia e
              Suíça. Para viajar tranquilo, com segurança e garantir sua livre
              circulação em todos esses países, adquira seu seguro com a Multi
              Seguro Viagem!
            </p>
          </>
        ),
      },

      {
        key: "34",
        label: (
          <h4 className="font-weight-bold">
            Quais documentos são exigidos para o Visto Schengen?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Se você pretende realizar um intercâmbio ou viagem a trabalho para
              alguns dos países que fazem parte do Tratado de Schengen, saiba
              que o período superior a 90 dias consecutivos exige a emissão de
              visto. A autorização é emitida pelo Consulado ou Embaixada do país
              que você ficará instalado, durante o período solicitado no momento
              da emissão. Alguns documentos devem ser apresentados para a
              liberação. Entre os principais, estão:
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro viagem com cobertura de até 30 mil euros
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Passaporte original;
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Comprovante de passagem aérea;
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Reservas de hospedagem ou carta-convite: nos casos de
                  estadia em casas de amigos.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Comprovante de situação financeira: normalmente o
                  extrato bancário dos últimos seis meses é o suficiente para
                  justificar a renda suficiente para permanência no destino.
                  Para os estudantes que não possuem renda, a comprovação pode
                  ser por meio de uma declaração oficial garantindo que outra
                  pessoa se responsabiliza pelo seu sustento durante o período
                  de estadia no país.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Formulário de aplicação de visto Schengen preenchido
                  e com foto 3x4 recente;
                </p>
              </li>
            </ul>

            <p className="font-size-md text-justify">
              Alguns países, além do Tratado de Schengen, também exigem a
              obtenção do seguro viagem, porém sem coberturas mínimas:
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Países do Golfo – Emirados Árabes Unidos, Qatar e
                  Turquia
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Cuba;
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Venezuela - Exige seguro viagem e mantém um padrão
                  parecido do Tratado de Schengen, porém com cobertura mínima de
                  40 mil dólares para assistência médica, repatriação e
                  funerária.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Reservas de hospedagem ou carta-convite: nos casos de
                  estadia em casas de amigos/parentes.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Austrália - Exige a cobertura de assistência viagem
                  para estudantes e a contratação de um seguro próprio, chamado
                  OSHC, para que o estudante tenha direito ao sistema público de
                  saúde do país.
                </p>
              </li>
            </ul>
          </>
        ),
      },

      {
        key: "35",
        label: (
          <h4 className="font-weight-bold">
            Quais modelos de coberturas os viajantes mais procuram em um Seguro
            Viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Pela nossa experiência de anos no mercado, identificamos que as
              coberturas mais procuradas pelos viajantes são:
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Assistência Médica: cobertura concedida caso o
                  viajante sofra algum acidente ou adquira alguma enfermidade em
                  seu período de viagem. Nesta cobertura, você terá direito a um
                  atendimento médico particular.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Extravio de Bagagem: caso sua bagagem seja
                  extraviada, a operadora irá indenizar o valor para a compra de
                  alguns suprimentos básicos, até seu limite de cobertura, e de
                  acordo com o peso de sua bagagem.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Doença Pré-Existente: é uma cobertura para viajantes
                  que já tomam algum tipo de medicamento controlado ou que
                  tenham algum problema de saúde pré-existente.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Odontológica: cobertura para qualquer problema
                  dentário que impeça o viajante de prosseguir com sua viagem,
                  Em caso de dor e incômodo, a operadora também prestará o
                  atendimento odontológico.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Farmacêutica: caso você passe por um atendimento
                  médico e o mesmo receite algum medicamento, essa cobertura
                  ajudará nos custos farmacêuticos.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro por Morte Acidental: em caso de falecimento do
                  viajante, por acidente, o seguro pagará uma indenização aos
                  herdeiros legais.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Prática de Esportes: esta cobertura garante o direito
                  a uma assistência médica, em casos de acidente por algum
                  esporte de caráter amador
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Gestação: oferece assistência médica para gestantes,
                  em caso de emergência referente a problemas da gestação. Não
                  cobre acompanhamento médico, apenas atendimento emergencial.
                </p>
              </li>
            </ul>

            <p className="font-size-md text-justify">
              Identificou a cobertura de seu interesse? Fale com a Multi Seguro
              Viagem.
            </p>
          </>
        ),
      },

      {
        key: "36",
        label: (
          <h4 className="font-weight-bold">
            Vou viajar para continentes diferentes. Como funciona o seguro neste
            caso?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Quando o viajante for viajar para continentes diferentes, em um
              mesmo período de viagem, é necessário que o plano cubra as
              necessidades de ambos os continentes. No caso de viagens que
              incluem o continente Europeu, é imprescindível lembrar que o
              seguro viagem esteja dentro das exigências do Tratado de Schengem,
              que exige que o viajante tenha um Seguro/Assistência Viagem com
              cobertura para assistência médica de no mínimo 30 mil euros. A
              sugestão de nossos consultores é que o cliente contrate um seguro
              viagem com cobertura mínima de 50 mil dólares de assistência
              médica, pois os planos em dólares são válidos mundialmente. Assim,
              quando o viajante passar pelo território Europeu, a cobertura
              médica de seu seguro será convertido na moeda local, totalizando
              aproximadamente 40 mil euros de assistência médica internacional.
            </p>
          </>
        ),
      },

      {
        key: "37",
        label: (
          <h4 className="font-weight-bold">
            Quais os cuidados ao contratar um Seguro Viagem?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Se você sofre de alguma doença crônica, o cuidado na contratação
              do seguro deverá ser redobrado! Verifique se o plano que deseja
              possui cobertura para doença preexistente. É melhor optar por
              planos com valores de coberturas maiores e mais completos, e
              adequar assim o investimento da contratação de acordo com sua
              necessidade. Em países como os Estados Unidos, por exemplo, os
              tratamentos médicos podem sair muito caros. Se você for gestante
              ou pretende praticar alguma modalidade de esporte radical, por
              exemplo, também precisará de um seguro viagem com cobertura
              especial para o seu perfil de viajante. Na dúvida, é sempre bom
              pesquisar os custos de alguns procedimentos médicos, intervenção
              cirúrgica ou até mesmo o valor de uma diária em hotel, no país de
              destino.
            </p>
          </>
        ),
      },

      {
        key: "38",
        label: (
          <h4 className="font-weight-bold">
            Como funciona Seguro Viagem para Cruzeiro, ou seja Seguro Marítimo?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Não é necessário contratar um plano específico para viagens de
              cruzeiro ou viagens marítimas, pois nenhum plano de Seguro Viagem
              diferencia viagens terrestres e marítimas. A questão é que os
              navios não possuem parcerias com operadoras de seguro viagem,
              sendo assim, quando o cliente estiver em alto mar e precisar de um
              atendimento médico, será necessário que o mesmo utilize o
              atendimento do próprio navio, arcando com os devidos custos, se
              houver. Porém, basta o viajante guardar todos os laudos,
              recibos/notas fiscais para solicitar o reembolso do valor depois
              da viagem. E, nos casos em que o viajante estiver em terra, ou
              seja, em uma parada do Navio para passeios terrestres, ele poderá
              entrar em contato com a central de atendimento 24hrs. Esta central
              irá passar todo procedimento a ser seguido e encaminhará o cliente
              para a rede credenciada, ou encaminhará o médico até o cliente.
              Tudo dependerá da sua localização e disponibilidade.
            </p>
          </>
        ),
      },

      {
        key: "39",
        label: (
          <h4 className="font-weight-bold">
            Qual a diferença entre o Seguro Viagem emitido pelas Operadoras e os
            emitidos pelas empresas de Cartão de Credito?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Alguns clientes obtém o seguro viagem gratuitamente pelo cartão
              credito, quando compram passagens aéreas. No entanto, a utilização
              do seguro viagem via cartão de crédito é um pouco distinta do
              padrão de seguro oferecido pelas operadoras. Confira algumas
              diferenças:
            </p>

            <div className="text-center w-100">
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <th>
                    <strong>Seguro Viagem emitido pelas Operadoras</strong>
                  </th>
                  <th>
                    <strong>
                      Seguro Viagem emitido pelo Cartão de Crédito
                    </strong>
                  </th>
                </tr>
                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">
                    Os viajantes são livres para escolher a cobertura que melhor
                    se adequar ao seu perfil, e ao perfil de sua viagem.
                  </td>
                  <td className="p-3">
                    Geralmente o cliente só terá o seguro liberado se comprar as
                    passagens aéreas pelo cartão, e o valor de cobertura é
                    limitado, sem a opção de escolha.
                  </td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">
                    Empresa especialista em assistência de viagem, com rede
                    credenciada e possibilidade do médico ir até seu hotel
                  </td>
                  <td className="p-3">
                    {" "}
                    Diversas linhas de serviços, normalmente necessário o
                    Usuário gastar e depois pedir reembolso.
                  </td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">
                    {" "}
                    Central de atendimento 24h dedicada a atender passageiros
                    durante o período da viagem
                  </td>
                  <td className="p-3">
                    {" "}
                    Central de atendimento comum para informações sobre o seguro
                    e demais serviços monetários.
                  </td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">
                    {" "}
                    Possibilidade de contratação para acompanhantes, incluindo
                    planos especiais em diversos produtos
                  </td>
                  <td className="p-3">
                    {" "}
                    Contempla apenas do titular, conforme perfil do cartão.
                  </td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">
                    Amplas coberturas, serviços e conveniência para o viajante.
                  </td>
                  <td className="p-3">
                    {" "}
                    Coberturas e serviços limitados de acordo com o perfil do
                    cartão.
                  </td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">
                    {" "}
                    Opções de assistência integral, sem custo por meio de rede
                    credenciada ou mediante reembolso.
                  </td>
                  <td className="p-3">
                    {" "}
                    A maioria dos cartões funciona apenas por meio de reembolso.
                  </td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">
                    Período de contratação de até 365 dias consecutivos em
                    viagem
                  </td>
                  <td className="p-3">
                    {" "}
                    Período de contratação limitado de acordo com o perfil do
                    cartão.
                  </td>
                </tr>
              </table>
            </div>

            <p className="font-size-md text-center pt-5">
              Conheça as melhores operadoras de Seguro Viagem do Brasil:
            </p>

            <Collapse accordion items={itemsKey39} />
          </>
        ),
      },

      // {
      //   key: "39",
      //   label: <h4 className="font-weight-bold"></h4>,
      //   children: (
      //     <>
      //       <p className="font-size-md text-justify"></p>
      //     </>
      //   ),
      // },
    ];
  }

  function buildItemsKey1() {
    return [
      {
        key: "1",
        label: "Assistência Médica",
        children: (
          <p className="font-size-md text-justify">
            Ao comprar um seguro viagem, o viajante que necessitar receberá
            atendimento médico local, pela rede de atendimento autorizada e em
            alguns casos o médico pode ir até seu hotel. Caso isso não seja
            possível, o segurado poderá se dirigir ao hospital mais próximo ou o
            que desejar e receberá um reembolso pelas despesas médicas e
            hospitalares, conforme Condições Gerais do seguro contratado.
          </p>
        ),
      },

      {
        key: "2",
        label: "Assistência Farmacêutica",
        children: (
          <p className="font-size-md text-justify">
            Em caso de necessidade, o viajante receberá reembolso de despesas
            com medicamentos, desde que prescritos por orientação médica e
            durante o período de viagem, conforme Condições Gerais do seguro
            contratado. Em alguns casos o próprio médico que lhe atender no
            hospital ou em seu hotel, lhe fornecerá os medicamentos.
          </p>
        ),
      },

      {
        key: "3",
        label: "Assistência Médica Para Pré-Existência",
        children: (
          <p className="font-size-md text-justify">
            Atendimento para crises decorrentes de doença preexistente ou
            crônica, em caso de emergência ou urgência, é um item essencial para
            algumas pessoas em viagem. As normas estão no item Condições Gerais
            do seguro contratado. Muita atenção para este ponto se você já tem
            algum problema de saúde.
          </p>
        ),
      },

      {
        key: "4",
        label: "Cancelamento De Viagem",
        children: (
          <p className="font-size-md text-justify">
            Garante ao viajante o reembolso de despesas ou depósitos pagos
            previamente pelo segurado, em caso de cancelamento ou interrupção da
            viagem, conforme Condições Gerais do seguro contratado.
          </p>
        ),
      },

      {
        key: "5",
        label: "Atraso de Voo",
        children: (
          <p className="font-size-md text-justify">
            Caso o voo do atrase por mais de 6 horas, o segurado receberá
            reembolso até o valor limite da cobertura. Isso pode incluir gastos
            com hotel, refeições e comunicação, conforme Condições Gerais do
            seguro contratado.
          </p>
        ),
      },

      {
        key: "6",
        label: "Repatriação Sanitária ou por Morte",
        children: (
          <p className="font-size-md text-justify">
            Se alguma fatalidade ocorrer, o corpo é repatriado ao Brasil com
            todos os custos cobertos pela operadora, para os processos fúnebres
            no país de origem. Consulte sobre casos de transporte de volta em
            casos de internação grave.
          </p>
        ),
      },

      {
        key: "7",
        label: "Passagem Gratuita e Acompanhamento Familiar",
        children: (
          <p className="font-size-md text-justify">
            Em caso de hospitalização do viajante segurado, viajando sozinho e
            por um período superior a 05 dias, ele pode receber acompanhamento
            de um familiar direto, com direito à cobertura de gastos com
            passagem e hospedagem, conforme estabelecido em Condições Gerais do
            seguro contratado.
          </p>
        ),
      },

      {
        key: "8",
        label: "Cobertura para Prática de Esportes",
        children: (
          <p className="font-size-md text-justify">
            O viajante com seguro viagem terá direito ao atendimento
            emergencial, em caso de acidente pessoal ou enfermidade súbita e
            aguda, durante todo o período da viagem, em caso de problema
            decorrente da prática de esportes, sempre conforme estabelecido em
            Condições Gerais do seguro contratado.
          </p>
        ),
      },

      {
        key: "9",
        label: "Acompanhamento de Menores",
        children: (
          <p className="font-size-md text-justify">
            Em caso de acidente ou enfermidade de menores de 15 anos e idosos
            acima de 75 anos, estes terão acompanhante durante viagem de
            regresso ao país de origem, sob a responsabilidade da seguradora,
            conforme o item Condições Gerais do seguro contratado.
          </p>
        ),
      },

      {
        key: "10",
        label: "Mensagens Urgentes",
        children: (
          <p className="font-size-md text-justify">
            Transmissão de mensagens urgentes à família do segurado estão
            garantidas, por meio da Central de atendimento, conforme Condições
            Gerais do seguro contratado.
          </p>
        ),
      },

      {
        key: "11",
        label: "Assistência odontológica",
        children: (
          <p className="font-size-md text-justify">
            De acordo com o seguro viagem contratado, a pessoa poderá receber
            atendimento médico odontológico, dentro do valor limite de sua
            cobertura, conforme Condições Gerais do seguro contratado.
          </p>
        ),
      },

      {
        key: "12",
        label: "Assistência Jurídica",
        children: (
          <p className="font-size-md text-justify">
            Assistência e reembolso para gastos e despesas geradas com problemas
            judiciais, desde que ocorridos durante a viagem, conforme Condições
            Gerais do seguro contratado.
          </p>
        ),
      },

      {
        key: "13",
        label: "Reembolso por Gastos",
        children: (
          <p className="font-size-md text-justify">
            Em caso de atraso de bagagem, o segurado receberá indenização para
            comprar itens de necessidade básica (higiene pessoal e roupas) para
            viagens aéreas de ida, conforme Condições Gerais do seguro
            contratado.
          </p>
        ),
      },

      {
        key: "14",
        label: "Seguro de Bagagens",
        children: (
          <p className="font-size-md text-justify">
            Em caso de extravio, roubo, furto ou destruição de sua bagagem, você
            receberá uma indenização de acordo com o valor da cobertura
            contratada, conforme Condições Gerais do seu seguro viagem
            contratado.
          </p>
        ),
      },

      {
        key: "15",
        label: "Translados Gratuitos",
        children: (
          <p className="font-size-md text-justify">
            Para casos de emergência, a remoção e transporte do segurado até um
            centro médico mais próximo, conforme Condições Gerais do seguro
            contratado.
          </p>
        ),
      },

      {
        key: "16",
        label: "Convalescença em Hotel e Extensão de internação hospitalar",
        children: (
          <p className="font-size-md text-justify">
            Cobertura de custo prolongado de convalescença em hotel, até cinco
            dias, e extensão de internação hospitalar por recomendação de um
            profissional médico são itens inclusos em alguns planos de seguro
            viagem. Podem ser verificados em Condições Gerais do seguro
            contratado
          </p>
        ),
      },

      {
        key: "17",
        label: "Seguro de Vida por Morte Acidental e Invalidez",
        children: (
          <p className="font-size-md text-justify">
            Esta cobertura é válida para os planos que oferecem cobertura em
            caso de morte ou invalidez permanente por acidente, ocorrido durante
            o período de viagem no exterior, as normas especificas estão
            esclarecidas em Condições Gerais.
          </p>
        ),
      },

      {
        key: "18",
        label: "Assistência de Fiança Judicial",
        children: (
          <p className="font-size-md text-justify">
            Caso seja necessário e aprovado pela operadora, será adiantado um
            valor a título de empréstimo para pagamento da fiança, conforme
            Condições Gerais do seguro contratado.
          </p>
        ),
      },

      {
        key: "19",
        label: "Garantia de Regresso",
        children: (
          <p className="font-size-md text-justify">
            Em caso de morte ou doença grave de um familiar direto, a operadora
            garante o retorno antecipado do segurado ao seu país de origem,
            conforme Condições Gerais do seguro contratado.
          </p>
        ),
      },
    ];
  }

  function buildItemsKey9() {
    return [
      {
        key: "1",
        label: "Plano de Seguro Viagem",
        children: (
          <p className="font-size-md text-justify">
            O Plano de Seguro Viagem oferece diferentes tipos de cobertura, para
            diferentes situações emergenciais ocorridas durante a vigência do
            seguro (período de viagem). Este tipo de seguro inclui cobertura
            médica em caso de emergência e pode ser utilizado em toda rede
            credenciada à seguradora, com possibilidade de reembolso quando for
            o caso. No entanto, é preciso efetuar o pagamento na hora da
            consulta ou pronto-socorro, e solicitar o ressarcimento junto à
            operadora, somente após o retorno. O principal objetivo do Seguro
            Viagem é se precaver de fatos não previstos com o intuito de reduzir
            eventuais prejuízos durante a viagem. De modo resumido, o plano de
            Seguro Viagem não tem em seu escopo a realização de tratamentos
            médicos preventivos, consultas e exames de rotina, ou de interesse
            do segurado. Se seu objetivo é garantir assistência em caso de
            eventuais emergências, acidentes e imprevistos que venham ocorrer
            exclusivamente no período da viagem, conte com a assessoria da Multi
            Seguro Viagem.
          </p>
        ),
      },

      {
        key: "2",
        label: "Seguro Saúde Internacional",
        children: (
          <p className="font-size-md text-justify">
            O Seguro Saúde Internacional, por sua vez, tem uma característica
            relacionada à Assistência Médica, incluindo a prevenção e tratamento
            de doenças, além de consultas e exames de rotina que não fazem parte
            do rol de atendimento do Seguro Viagem. O foco principal deste
            serviço está na saúde do segurado, independente do mesmo estar
            viajando. Por isso, suas coberturas são exclusivamente relacionadas
            à saúde, excluindo-se coberturas como perda de bagagem por exemplo.
            Uma das grandes diferenças entre o Seguro Saúde e o Seguro Viagem é
            que normalmente os planos de Seguro Saúde não são contratados por
            curtos períodos, como no caso do Seguro Viagem, que se limita
            somente ao período de viagem. A contratação do Seguro Saúde é
            recomendada para pessoas que vão passar um grande período no
            exterior, já que sua contratação possui vigência usual de 12 meses.
            Para viagens de curto período, a necessidade dos viajantes passa a
            ser o atendimento emergencial para imprevistos, e não a realização
            de consultas médicas ou tratamento de doenças. Além disso, o custo
            de um Seguro Saúde internacional com cobertura no país de seu
            destino, é bastante elevado, podendo chegar a 10 vezes o valor de um
            Seguro Viagem para o mesmo período, o que acaba limitando bastante o
            público que consegue ter acesso ao produto.{" "}
          </p>
        ),
      },
    ];
  }

  function buildItemsKey12() {
    return [
      {
        key: "1",
        label: (
          <h4 className="font-weight-bold">
            Quais são os benefícios de comprar um Seguro Viagem com a Multi
            Seguro Viagem?
          </h4>
        ),
        children: (
          <ul>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Trabalhamos com as operadoras Líderes do mercado: Você
                encontra as melhores operadoras, nacionais e internacionais na
                Multi Seguro Viagem. São planos de qualidade, com preço justo e
                bem negociados com as operadoras e pagamento facilitado em até
                seis vezes sem juros.
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Compare e compre seu seguro online: As opções de seguro
                viagem são muitas, por isso, para facilitar sua vida, compare os
                planos e compre a cobertura ideal para seu perfil e necessidade
                100% online.
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Mais facilidade e segurança: Viaje tranquilo e com toda
                segurança com quem mais entende de Seguro Viagem no Brasil. Aqui
                você tem uma plataforma ágil, para resolver tudo em um só lugar,
                com toda agilidade e com especialistas.
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; Atendimento diferenciado com especialistas: Nós
                acompanhamos seu atendimento junto às operadoras para agilizar
                suas solicitações. Pode contar conosco.
              </p>
            </li>
          </ul>
        ),
      },

      {
        key: "2",
        label: (
          <h4 className="font-weight-bold">
            Quais as responsabilidades da Multi Seguro Viagem e qual as
            responsabilidades das Operadoras?
          </h4>
        ),
        children: (
          <p className="font-size-md text-justify">
            A Multi Seguro Viagem, como plataforma de comparação e venda online
            de Seguro Viagem tem como objetivo apresentar aos nossos clientes as
            melhores opções de planos e proporcionar a melhor comparação. A
            Operadora de Seguro Viagem contratada por meio da Multi Seguro
            Viagem, é a única e exclusiva responsável por diversos procedimentos
            (incluindo reembolso de despesas), coberturas e limites e preços
            base do plano contratado, além de garantir atendimento ao viajante,
            antes, durante e após a viagem. Em caso emergência ou necessidade de
            uso, a operadora contratada deve ser diretamente acionada pelo
            viajante. É como funciona no mercado de seguro de automóveis por
            exemplo, onde o corretor é responsável pela parte comercial e
            suporte, e a seguradora é responsável em caso de sinistros.
            Recomendamos ao viajante ler atentamente todas as regras e termos de
            uso descritas nas condições gerais disponibilizadas no site da
            operadora contratada, para evitar dúvidas e inconveniências futuras.
            A Multi Seguro Viagem está habilitada a esclarecer as dúvidas
            oriundas dos planos fornecidos pelas operadoras parceiras, bem como
            auxiliá-lo a identificar o plano que melhor atenderá a suas
            necessidades e perfil. É importante destacar que a Multi Seguro
            Viagem não atua como Operadora e sim como Corretora de Seguros,
            especializada na comercialização de Seguro Viagem. Trabalhamos com
            diversas operadoras de qualidade do mercado, com o objetivo oferecer
            ao viajante a facilidade de encontrar o plano ideal para o seu
            perfil e necessidade, por meio do serviço de comparação e venda
            online de seguro viagem, feito na plataforma digital da própria
            Multi Seguro Viagem. Além de intermediar diferentes planos de
            diversas operadoras do mercado, e facilitar a comparação dos planos
            ao viajante, a responsabilidade da Multi Seguro Viagem é prestar
            atendimento diferenciado e excelência, oferecer promoções e planos
            adequados, ter clareza e precisão nas informações prestadas aos
            clientes, intermediar e acompanhar o atendimento entre viajante e
            operadora, em caso de possíveis dificuldades de contato ou dúvidas.
            Nossos especialistas estão sempre à disposição, dentro do horário
            comercial, para fazer esta “ponte” sempre que necessário, pois é
            este nosso papel. Precisa falar conosco? Estamos à disposição! Após
            o contato inicial com a operadora, basta enviar um e-mail para
            contato@multiseguroviagem.com.br ou em nossa página de contato.
          </p>
        ),
      },

      {
        key: "3",
        label: <h4 className="font-weight-bold">Visto Schengen</h4>,
        children: (
          <>
            <p className="font-size-md text-justify">
              Nos casos em que a permanência nos países do tratado seja superior
              ao período permitido é necessário a emissão de um visto.
              Geralmente, esta regra é aplicada para viagens a trabalho ou
              intercâmbio, que podem ser mais longas. A duração do visto, vai
              depender do tempo de permanência no país ou de acordo com a
              necessidade solicitada. Para isto, é preciso ir ao Consulado ou
              Embaixada do país em que você ficará residente, e levar os
              seguintes documentos:
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro viagem com cobertura de no mínimo 30 mil
                  euros;
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Passaporte original;
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Comprovante de passagem aérea;
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Reservas de hospedagem ou carta-convite: nos casos de
                  estadia em casas de amigos.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Comprovante de situação financeira: normalmente o
                  extrato bancário dos últimos seis meses é o suficiente para
                  justificar a renda suficiente para permanência no destino.
                  Para os estudantes que não possuem renda, a comprovação pode
                  ser por meio de uma declaração oficial garantindo que outra
                  pessoa se responsabiliza pelo seu sustento durante o período
                  de estadia no país.
                </p>
              </li>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Formulário de aplicação de visto Schengen preenchido
                  e com foto 3x4 recente;
                </p>
              </li>
            </ul>
          </>
        ),
      },

      {
        key: "4",
        label: <h4 className="font-weight-bold">Seguro Viagem na Europa</h4>,
        children: (
          <>
            <p className="font-size-md text-justify">
              Para a entrada nos países da Europa que fazem parte do Tratado de
              Schengen, um dos itens obrigatórios é a contratação de um Seguro
              Viagem. No entanto, algumas regras devem ser observadas e
              seguidas, a partir das normas do acordo. A lei que exige o Seguro
              Viagem está apoiada pelo Regulamento (CE) n.º 810/2009, do
              Parlamento Europeu e do Conselho, em vigor desde 05 de abril de
              2010. A apólice do seguro viagem deve ter a cobertura mínima de
              até 30 mil euros ou 50 mil dólares e garantir assistência médica
              internacional emergencial para doenças ou imprevistos durante a
              viagem, como a perda de bagagens, documentos, acidentes pessoais
              ou até mesmo nos casos de repatriação funerária. Portanto, se um
              turista estrangeiro embarcar para a Europa sem a apólice do seguro
              viagem, saiba que pode ser barrado no aeroporto de destino, e
              deportado para o país de origem. O valor estipulado é válido para
              todos os países pertencentes ao Acordo, com exceção da Irlanda que
              não exige o valor mínimo na contratação do seguro viagem. A maior
              parte dos países da Europa exige o Seguro viagem, porque não
              oferecem atendimento gratuito na área da saúde para os
              estrangeiros. As consultas particulares costumam ter um alto
              valor, o que é reembolsado posteriormente, com aquisição da
              apólice. Se a viagem englobar a locação de veículo, é preciso
              estar atento com as regras de cobertura para acidentes que possam
              ferir a terceiros. A mesma regra vale nos casos de turistas com
              doenças preexistentes, com a contratação de um seguro viagem que
              cubra as despesas médicas, em casos de crises. Para acionar o
              seguro basta estar com apólice em mãos, e seguir o passo a passo
              lá descrito. Além disso, ligar para o número de telefone indicado,
              para que você possa contatar o representante no país onde você se
              encontra.
            </p>
          </>
        ),
      },
    ];
  }

  function buildItemsKey39() {
    const affinity = [
      {
        key: "sobre",
        label: "Sobre a Affinity Seguro Viagem",
        children: (
          <p className="font-size-md text-justify">
            A empresa pertence a uma das maiores seguradoras do mundo, a Sompo
            Japan Nipponkoa Insurance (SJNK), conceituada organização
            multinacional que já possui mais de 18 mil funcionários atuando em
            todo o mundo. O objetivo da Affinity é se tornar a seguradora
            preferida dos viajantes, pela alta qualidade dos serviços em seguro
            de viagem, por garantir uma experiência tranquila e livre de
            problemas, independente do lugar ou perfil da viagem. Quer ser
            reconhecida também essencialmente, por ser formada por profissionais
            especializados, com ampla experiência em serviços de assistência.
          </p>
        ),
      },
    ];

    const assistcard = [
      {
        key: "sobre",
        label: "Sobre o Seguro Viagem Assist Card",
        children: (
          <p className="font-size-md text-justify">
            A Assist Card surgiu em 1972, na Suíça. Logo depois começou a se
            expandir por outros continentes como América Latina, América Central
            e Ásia. Atualmente, a empresa faz parte do Grupo STARR, uma
            organização reconhecida mundialmente no setor de seguros e
            financiamentos. Sua proposta é ajudar o viajante desde as
            ocorrências mais simples, até as emergências mais complexas, a
            qualquer momento, em qualquer lugar e sob qualquer condição e
            slogan: (“ANYTIME, ANYWHERE, ANY REASON”). Os números da Assist Card
            são impressionantes: Funciona em 197 países, e em mais de 12 mil
            cidades, com escritórios locais. São mais de 400 mil prestadores e
            1400 funcionários qualificados pelo mundo inteiro, que atendem em
            mais de 16 línguas, ajudando mais de 7 milhões de clientes todos os
            anos. Conhecida em todo o mundo, a Assist Card se compromete sempre
            em fornecer um serviço de confiança por meio de uma equipe dedicada
            e disponível, que procura prestar sempre uma ajuda rápida e
            eficiente e gerar experiências únicas e inesquecíveis para seus
            clientes.
          </p>
        ),
      },
    ];

    const gta = [
      {
        key: "sobre",
        label: "Sobre o Seguro Viagem GTA",
        children: (
          <p className="font-size-md text-justify">
            Presente há 25 anos no mercado, a GTA Seguro Viagem atende mais de 1
            milhão de pessoas por ano. Parte de sua distribuição é feita por
            agentes de viagens ou empresas especializadas em Seguro Viagem, como
            a Multi Seguro Viagem. Em 2016, a GTA recebeu o Prêmio Top Aviesp
            como a melhor empresa de assistência viagem do Brasil, pelo nono ano
            consecutivo. Sua meta é conseguir a satisfação plena de seus
            clientes, com planos para atender a todos os perfis de viagens!
          </p>
        ),
      },
    ];

    const ua = [
      {
        key: "sobre",
        label: "Sobre o Seguro Viagem Universal Assistance",
        children: (
          <p className="font-size-md text-justify">
            A Universal Assistance surgiu na Argentina e já tem mais de 35 anos
            de mercado. Ocupa hoje a posição de líder de seguros viagens na
            América Latina. Presente em mais de 150 países, a Universal
            Assistance Seguro Viagem conta com centrais de atendimentos
            próprios, o que nem todas as operadoras concorrentes possui. São
            mais de 1,5 milhões de ligações atendidas anualmente, de forma
            rápida e prestativa e sempre na língua nativa do cliente.
            Atualmente, a empresa faz parte grupo internacional IMAS,
            especializado em oferecer serviços de seguro viagem para mais de 100
            milhões de viajantes.
          </p>
        ),
      },
    ];

    const assistMed = [
      {
        key: "sobre",
        label: "Sobre o Seguro Viagem Assist-Med",
        children: (
          <p className="font-size-md text-justify">
            A Assist-Med está no mercado há mais de 30 anos, com sede na
            Argentina. Atua em mais de 208 países por todo o mundo e funciona 24
            horas por dia, 365 dias por ano. A empresa garante sempre um bom
            atendimento e uma ajuda eficaz para seus viajantes. Presente no
            Brasil desde 2010, a Assist-Med oferece assistência média e
            hospitalar, indenização em caso de perda de bagagem, seguro de
            cancelamento de viagem, coberturas para despesas odontológicas,
            entre outros serviços de acordo com cada plano. Todos os planos,
            desde os seguros de viagem internacional, até os seguros de viagem
            nacional, são desenvolvidos seguindo todas as exigências da SUSEP
            (Superintendência de Seguros Privados). Com isso, você viajante,
            pode ficar tranquilo com os serviços desta empresa, e aproveitar ao
            máximo a sua viagem, sem se preocupar.
          </p>
        ),
      },
    ];

    const intermac = [
      {
        key: "sobre",
        label: "Sobre o Seguro Viagem Intermac Assistance",
        children: (
          <p className="font-size-md text-justify">
            Presente no mercado há mais de 18 anos, a Intermac Assistance possui
            uma grande variedade de produtos totalmente pensados para a
            segurança de seus viajantes. Seriedade, transparência e eficiência
            são características de todos os serviços da empresa Intermac,
            especializada em Seguro Viagem. A Intermac tem como objetivo suprir
            todas as necessidades de seus clientes e superar suas expectativas.
            Ética, clareza, respeito e eficiência permitem que a organização se
            consolide entre as maiores operadoras do segmento de seguro viagem
            no Brasil.
          </p>
        ),
      },
    ];

    const vital = [
      {
        key: "sobre",
        label: "Sobre o Seguro Viagem Vital Card",
        children: (
          <p className="font-size-md text-justify">
            Há mais de 16 anos no mercado, o Seguro Viagem Vital Card fornece
            planos variados e um enorme leque de coberturas, que cobrem desde R$
            5.000,00 até US$ 1.000.000,00 em assistência médica por exemplo. A
            Vital Card também se diferencia quando se trata de assistência
            médica, garantindo pronto atendimento rápido e eficaz em casos de
            emergência mais complicada, como um acidente por exemplo. Seu
            objetivo é fornecer planos de seguro viagem com qualidade, agilidade
            e excelência, buscando inspirações nas inovações e na realização dos
            sonhos de seus clientes. Atualmente, a Vital Card é Integrante do
            grupo Schultz Turismo, que está presente no mercado de turismo há
            mais de 30 anos.
          </p>
        ),
      },
    ];

    const coris = [
      {
        key: "sobre",
        label: "Sobre a Coris Seguro Viagem",
        children: (
          <p className="font-size-md text-justify">
            A empresa Coris foi fundada em 1987, em Paris, e em 2010, foi
            incorporada ao Grupo April. Sua equipe é altamente qualificada e
            atualizada, para dar sempre o melhor atendimento e garantir o
            bem-estar e conforto dos viajantes. O cliente que contrata um seguro
            viagem da Coris não precisa se preocupar com nada: o Seguro Viagem
            Coris resolve todo tipo de problema, desde os mais simples, até
            emergências mais sérias.{" "}
          </p>
        ),
      },
    ];

    return [
      {
        key: "affinity",
        label: "Affinity",
        children: (
          <>
            <p className="text-center">
              <img src={imgAffinity} alt="affinity" />
            </p>
            <p className="font-size-md text-justify p-3">
              A empresa Affinity fornece planos de seguro viagem para todos os
              perfis de viajantes. Seja para viagens a negócios, estudos ou
              lazer. Não importa o lugar, os planos da Affinity possuem seguros
              para viagens internacionais e seguros para viagens nacionais. Seu
              diferencial? Oferecer atendimento moderno e eficiente aos
              viajantes, em qualquer parte do mundo, protegendo e ajudando
              rapidamente seus clientes em imprevistos que possam surgir.
            </p>

            <Collapse accordion items={affinity} className="mb-4" ghost />
          </>
        ),
      },

      {
        key: "assistcard",
        label: "Assist Card",
        children: (
          <>
            <p className="text-center">
              <img src={imgAssistcard} alt="assist card" />
            </p>
            <p className="font-size-md text-justify p-3">
              A operadora Assist Card possui um dos melhores serviços quando se
              trata de seguro viagem e uma grande variedade de planos. Sendo a
              Operadora com planos entre os mais vendidos do site, tem mais de
              40 anos de experiência e já é considerada a maior organização de
              assistência de seguro viagem do mundo!{" "}
            </p>

            <Collapse accordion items={assistcard} className="mb-4" ghost />
          </>
        ),
      },

      {
        key: "gta",
        label: "GTA",
        children: (
          <>
            <p className="text-center">
              <img src={imgGta} alt="gta" />
            </p>
            <p className="font-size-md text-justify p-3">
              A GTA Seguro Viagem, uma das mais tradicionais do mercado, é
              especialista em viagens internacionais e oferece planos de seguro
              viagem para todos os tipos de roteiro. Precisou de atendimento
              urgente no exterior? Com a GTA, você será prontamente atendido,
              muitas vezes até mesmo em seu hotel, e receberá todas as
              informações que necessita. São mais de 3.000 profissionais
              bilíngues e trilíngues, prontos para te ajudar em qualquer
              situação e lugar do mundo Com foco total na qualidade dos
              serviços, a GTA apresenta equipe especializada em 22 cidades
              brasileiras e uma plataforma de atendimento mundial, que funciona
              24 horas por dia, 365 dias do ano. São mais de 1 milhão de
              turistas atendidos anualmente, totalmente satisfeitos! Para
              garantir o melhor atendimento, possui aproximadamente 530 linhas
              telefônicas com capacidade para até 90 mil chamadas por hora. Se
              você procura uma operadora que você possa contar 24 horas por dia,
              essa é a GTA!
            </p>

            <Collapse accordion items={gta} className="mb-4" ghost />
          </>
        ),
      },

      {
        key: "ua",
        label: "Universal Assistance",
        children: (
          <>
            <p className="text-center">
              <img src={imgUa} alt="universal assistance" />
            </p>
            <p className="font-size-md text-justify p-3">
              A Universal Assistance, empresa de origem Argentina, é líder em
              assistência de seguro viagem na América Latina e por que não dizer
              no mundo. A empresa busca desenvolver serviços de atendimento cada
              vez mais especializados. Já tem mais de 35 anos de mercado, e
              superou a marca 10 milhões de viajantes atendidos. Ao contratar um
              plano de seguro viagem da Universal Assistance, é possível viver
              uma experiência única e tranquila, sem se preocupar com possíveis
              imprevistos. Presente nos cinco continentes, a Universal
              Assistance conta com mais de 1000 colaboradores prontos para te
              ajudar. Os planos oferecem centro médico e aeronaves próprias,
              tudo para garantir o total bem-estar e a melhor experiência para
              seus clientes em viagem. Procura uma operadora que fala sua
              língua? É por causa da sua constante preocupação em fornecer o
              melhor atendimento, que toda ajuda prestada por seus funcionários
              é feita no idioma nativo do viajante, facilitando ao máximo a
              comunicação e suporte ao usuário.
            </p>

            <Collapse accordion items={ua} className="mb-4" ghost />
          </>
        ),
      },

      {
        key: "assistmed",
        label: "Assist-Med",
        children: (
          <>
            <p className="text-center">
              <img src={imgAssistMed} alt="assist med" />
            </p>
            <p className="font-size-md text-justify p-3">
              A Assist-Med é uma empresa especializada em Seguro Viagem que
              oferece planos completos para que seus clientes possam aproveitar
              ao máximo sua estadia no exterior, sem precisar se preocupar com
              os imprevistos que possam vir a acontecer. Trabalhando com
              atendimento 24 horas por telefone a organização busca oferecer
              praticidade para seus clientes caso eles precisem contatá-los.
            </p>

            <Collapse accordion items={assistMed} className="mb-4" ghost />
          </>
        ),
      },

      {
        key: "intermac",
        label: "Intermac Assistance",
        children: (
          <>
            <p className="text-center">
              <img src={imgIntermac} alt="Intermac" />
            </p>
            <p className="font-size-md text-justify p-3">
              A Intermac Assistance é atualmente uma das maiores empresa de
              seguros de viagens do Brasil, oferecendo serviços e planos
              exemplares. São mais de 1 milhão de clientes recebendo atenção
              especializada e dedicada durante suas viagens. Precisou de
              atendimento? A Intermac te ajuda! O viajante segurado pode
              contatar a empresa durante 365 dias no ano, 24 horas por dia, por
              meio de uma rede mundial de proteção que procura auxiliar
              viajantes em situações de emergência ou situações de ocorrências
              simples, como uma conjuntivite por exemplo.
            </p>

            <Collapse accordion items={intermac} className="mb-4" ghost />
          </>
        ),
      },

      {
        key: "vitalcard",
        label: "Vital Card",
        children: (
          <>
            <p className="text-center">
              <img src={imgVital} alt="vital card" />
            </p>
            <p className="font-size-md text-justify p-3">
              Com um grande variedade de planos e coberturas de Seguro Viagem, a
              Vital Card atende com qualidade exemplar a diversos tipos e estilo
              de viajantes, até mesmo as necessidades mais específicas. Se
              precisar de ajuda no exterior, a Vital Card fornece atendimento 24
              horas por dia e garante um auxílio de primeira qualidade aos
              viajantes que passam por qualquer imprevisto durante sua viagem,
              seja algo corriqueiro, ou uma emergência mais preocupante. O
              atendimento pode ser feito por WhatsApp, e-mail, telefone ou
              Skype, o que facilita muito o contato com a empresa. Com poucos
              cliques, o cliente consegue soluções rápidas e eficazes para
              resolver suas necessidades. Seja por telefone, mensagem ou e-mail,
              o viajante será informado sobre o local onde receberá atendimento,
              ou ainda se será atendido em seu próprio hotel, sem se preocupar
              em desembolsar nada durante a viagem.
            </p>

            <Collapse accordion items={vital} ghost />
          </>
        ),
      },

      {
        key: "coris",
        label: "Coris",
        children: (
          <>
            <p className="text-center">
              <img src={imgCoris} alt="Coris" />
            </p>
            <p className="font-size-md text-justify p-3">
              Coris é uma empresa global de seguro viagem, que está no mercado
              desde 2006. Referência no setor por seu profissionalismo, rapidez,
              qualidade e excelência no atendimento, a Coris oferece soluções
              eficientes para qualquer tipo de imprevisto que os viajantes
              possam enfrentar. São 40 centrais de atendimento operando nos
              cinco continentes, e mais de 160 correspondentes por todo o mundo,
              com coberturas de seguro viagem para todos os tipos de viagens,
              desde lazer, até as voltadas para negócios ou estudos. O
              atendimento é feito 24 horas por dia, todos os dias do ano, e
              totalmente em português. Rápido e fácil para você que vai viajar.
            </p>

            <Collapse accordion items={coris} className="mb-4" ghost />
          </>
        ),
      },
    ];
  }
};

export default InfoForForeigners;
