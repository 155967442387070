import { Card, Row } from "antd";

const InfoForForeignersIndexSpanish = () => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <div>
      <Row justify="center" className="mb-3">
        <Card
          className="strong-border card-without-padding"
          title={
            <div>
              <h1 className="text-center">Asistencia Viajero para Brasil</h1>
              <h3 className="text-center text-muted mb-4">
                Estranjeros en viaje a Brasil
              </h3>
            </div>
          }
          style={{
            margin: "18px 18px",
            maxWidth: 800,
          }}
          classNames="text-center"
        >
          <p className="font-size-md p-3 text-justify">
            Si vos sos estranjero y tenés perspectivas de visitar a Brazil, la
            compra de una asistencia viajero para Brasil es fundamental para su
            seguridad y garantía de tranquilidad ! A parte de todas las ventajas
            de poseer un plan de seguro al viajero, ahora es obligatorio segun
            el dispuesto en “PORTARIA CC-PR MJSP MINFRA MS Nº 419, DE 26 DE
            AGOSTO DE 2020“, y tienen que seguir los siguientes requisitos:
          </p>

          <ul>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; I - El plazo del seguro tiene que tener validez en el
                periodo de tu viaje programado.
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; II - Cobertura minima de R$ 30.000
              </p>
            </li>
            <li>
              <p
                className="font-size-md text-justify"
                style={{ marginLeft: 48, marginRight: 48 }}
              >
                &#x2022; III - Estar firmado en portugues o inglés
              </p>
            </li>
          </ul>

          <p className="font-size-md p-3 text-justify">
            Para tu tranquilidad, Multi Seguro Viagem posee un plan con todos
            estos requisitos de la Portaria del Governo Federal. La emisión de
            estos planes son customizadas, te pedimos que pongas en contacto
            conosotros através de los siguientes canales:&nbsp;
            <span className="font-weight-bold">+55 19 3201-0447</span>
          </p>
        </Card>
      </Row>
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default InfoForForeignersIndexSpanish;
