import { Card, Col, Collapse, Row } from "antd";
import internacionalImg from "assets/images/internacional.png";

const InfoInternationalTravelInsurance = () => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const items = buildItems();

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Row justify="center" className="mb-3">
      <Col style={{ width: 836 }}>
        <Card
          className="strong-border card-without-padding"
          title={
            <Row>
              <Col span={24}>
                <h1 className="text-center">Seguro Viagem Internacional</h1>
                <h3 className="text-center text-muted mb-4">
                  Todas as Operadoras estão Aqui (até 50% OFF)
                </h3>
              </Col>
            </Row>
          }
          style={{
            margin: "18px 18px",
            maxWidth: 800,
          }}
          classNames="text-center"
        >
          <p className="font-size-md p-3 text-justify">
            A realização de uma viagem muitas vezes está relacionada a
            realização de um sonho, a possibilidade de conhecer uma nova
            cultura, busca por novas experiências ou mesmo para relaxar e ter um
            tempo para encontrar tranquilidade fora da rotina do dia-a-dia.
          </p>

          <Row justify="center">
            <img src={internacionalImg} alt="Internacional" />
          </Row>

          <p className="font-size-md p-3 text-justify">
            Para conseguir fazer uma viagem internacional, normalmente são
            considerados diversos fatores no momento de planejamento, seja
            compra de moeda estrangeira, passagem aérea, reserva do hotel,
            locação de carros, compra de roupas, malas apropriadas e escolha dos
            passeios e destinos que serão visitados. Tudo isso para que nenhum
            imprevisto atrapalhe o momento de lazer e descontração. É muito
            importante que neste momento de planejamento o Seguro Viagem
            Internacional seja também considerado, principalmente para que
            imprevistos e situações não planejadas, que podem acontecer em
            qualquer viagem, não acabe com todo seu sonho e orçamento financeiro
            da viagem. O Custo do Seguro Viagem Internacional normalmente não
            ultrapassa 5% dos gastos totais da viagem e em caso de necessidade
            de utilização, esse investimento fará toda a diferença em sua
            segurança financeira e também seu bem-estar para a continuidade da
            viagem.{" "}
          </p>

          <Collapse accordion items={items} className="mb-4" ghost />
        </Card>
      </Col>
    </Row>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildItems() {
    return [
      {
        key: "1",
        label: (
          <h4 className="font-weight-bold">
            O que é o Seguro Viagem Internacional?
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              O Seguro Viagem Internacional é um plano de seguro que tem como
              principal objetivo assistir, auxiliar e também reembolsar o
              viajante em caso de diversas situações de emergência e imprevistos
              que são cobertos pelo plano de Seguro. Os planos possuem suas
              tarifas calculadas baseadas na quantidade de dias de viagem, no
              destino do viajante, além do valor da cobertura desejada, quanto
              maior a cobertura, maior será a tarifa diária cobrada pela
              segurada. O Perfil do viajante e as atividades que serão
              realizadas na viagem também podem interferir na tarifa, tendo em
              vista que existem planos que cobrem inclusive a prática de
              esportes, passageiros gestantes e também idosos que precisam de
              uma atenção e cuidados especiais em viagens ao exterior. Existem
              hoje mais de 15 seguradoras no mercado que oferecem diversas
              opções de planos de seguro viagem internacional, no Multi Seguro
              Viagem você consegue realizar a cotação com as principais e mais
              respeitadas seguradoras do mercado. Através de uma única cotação é
              possível comparar as opções de mais de 50 planos e suas diferentes
              coberturas.
            </p>
          </>
        ),
      },

      {
        key: "2",
        label: (
          <h4 className="font-weight-bold">
            Coberturas do Seguro Viagem Internacional
          </h4>
        ),
        children: (
          <>
            <p className="font-size-md text-justify">
              Os planos de seguro viagem internacional possuem coberturas
              variadas, desde as mais básicas até planos com coberturas altas e
              completas. As principais coberturas dos planos de Seguro Viagem
              Internacional são:
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Despesas Médica e Hospitalares ocorridas em Viagem
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Despesas Médicas ocorridas em viagem relacionadas à
                  doença pré-existente
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Despesas Odontológicas Emergenciais
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Translado Médico
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Regresso Sanitário
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Despesas Farmacêuticas
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro para Morte Acidental em Viagem
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro para Invalidez Total e Parcial por acidente
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro para Extravio de Bagagem
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro de Reembolso em caso de Atraso de Bagagem
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro para Danos ocasionados a Mala
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Seguro para Cancelamento de Viagem
                </p>
              </li>
            </ul>

            <p className="font-size-md text-justify">
              Além dos serviços de Assistências aos viajantes, que são inclusos
              no plano, variando conforme a seguradora que contratar, sendo
              alguns deles:
            </p>

            <ul>
              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Assistência Jurídica
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Adiantamento em caso de fiança
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Auxílio para reserva de hotel para acompanhante
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Reserva de Passagem aérea de ida e volta para um
                  familiar caso seja necessário
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Transmissão de mensagens urgentes
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Assistência na localização de bagagem
                </p>
              </li>

              <li>
                <p
                  className="font-size-md text-justify"
                  style={{ marginLeft: 48, marginRight: 48 }}
                >
                  &#x2022; Orientação em caso de perda de documentos / cartões
                  de crédito
                </p>
              </li>
            </ul>

            <p className="font-size-md text-justify">
              É importante que sempre antes de efetivar a compra de seu seguro
              sejam avaliadas todas as coberturas de seu plano e verificar se os
              valores cobertos estão compatíveis com sua necessidade e o perfil
              de sua viagem. Se estiver na dúvida, não hesite em contatar os
              consultores da Multi Seguro Viagem para que possam lhe ajudar a
              encontrar os melhores planos e coberturas para sua viagem.{" "}
            </p>
          </>
        ),
      },

      {
        key: "3",
        label: <h4 className="font-weight-bold">Qual Plano Contratar?</h4>,
        children: (
          <>
            <p className="font-size-md text-justify">
              A contratação do plano de seguro viagem adequado para seu perfil é
              fundamental para que sua viagem ocorra com toda a segurança e
              conforto que você merece, quanto maior a cobertura contratada,
              mais tranquilo você estará. É importante ressaltar que alguns
              destinos como o continente europeu por exemplo, possuem exigências
              de coberturas mínimas, além da obrigatoriedade da contratação do
              seguro, portanto é importante considerar isso no momento de
              contratar o plano.
            </p>

            <p className="font-size-md text-justify">
              Os planos de Seguro Internacional mais comercializados para
              destinos internacionais variados são:{" "}
            </p>

            <div className="text-center w-100">
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <th>
                    <strong>Multi Seguros 100k</strong>
                  </th>
                  <th>
                    <strong>AC 100</strong>
                  </th>
                </tr>
                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">Seguradora: Universal Assistance</td>
                  <td className="p-3">Seguradora: Assist Card</td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">Assistência Médica: US$ 100.000,00</td>
                  <td className="p-3">Assistência Médica: US$ 100.000,00</td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">Extravio de Bagagem: US$ 1.000,00</td>
                  <td className="p-3">Extravio de Bagagem: US$ 1.200,00</td>
                </tr>

                <tr
                  style={{
                    borderBottom: "1px solid #c0c0c080",
                    padding: 8,
                    textAlign: "left",
                    width: "50%",
                  }}
                >
                  <td className="p-3">Custo médio por dia de US$ 6,00*</td>
                  <td className="p-3">Custo médio por dia de US$ 6,51*</td>
                </tr>
              </table>
            </div>

            <p className="font-size-sm text-justify">
              * As Tarifas tem o preço estimado e podem ser alteradas pelas
              seguradoras sem aviso prévio, prevalecendo sempre a cotação
              vigente no momento do fechamento do seguro.
            </p>
          </>
        ),
      },
    ];
  }
};

export default InfoInternationalTravelInsurance;
