import axios from "axios";
import { notification } from "antd";
import {
  generateCode,
  getIpWan,
  getUrlFromBrowser,
  translateWord,
} from "utils/helpers";
import dayjs from "dayjs";
import appService from "../services/appService";
import {
  APP,
  BANK_ACCOUNT_CODE,
  COMPANY_ID,
  SUBSCRIPTION_ID,
} from "../settings/constant";

const app = APP;

// const language = navigator.language;
// const backendLocal = 'http://177.153.58.33:8005'
// const backendLocal = 'http://veertical.vps-kinghost.net:8005'
// const backendLocal = 'http://localhost:8000'

let backendUrl;

if (app.DEV_MODE) {
  const protocol = app.API_DEV_HTTPS ? "https" : "http";
  let devHost;

  if (app.API_DEV_HOST === "vps") {
    devHost = app.API_HOST_DEV_VPS;
  } else {
    devHost = app.API_HOST_DEV_LOCALHOST;
  }

  backendUrl = `${protocol}://${devHost}:${app.API_PORT}/api`;
} else {
  backendUrl = `https://${app.API_HOST_PRODUCTION}:${app.API_PORT}/api`;
}

const APP_HEADERS = {
  // [default]
  //----------------------------------------------------------------------------------------------------
  "Content-Type": "application/json",
  "x-serverdomain": backendUrl,
  "x-clienturl": getUrlFromBrowser(),

  // [safe, track and format]
  //----------------------------------------------------------------------------------------------------
  "x-application": app.ID,
  "x-application-profile": "website",
  "x-token": app.TOKEN,
  "x-requestcode": generateCode([2, 3], 6),
  "x-resultasobject": false,
  "x-origin": 0,
  "x-summary": true,
  "x-forcefiled": 0,
  "x-forceremoved": 0,
  "x-timestamp": +new Date(),
  "x-ip": await getIpWan(),

  // [subscriber identification]
  // ----------------------------------------------------------------------------------------------------
  "x-accountid": null, // accountId author
  "x-subscriptionid": SUBSCRIPTION_ID, // subscriber owner
  "x-companyid": COMPANY_ID, // companyId owner
  "x-bankaccountcode": BANK_ACCOUNT_CODE, // companyId owner

  // [frontend culture identification/i18n]
  // ----------------------------------------------------------------------------------------------------
  "x-countrycode": app?.LOCALE?.split("-")?.[1]?.toLowerCase(),
  "x-languagecode": dayjs().locale(), // 'pt-br', 'en-us', ...
  "x-localecode": dayjs().locale()?.split("-")?.[1]?.toLowerCase() || "us", // 'br', 'us', ...
  "x-timezoneoffset": dayjs().utcOffset(),
  "x-tokenexpirationminutes": 60,
  "x-3rdpartytoken": process.env.REACT_APP_BACKEND_TOKEN,
};

const apiConfig = axios.create({
  baseURL: backendUrl,
  timeout: 60000,
  headers: APP_HEADERS,
  withCredentials: true,
});

// API Request interceptor
apiConfig.interceptors.request.use(
  async (config) => {
    const requestCode = generateCode([2, 3], 6);

    config.headers["x-timestamp"] = +new Date();
    config.headers["x-requestcode"] = requestCode;

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      key: "unknowing-error",
      message: translateWord("error"),
    });

    Promise.reject(error);
  },
);

// API response interceptor
apiConfig.interceptors.response.use(
  (response) => {
    // custom error from server
    if (response?.data?.error) throw new Error(response.data.error);

    const docs = response?.data?.data;

    if (Array.isArray(docs)) {
      docs?.map((d) => $removeSensitiveFields(d));
    } else {
      $removeSensitiveFields(docs);
    }

    const result = response?.data;
    const requestCode = response.config.headers["x-requestcode"];
    const timestamp = Number(response.config.headers["x-timestamp"]);
    const totalTime = +new Date() - timestamp;

    appService.console(
      "s",
      `${requestCode} ${response.config.method.toUpperCase()} ${
        response.config.url
      } ${totalTime}ms`,
      result,
    );

    return result;

    function $removeSensitiveFields(d) {
      if (!d) return;
      delete d?.password;
    }
  },
  (error) => {
    const requestCode = error.config.headers["x-requestcode"];
    const timestamp = Number(error.config.headers["x-timestamp"]);
    const totalTime = +new Date() - timestamp;
    const url = error?.config?.baseURL?.replace("/api", "");

    appService.console(
      "e",
      `${requestCode} ${error.config.method.toUpperCase()} ${
        error.config.url
      } ${totalTime}ms`,
      error.response?.data,
    );

    let notificationParam = {
      key: "generic-error",
      message: "",
      description: `${error.config.method.toUpperCase()}: `,
    };

    let alreadyShowedError = false;

    if (!alreadyShowedError && error?.response?.status === 404) {
      notificationParam.message = `404: ${translateWord(
        "api_route_not_found",
      )}`;
      notificationParam.description += error?.response?.config?.url;
      notificationParam.duration = 0;
      showNotification();
    }

    if (!alreadyShowedError && error?.response?.status === 500) {
      notificationParam.message = `500: ${translateWord(
        "internal_server_error",
      )}`;
      notificationParam.description += error?.response?.config?.url;
      notificationParam.duration = 0;
      showNotification();
    }

    if (!alreadyShowedError && error?.response?.status === 508) {
      notificationParam.message = `508: ${translateWord("time_out")}`;
      notificationParam.description += error?.response?.config?.url;
      notificationParam.duration = 0;
      showNotification();
    }

    if (!alreadyShowedError && error?.message === "Network Error") {
      notificationParam.message = `500: ${translateWord("server_not_found")}`;
      notificationParam.description = url;
      notificationParam.duration = 0;
      showNotification();
    }

    if (!alreadyShowedError && error?.response?.status !== 400) {
      notificationParam.message = `${error?.response?.status}: ${translateWord(
        "internal_server_error",
      )}`;
      notificationParam.description += error?.response?.config?.url;
      notificationParam.duration = 0;
      showNotification();
    }

    // do not use Promise.resolve(error) here!
    return Promise.reject(error);

    function showNotification(method = "error") {
      alreadyShowedError = true;
      notification.destroy("try-login");
      notification[method](notificationParam);
    }
  },
);

export default apiConfig;
