import React from "react";
import Logo from "components/UI/Logo/Logo";
import Footers from "components/Footer/Footer";
import FooterMenu from "./FooterMenu";
import FooterLinks from "../../FooterLinks/FooterLinks";

const Footer = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <FooterLinks />

      <Footers
        logo={
          // <img src={MultiLogo} width={140} alt="Multi Seguro Viagem." />
          <Logo
            withLink
            linkTo="/"
            src="/images/logo-alt.png"
            title="Multi Seguro Viagem."
          />
        }
        menu={<FooterMenu />}
        copyright={`Copyright @ ${new Date().getFullYear()} | Uma empresa Ekto Grupo Ltda.`}
      />
    </div>
  );
};

export default Footer;
