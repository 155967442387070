import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import GlobalStyles from "themes/global.style";
import AuthProvider from "context/AuthProvider";
import AppRoutes from "./router";
import "antd/dist/reset.css";
import "./lang";
import { ConfigProvider } from "antd";
import WhatsAppFloatButton from "./components/jarvisly-components/others/WhatsAppFloatButton/WhatsAppFloatButton";
import "./index.css";

const App = () => (
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#008489",
        contentFontSize: 16,
        contentLineHeight: 1.5,
        controlHeight: 40,
        colorLink: "darkcyan",
      },
    }}
  >
    <React.Fragment>
      <GlobalStyles />
      <BrowserRouter>
        <AuthProvider>
          <AppRoutes />
          <WhatsAppFloatButton phone="1932010447" />
        </AuthProvider>
      </BrowserRouter>
    </React.Fragment>
  </ConfigProvider>
);
const root = createRoot(document.getElementById("root"));
root.render(<App />);
