import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // Importe o plugin para lidar com tempos relativos
import localizedFormat from "dayjs/plugin/localizedFormat";

import "dayjs/locale/pt-br";

import antdPtBR from "antd/es/locale/pt_BR";
import antdEnUS from "antd/es/locale/en_US";
import antdZhCn from "antd/es/locale/zh_CN";
import antdFrFR from "antd/es/locale/fr_FR";
import antdJaJP from "antd/es/locale/ja_JP";

import pt from "./locales/pt_BR.json";
import en from "./locales/en_US.json";
import zh from "./locales/zh_CN.json";
import fr from "./locales/fr_FR.json";
import ja from "./locales/ja_JP.json";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { APP } from "../settings/constant";

const app = APP;

// extend the plugin alternative for 'moment(dt).fromNow()'
dayjs.locale(app?.LOCALE || "pt-br"); // Defina o locale desejado
dayjs.extend(localizedFormat); // Adicione o plugin de formatação localizada
dayjs.extend(relativeTime); // Adicione o plugin de tempo relativo

export const resources = {
  pt: {
    translation: pt,
    antd: antdPtBR,
  },

  en: {
    translation: en,
    antd: antdEnUS,
  },
  zh: {
    translation: zh,
    antd: antdZhCn,
  },
  fr: {
    translation: fr,
    antd: antdFrFR,
  },
  ja: {
    translation: ja,
    antd: antdJaJP,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: app.LANGUAGE,
  lng: app.LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
